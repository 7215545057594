import { useRef } from "react";
// Importing Components
import BottomFixedButtons from "components/buttons/BottomFixedButtons";
import PlanProgramYear from "pages/planProgramRegular/components/PlanProgramsYear";
import PlanProgramHeader from "pages/planProgramRegular/sections/PlanProgramHeader";
import PlanProgramDescription from "./sections/PlanProgramDescription";

// Importing Layout
import NavBarr from "components/layout/NavBarr";
import Footer from "components/layout/footer/Footer";

//  importing assets
import straightUp from "assets/images/programs_pages/vectors/straight_up.png";

// Calling API
import { planPrograms, yearResults } from "helpers/planPrograms/planProgramApi";
import { modules } from "helpers/planPrograms/modules";
import PlanProgramResults from "pages/planProgramRegular/sections/PlanProgramResults";
import PlanProgramRegister from "./components/PlanProgramRegister";

export default function ProgramContent(props) {
  const { params } = props;
  const programsRef = useRef(null);

  const planProgram = planPrograms.find((i, index) => {
    return i.id == params;
  });

  const yearsResults = yearResults.filter((item) => {
    return item.planprogram_id === planProgram.id;
  });

  const modulesByYear = {};
  const years = planProgram.years;

  years.forEach((year) => {
    modulesByYear[year] = modules.filter(
      (module) =>
        module.year === year && module.planprogram_id === planProgram.id
    );
  });
  // years.forEach((year) => {
  //   modulesByYear[year] = modules.filter((module) => {
  //     const matchingYear = module.year === year;
  //     const matchingPlanProgram = Array.isArray(module.planprogram_id)
  //       ? module.planprogram_id.includes(planProgram.id)
  //       : module.planprogram_id === planProgram.id;
  //     return matchingYear && matchingPlanProgram;
  //   });
  // });

  const handleClick = () => {
    if (programsRef.current) {
      const top = programsRef.current.offsetTop - 70;
      window.scroll({
        top,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <NavBarr />
      <div className="body regular">
        <header className="programs__content-header">
          <PlanProgramHeader
            title={planProgram.title}
            desc={planProgram.shortDesc}
            subtitle={planProgram.subtitle}
            theme={planProgram.theme}
            action={() => {
              handleClick();
            }}
          />
        </header>
        <div className="vector-wrapper">
          <img src={straightUp} alt="Section Splitter" />
        </div>

        <section className="programs__content-separator" ref={programsRef}>
          <PlanProgramDescription
            title={planProgram.subtitle}
            desc={planProgram.description}
          />
        </section>

        {/* <div
          className='vector-wrapper'
        >
          <img src={straightDown} alt='' />
        </div> */}

        <section className="programs__content-cards" id="programs-content">
          <div className="programs__content-cards-wrapper container">
            {years.map((year) => {
              const yearResult = yearResults.find(
                (result) =>
                  result.year === year &&
                  result.planprogram_id === planProgram.id
              );
              const subtitle = yearResult ? yearResult.title : "";
              return (
                <PlanProgramYear
                  year={year}
                  modules={modulesByYear}
                  subtitle={subtitle}
                  theme={planProgram.theme}
                />
              );
            })}
          </div>
          <div className="programs__content-year-results-wrapper container">
            <PlanProgramResults
              results={yearsResults}
              theme={planProgram.theme}
            />
          </div>
        </section>

        <section className="programs__content-register">
          <PlanProgramRegister />
        </section>
        <BottomFixedButtons />
        <Footer variant={"hidden"} className={["footer__programs-content"]} />
      </div>
    </>
  );
}
