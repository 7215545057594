import { useRef } from "react";
import { planProgramsCards } from "helpers/planProgramsCards";
import { motion } from "framer-motion";
import "scss/css/programs/programsStyle.css";

// Importing Layout
import NavBarr from "components/layout/NavBarr";
import Footer from "components/layout/footer/Footer";

// Importing Components
import ProgramsCard from "components/cards/ProgramCards";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";
import ProgramsHeaderSection from "components/sections/programs/ProgramsHeaderSection";

// Importing Test Assets
import ProgramsVector from "assets/svg/programs_svg.svg";

export default function Programs() {
  const programsRef = useRef(null);

  const unRegularPlanProgram = planProgramsCards.filter((item) => {
    return item.type === 2;
  });

  const regularPlanProgram = planProgramsCards.filter((item) => {
    return item.type === 1;
  });

  const handleClick = () => {
    if (programsRef.current) {
      const top = programsRef.current.offsetTop;
      window.scroll({
        top,
        behavior: "smooth",
      });
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavBarr contrast={true} />
      <section className="programs">
        <ProgramsHeaderSection
          action={() => {
            handleClick();
          }}
        />
      </section>
      <div className="programs__vector-svg">
        <img src={ProgramsVector} alt="Section Splitter" />
      </div>

      <section className="programs__section" ref={programsRef}>
        <div className="programs__container">
          <div className="programs__section__intro">
            <h1>Programet</h1>
            <p>Më poshtë mund t’i shihni planprogramet tona</p>
            <div className="divider"></div>
          </div>
          <div className="programs__cards-section">
            {/* <div className="programs__section__grid">
              <h1 className="programs__section-title">Planprogramet Verore</h1>
              <div className="programs__section__grid__cards">
                {unRegularPlanProgram.map((i, index) => {
                  return (
                    <ProgramsCard
                      key={index}
                      img={i.img}
                      title={i.title}
                      subtitle={i.subtitle}
                      age={i.age}
                      desc={i.description}
                      redirectLink={i.redirect}
                      theme={i.theme}
                    />
                  );
                })}
              </div>
            </div> */}
            <div className="programs__section__grid">
              <h1 className="programs__section-title">Planprogramet</h1>
              <div className="programs__section__grid__cards">
                {regularPlanProgram.map((i, index) => {
                  return (
                    <ProgramsCard
                      key={index}
                      img={i.img}
                      title={i.title}
                      subtitle={i.subtitle}
                      age={i.age}
                      desc={i.description}
                      redirectLink={i.redirect}
                      theme={i.theme}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="programs__contact">
        <div className="programs__contact-container">
          <div className="programs__contact-title">
            <h1>Kontakt</h1>
            <h2>Na kontaktoni këtu</h2>
          </div>
          <ContactSection
            ContactSvg={ContactHomeSvg}
            ContactBtnTitle="Dërgo Mesazhin"
            ContactBtnClassName="programs__contact-btn red-btn"
          />
        </div>
      </section> */}
      <BottomFixedButtons />
      <Footer variant={"lightGray"} />
    </motion.div>
  );
}
