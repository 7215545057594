import { useParams } from "react-router-dom";
import { planPrograms } from "helpers/planPrograms/planProgramApi";
import { motion } from "framer-motion";

// Importing routes
import ProgramContent from "pages/planProgramRegular/ProgramContent";
import ProgramContentUr from "pages/planProgramUnregular/ProgramContentUr"
function PlanPrograms() {
    const { id } = useParams();

    const planProgram = planPrograms.find((i, index) => {
        return i.id == id
    })

    const type = planProgram.type
    const params = id
    return (
        <motion.div
        initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                >
        
            {type == 1 &&
                <ProgramContent params={id} />

                ||
                type == 2 &&
                <ProgramContentUr params={id} />
            }

        </motion.div>
    );
}

export default PlanPrograms;