import ProgramYearResults from "../components/ProgramYearResults";

function PlanProgramResults(props) {
  const { results, theme } = props;
  return (
    <>
      <div className="year__results-info">
        <div className="year__results-title">
          <h1>Rezultatet mësimore të fituara gjatë viteve</h1>
        </div>
        {results.map((i, index) => {
          const yearDesc = i.desc.split(".").filter((i) => {
            return i !== "";
          });
          return (
            <ProgramYearResults
              key={index}
              title={i.title}
              desc={yearDesc}
              theme={theme}
            />
          );
        })}
      </div>
    </>
  );
}

export default PlanProgramResults;
