function ArticleRegister() {
  return (
    <>
      <div className="article__register-container container">
        <p>
          Dhe për fund, ju rikujtojme që kampet verore te jCoders do te
          trajtojne këto tema në thellësi me fëmijet tuaj. Andaj ju ftojme qe te
          shfrytëzoni rastin të regjistroni fëmijët në kampin veror të jCoders.
        </p>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSdGNNXMp5obSCjaREwz1fEh4rQzLEuxSw445YUnhmvZ2MVLCg/viewform"
          className="btn blue-btn article__register-btn"
          target="_blank"
        >
          Regjistrohu
        </a>
      </div>
    </>
  );
}

export default ArticleRegister;
