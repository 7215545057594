import { UilUserPlus } from "@iconscout/react-unicons";
import { NavLink } from "react-router-dom";

function RegisterButton(props) {
  const { className } = props;
  return (
    <>
      <NavLink
        className={`register-scroll-btn ${[[...className]]}`}
        to={"/register"}
        aria-label="Regjistrohu"
      >
        <UilUserPlus />
      </NavLink>
    </>
  );
}

export default RegisterButton;
