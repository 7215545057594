import codeImg from 'assets/images/programs_pages/codeImg.png'

function PlanProgramDescription(props) {
    const { desc } = props
    return (
        <>
            <div className='programs__content-container container'>
                <h1>Çka do të mësojnë nxënësit?</h1>
                <p>
                    {desc}
                </p>
                <div className='separator-img-wrapper'>
                    <img src={codeImg} alt='' />
                </div>
            </div>
        </>
    );
}

export default PlanProgramDescription;