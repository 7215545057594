import { motion } from "framer-motion";
import "scss/css/about/aboutStyle.css";

// Importing Layout
import NavBarr from "components/layout/NavBarr";
import Footer from "components/layout/footer/Footer";

// Importing Sections
import AboutSection from "components/sections/AboutSection";
import TeamSection from "components/sections/about/TeamSection";

// import SEPSection from "components/sections/SEPSection";
// import ObjectiveSectionEdu from "components/sections/about/ObjectivesSectionEdu";
import ObjectivesSection from "components/sections/about/ObjectivesSection";
// import ContactSection from "components/sections/ContactSection";
import SuccessSection from "components/sections/successSection/SuccessSection";
import VideoSection from "components/players/VideoPlayer";

// Importing SVG
// import ObjectivesUp from "assets/vectors/streight_vector_up.svg";
// import ObjectivesDown from "assets/vectors/streight_vector_down.svg";
// import ContactSvg from "assets/svg/home_contact_svg.svg";
import AboutUsHeaderSvg from "assets/svg/about_us_header.svg";

// Importing Components
import Button from "components/buttons/Buttons";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";

export default function AboutUs() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      style={{ overflowX: "hidden" }}
    >
      <NavBarr contrast={true} />
      <header className="about">
        <div className="about__wrapper">
          <div className="about__container container">
            <AboutSection
              classNameAboutText={["about__text"]}
              classNameAboutBtn={["display-none"]}
              classNameAboutSvg={["about__svg-mobile"]}
            />
          </div>
          <div className="about__header-vectors">
            <img src={AboutUsHeaderSvg} alt="About Header Image" />
          </div>
        </div>
      </header>
      <section className="about__objectives">
        <ObjectivesSection />
      </section>
      <section className="about__video">
        <div className="about__video-bg"></div>
        <div className="about__video-container container">
          <VideoSection
            src={"https://jcoders.s3.eu-central-1.amazonaws.com/Jcoders_4.mp4"}
            className={"about__us-video"}
          />
        </div>
      </section>

      <section className="about__team">
        <div className="about__team-container container">
          <div className="about__team-title">
            <h1>Ekipa Jonë</h1>
            <h2>Ekipa Kryesore</h2>
          </div>
          <div className="team__cards-container">
            <TeamSection />
          </div>
        </div>
      </section>
      {/* <div className="about__objectives-vectors">
        <img src={ObjectivesDown} alt="Section Splitter" />
      </div> */}

      <section className="about__separator">
        <div className="about__separator-container">
          <h1>Duke krijuar gjeneratën e re të inovatorëve</h1>
          <p>Bëhu pjesë e komunitetit të jCoders. Regjistrohu tani!</p>
          <Button
            RedirectLink="/register"
            className={["about__separator-btn danger-btn"]}
            ButtonTitle="Regjistrohu"
            variant={"red"}
            size={"md"}
            ariaLabel={"Regjistrohu"}
          />
        </div>
      </section>

      {/* <section className="about__sep">
        <div className="about__sep-container">
          <div className="about__sep-title">
            <h1>Kontakt</h1>
            <h2>Na kontaktoni këtu</h2>
          </div>
          <ContactSection
            ContactSvg={ContactSvg}
            ContactBtnTitle="Dërgo Mesazhin"
            ContactBtnClassName="red-btn"
            InputClassName="dark-blue-inputs"
          />
        </div>
      </section> */}

      {/* <section className="about__objectives-edu">
        <ObjectiveSectionEdu />
      </section> */}

      <section className="about__success">
        <div className="about__success-container container">
          <div className="about__success-title">
            <h1>Sukseset tona</h1>
            {/* <h2>
              Në Akademinë Jcoders, kemi ndihmuar qindra studentë të fillojnë
              karriera të suksesshme në fushën e teknologjisë.
            </h2> */}
          </div>
          <div className="about__success-carousel">
            <SuccessSection style={{ backgroundColor: "#fff" }} />
          </div>
        </div>
      </section>

      <BottomFixedButtons />

      <Footer variant={"white"} />
    </motion.div>
  );
}
