import { useEffect, useState } from "react";
import { motion } from "framer-motion";

// Importing Layout
import Footer from "components/layout/footer/Footer";
import NavBarr from "components/layout/NavBarr";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";
import SummerRegisterSection from "components/sections/SummerRegisterSection";

function Register() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavBarr />

      <section
        className="register__hero"
        style={{ paddingTop: isMobile ? "135px" : "115px" }}
      ></section>
      <section className="register" style={{ paddingTop: "25px" }}>
        <div className="register__container">
          <div className="register__title">
            <h2
              style={{
                fontWeight: "700",
                textAlign: "center",
                fontSize: "1.4375rem",
              }}
            >
              Kampi veror është mbyllur 🙂. Por ju mund të regjistroheni në
              planprogramet tona të rregullta{" "}
              <a
                style={{
                  fontWeight: "700",
                  textAlign: "center",
                  color: "#f15b5b",
                  textDecoration: "underline",
                }}
                href="/programs"
              >
                këtu
              </a>
            </h2>
            {/* <h1 style={{ color: "#f15b5b" }}>Regjistrohuni në kampin veror!</h1> */}
            {/* <h2>
              Për t’a regjistruar fëmijën tuaj në kampin veror, ju lutem
              plotësoni formën e më poshtme me të dhënat për fëmijën tuaj. Ju
              faleminderit që keni zgjedh jCoders 🙂.
            </h2> */}
          </div>

          {/* <SummerRegisterSection /> */}
          {/* <p>
            Te dhenat tuaja jane te mbrojtuara sipas LIGJIT PËR MBROJTJEN E TË
            DHËNAVE PERSONALE, LIGJI Nr. 03/L-172
          </p> */}
        </div>
      </section>
      <Footer variant={"lightGray"} />
      <BottomFixedButtons />
    </motion.div>
  );
}

export default Register;
