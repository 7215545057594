function RegisterInput(props) {
  const {
    name,
    type,
    value,
    onChange,
    required = true,
    placeholder,
    className,
    id,
    min,
  } = props;
  return (
    <div className="register__input-container">
      <label htmlFor={id}>
        {placeholder}
        <span style={{ color: required == false ? "#5e5e5e" : "" }}>
          {required ? "*" : " (opsionale)"}
        </span>
      </label>
      <input
        name={name}
        type={type}
        value={value}
        required={required}
        onChange={onChange}
        // placeholder={placeholder}
        className={`register__input ${className}`}
        id={id}
        min={min}
      />
    </div>
  );
}

export default RegisterInput;
