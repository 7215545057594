import Button from "components/buttons/Buttons";

function PlanProgramRegister(props) {
  const {
    theme = "blue",
    style,
    containerStyle,
    size = "md",
    redirect = "/register",
  } = props;
  return (
    <>
      <div
        style={containerStyle}
        className="programs__content-register-wrapper container"
        id="programs-register-id"
      >
        <div className="programs__content-register-content">
          <h1 style={style}>Duke krijuar gjeneraten e re të inovatorëve</h1>
          <p>Bëhu pjesë e komunitetit të jCoders. Regjistrohu tani!</p>
          <Button
            className={[`program__content-register-btn m-btn`]}
            RedirectLink={redirect}
            ButtonTitle="Regjistrohu"
            variant={theme}
            size={size}
            ariaLabel={"Regjistrohu"}
          />
        </div>
      </div>
    </>
  );
}

export default PlanProgramRegister;
