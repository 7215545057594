import img1 from "assets/images/article/help/img1.png";
import img2 from "assets/images/article/help/img2.png";
import img3 from "assets/images/article/help/img3.png";
import img4 from "assets/images/article/help/img4.png";

const images = [
  {
    id: 1,
    img: img4,
    text: "Përkrahje në vendim marrje: Inteligjenca artificiale ofron informacion te shpejt dhe të plotë, si dhe analizë në tema të ndryshme. Duke pas aftësi të procesojë sasi të mëdha të informacionit, Inteligjenca artificale mund të identifikojë paterna, trende dhe koorelime të cilat jo-domosdoshmërisht janë të dukshme nga njeriu. Kjo ofron asistence ashtu që njeriu të jetë më i informuar para së të merr vendime të caktuara.",
  },
  {
    id: 2,
    img: img3,
    text: "Personalizim: Algoritmet e inteligjencës artificale kanë aftësi që të krijojnë eksperienca të personalizuara në varësi të prefencave indviduale të secilit njeri. Qofte për blerje online, rekomandime për përmbajtje në internet, ose edhe në kujdes shendëtsor.",
  },
  {
    id: 3,
    img: img2,
    text: "Inteligjence e zgjeruar: Inteligjenca artificale zgjëron kapacitetin e secilit njeri. Përmes përdorimit të veglave te AI dhe komplementimit me shkathësitë njerzore, Inteligjenca artificiale ndihmon profesionistët në fushën e mjekësisë, financave edhe në hulumtim kështu duke rrit kapacitetin e tyre.",
  },
  {
    id: 4,
    img: img1,
    text: "Teknologji asistive: Inteligjenca artificale do të gjejë shumë përdorim edhe të njerzit me aftësi të kufizuara dhe nevoja te vecanta, kështu duke kompenzuar aftesitë e tyre në dëgjim, të pamurit, edhe në levizje.",
  },
];
function ArticleHelp() {
  return (
    <>
      <div className="article__help-container ">
        <div className="article__help-wrapper container">
          <h1>Si do të ndihmojë Inteligjenca Artificiale jetën e njeriut?</h1>
          <p>
            Si teknologji e posa liruar në publik për përdorim, Inteligjenca
            artificiale veçse ka filluar të ketë përdorim të hovshëm në
            përditshmerinë e shumë profesionistëve. Efekti i saj në zhvillimin e
            njerzimit do të shihet me kohë, mirëpo padyshim që kjo teknologji do
            të ketë shkaktojë zhvillim të përshpejtuar te njerzimit. Ajo ka
            përfitime në nxerrjën më të shpejtë të rezultateve, sidomos në
            raport me punët monotone, të përsëritshme, kështu duke lejuar
            njeriun të fokusohet në aspekte më komplekse dhe kreative të punës.
            nteligjenca artificiale kryesisht do të ndihmojë në:
          </p>
        </div>
      </div>
      <div className="article__help-content">
        {images.map((i) => {
          return (
            <div className="article__help-content-container container">
              <p>{i.text}</p>
              <div className="article__help-img">
                <img loading="lazy" src={i.img} alt="Article Image" />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ArticleHelp;
