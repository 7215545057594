import classNames from "classnames";
import PlanProgramModule from "./PlanProgramModule";

function PlanProgramsCards(props) {
  const { containerClassName, year, modules, subtitle, theme } = props;

  return (
    <>
      <div className={`content__container-prg  ${containerClassName}`}>
        <div className="content__wrapper-prg">
          <div className="content__titles-prg">
            <h1>viti {year}</h1>
            <h2>{subtitle}</h2>
          </div>

          {/* <div className="content__images-prg">
            {FetchCardData2.map((i, index) => {
              return (
                <div
                  key={index}
                  className={[
                    classNames(`content__img-wrapper ${yearTheme}-border`),
                  ]}
                >
                  <img src={i.image1} alt="Year Image" />
                </div>
              );
            })}
          </div> */}

          <div className="content__cards-container">
            {modules[year].map((i) => (
              <PlanProgramModule
                moduleTitle={i.title}
                moduleDesc={i.description}
                moduleDuration={i.duration}
                languageImg={i.bgImg}
                moduleTheme={theme}
              />
            ))}
          </div>
        </div>
        <div className={`content__line-splitter ${theme}-splitter`}></div>
      </div>
    </>
  );
}

export default PlanProgramsCards;
