import RootModal from "components/modals/RootModal";

export const ErrorModal = (props) => {
  const { open, closeModal, action, variant, captchaError = false } = props;
  return (
    <>
      <RootModal
        open={open}
        variant={variant}
        closeModal={closeModal}
        action={action}
        buttonText={"Close"}
      >
        <h1>
          Message{" "}
          <span style={{ color: "#F15B5B", fontWeight: "700" }}>Not Sent</span>
        </h1>
        <p>
          {captchaError
            ? "Recaptcha Verification Required. Sorry, we couldn`t send your message. Please verify the Recaptcha and try again."
            : "Sorry, we couldn`t send your message at this time. Please check your internet connection and try again later. If the problem persists, please contact us and we`ll be happy to assist you."}
        </p>
      </RootModal>
    </>
  );
};

export const SuccessModal = (props) => {
  const { open, closeModal, action } = props;
  return (
    <>
      <RootModal
        open={open}
        variant={"yellow"}
        closeModal={closeModal}
        action={action}
        buttonText={"Close"}
      >
        <h1>
          Email Sent{" "}
          <span style={{ color: "#F8B41A", fontWeight: "700" }}>
            Successfully
          </span>
        </h1>
        <p>
          Your message has been sent successfully. We appreciate your interest
          and will respond as soon as possible.
        </p>
      </RootModal>
    </>
  );
};
