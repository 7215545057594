import { useState, useRef, useEffect } from "react";
import Slide from "react-reveal/Slide";
import ReCAPTCHA from "react-google-recaptcha";
import { ErrorModal, SuccessModal } from "components/modals/EmailModal";
import Button from "components/buttons/Buttons";
import ContactNumber from "./home/ContactNumber";
import PhoneNumberInput from "components/inputs/PhoneNumberInput";

function ContactSection(props) {
  const { InputClassName, ContactBtnClassName, ContactBtnTitle, ContactSvg } =
    props;
  const recaptchaRef = useRef(null);
  const [formState, setFormState] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalVariant, setModalVariant] = useState(1);
  const [contactNumber, setContactNumber] = useState("");
  const [selectNumberVariant, setSelectNumberVariant] = useState("043");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };
  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectNumberVariant(value);
  };
  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status == 200) {
      document.getElementById("name").value = "";
      document.getElementById("email").value = "";
      setContactNumber("");
      document.getElementById("subject").value = "";
      document.getElementById("message").value = "";
      setOpenModal(true);
      setModalVariant(1);
      // if (recaptchaRef.current) {
      //   recaptchaRef.current.reset();
      // }
    } else {
      setOpenModal(true);
      setModalVariant(2);
    }

    return response.json();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    if (captchaVerified == false) {
      setOpenModal(true);
      setModalVariant(3);
      return;
    }

    const url = "http://mailsender.j-coders.com/api/contact";
    const data = {
      email: formState.email,
      name: formState.name,
      phone_number: phoneNumber,
      subject: formState.subject,
      message: formState.message,
    };

    const response = await postData(url, data);
  }
  useEffect(() => {
    setPhoneNumber(selectNumberVariant + "-" + contactNumber);
  });

  return (
    <>
      <Slide top cascade>
        <div className="contact__info">
          <Slide top cascade>
            <form className="contact__inputs" onSubmit={handleSubmit}>
              <input
                type="email"
                required
                placeholder="Emaili"
                className={[`email contact__input ${InputClassName}`]}
                id="email"
                name="email"
                onChange={handleChange}
              />
              <input
                type="text"
                required
                placeholder="Emri dhe mbiemri"
                className={[`name contact__input ${InputClassName}`]}
                id="name"
                name="name"
                onChange={handleChange}
              />
              <PhoneNumberInput
                inputChange={setContactNumber}
                selectChange={handleSelectChange}
                value={contactNumber}
                className={"number contact__contrast-color"}
                placeholder={"Numri i telefonit"}
              />
              <input
                type="text"
                required
                placeholder="Subjekti"
                className={[`subject contact__input ${InputClassName}`]}
                id="subject"
                name="subject"
                onChange={handleChange}
              />
              <textarea
                name="message"
                id="message"
                className={[`message contact__input ${InputClassName}`]}
                placeholder="Mesazhi"
                cols="30"
                required
                rows="10"
                onChange={handleChange}
              ></textarea>

              {(captchaVerified && (
                <button
                  type={captchaVerified ? "submit" : ""}
                  className={[
                    `contact__btn btn  ${
                      captchaVerified ? "blue-btn" : "disabled-btn"
                    } `,
                  ]}
                  disabled={!captchaVerified}
                  aria-label="Submit Form"
                >
                  {ContactBtnTitle}
                </button>
              )) || (
                <button
                  className={[
                    `contact__btn btn  ${
                      captchaVerified ? "blue-btn" : "disabled-btn"
                    } `,
                  ]}
                  disabled={true}
                  aria-label="Submit Message"
                >
                  {ContactBtnTitle}
                </button>
              )}

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ReCAPTCHA
                  sitekey="6Ld_F2EmAAAAAPyPmbKdKuc6hqF6CeoSnl_faXoy"
                  ref={recaptchaRef}
                  onChange={() => {
                    setCaptchaVerified(true);
                  }}
                  onExpired={() => {
                    setCaptchaVerified(false);
                  }}
                  size={"compact"}
                />
              </div>
            </form>
          </Slide>
          <div className="contact__svg">
            <img src={ContactSvg} alt="Contact Us Image" />
          </div>
        </div>
      </Slide>

      {(modalVariant == 1 && (
        <SuccessModal
          open={openModal}
          className={""}
          variant={"yellow"}
          closeModal={() => {
            setOpenModal(false);
          }}
          action={() => {
            setOpenModal(false);
          }}
          buttonText={"close"}
        />
      )) ||
        (modalVariant == 2 && (
          <ErrorModal
            open={openModal}
            className={""}
            variant={"red"}
            closeModal={() => {
              setOpenModal(false);
            }}
            action={() => {
              setOpenModal(false);
            }}
            buttonText={"close"}
            captchaError={false}
          />
        )) ||
        (modalVariant == 3 && (
          <ErrorModal
            open={openModal}
            className={""}
            variant={"red"}
            closeModal={() => {
              setOpenModal(false);
            }}
            action={() => {
              setOpenModal(false);
            }}
            buttonText={"close"}
            captchaError={true}
          />
        ))}
    </>
  );
}

export default ContactSection;
