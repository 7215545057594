import { IoIosArrowUp } from "react-icons/io";

function ScrollToTopBtn(props) {
  const { className } = props;

  const ScrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <button
        className={`scroll-btn ${[[...className]]}`}
        onClick={ScrollToTop}
        aria-label="Shko në krye"
      >
        <IoIosArrowUp />
      </button>
    </>
  );
}

export default ScrollToTopBtn;
