import HeroTitles from "components/sections/home/components/HeroTitles";
import FadeCarousel from "components/carousels/FadeCarousel";

// Importing Assets
import img1 from "assets/images/heroImages/foto1.webp";
import img2 from "assets/images/heroImages/foto2.webp";
import img3 from "assets/images/heroImages/foto3.webp";
import img4 from "assets/images/heroImages/foto4.webp";
import img6 from "assets/images/heroImages/foto6.webp";
import img7 from "assets/images/heroImages/foto7.webp";
import img8 from "assets/images/heroImages/foto8.webp";
import img9 from "assets/images/heroImages/foto9.webp";
import img10 from "assets/images/heroImages/foto10.webp";

const sliderImg = [
  { id: 1, img: img1 },
  { id: 2, img: img2 },
  { id: 3, img: img3 },
  { id: 4, img: img4 },
  { id: 6, img: img6 },
  { id: 7, img: img7 },
  { id: 8, img: img8 },
  { id: 9, img: img9 },
  { id: 10, img: img10 },
];

function HeroSection() {
  return (
    <>
      <div className="home__container">
        <div className="home__text">
          <HeroTitles />
        </div>
      </div>
      <div className="home__slider">
        <FadeCarousel
          images={sliderImg}
          style={{ height: "100%" }}
          className={"home__slider-img"}
          alt={"Home slider images"}
        />
      </div>
    </>
  );
}

export default HeroSection;
