import classNames from "classnames";

const theme = {
  blue: { color: "#40b1e5" },
  red: { color: "#f15b5b" },
  purple: { color: "#9f64a8" },
  yellow: { color: "#ffb000" },
  green: { color: "#8dc63f" },
};

function PlanProgramModule(props) {
  const {
    moduleTitle,
    moduleDesc,
    moduleDuration,
    languageImg,
    moduleTheme,
    key,
    style,
  } = props;
  const _theme = theme[moduleTheme].color;

  return (
    <>
      <div key={key} className="content__card">
        <div className="content__card-wrapper">
          <div className="content-seperator">
            <div className="content__card-title-cont">
              <h1>{moduleTitle}</h1>
              <div
                className={[classNames(`line-text ${moduleTheme}-line`)]}
              ></div>
            </div>

            <p>{moduleDesc}</p>
          </div>
          <div className="content-seperator">
            <h2>
              Kohëzgjatja:{" "}
              <span style={{ color: _theme }}>
                {moduleDuration} {moduleDuration > 1 ? "Seanca" : "Javë"}
              </span>{" "}
            </h2>
          </div>
        </div>

        <div className="language-img">
          <img src={languageImg} alt="Language Image" />
        </div>
      </div>
    </>
  );
}

export default PlanProgramModule;
