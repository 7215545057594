import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "components/buttons/Buttons";
import Stem_icon from "assets/images/stem_icon.png";
import Socials from "components/buttons/Socials";

function MobileNavbar(props) {
  const { collapse, toggleMenu, errorPage = false, hide } = props;
  const [hiddenNav, setHiddenNav] = useState(false);

  useEffect(() => {
    if (errorPage) {
      setHiddenNav(errorPage);
    } else {
      setHiddenNav(errorPage);
    }
  }, [errorPage]);
  return (
    <>
      {/* Mobile Navbar */}
      <div className={`nav__menu-mobile ${!collapse ? "open-nav" : ""}`}>
        <div className="nav__menu-mobile-wrapper">
          <div className="nav-splitter"></div>
          {hide == false && (
            <ul>
              <li>
                <NavLink
                  activeclassname="active-link"
                  onClick={toggleMenu}
                  to={"/"}
                  aria-label="Ballina"
                >
                  Ballina
                </NavLink>
              </li>
              <li className={`${hiddenNav ? "hidden-navLink" : ""}`}>
                <NavLink
                  activeclassname={`active-link ${
                    hiddenNav ? "hidden-navLink" : ""
                  }`}
                  onClick={toggleMenu}
                  to="/programs"
                  aria-label="Programet"
                >
                  Programet
                </NavLink>
              </li>
              <li className={`${hiddenNav ? "hidden-navLink" : ""}`}>
                <NavLink
                  activeclassname={`active-link ${
                    hiddenNav ? "hidden-navLink" : ""
                  }`}
                  onClick={toggleMenu}
                  to="/projects"
                  aria-label="Projektet"
                >
                  Projektet
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeclassname="active-link"
                  onClick={toggleMenu}
                  to="/about"
                  aria-label="Për ne"
                >
                  Për ne
                </NavLink>
              </li>
              <li className={`${hiddenNav ? "hidden-navLink" : ""}`}>
                <NavLink
                  activeclassname={`active-link`}
                  onClick={toggleMenu}
                  to="/media"
                  aria-label="Çka ka t’re"
                >
                  Çka ka t’re
                </NavLink>
              </li>

              <li>
                <NavLink
                  activeclassname="active-link"
                  onClick={toggleMenu}
                  to="/contact"
                  aria-label="Kontakt"
                >
                  Kontakt
                </NavLink>
              </li>
              <li>
                <Button
                  buttonAction={toggleMenu}
                  className={["nav__btn"]}
                  RedirectLink="/register"
                  ButtonTitle="Regjistrohu"
                  variant={"yellow"}
                  size={"xs"}
                  ariaLabel={"Regjistrohu"}
                />
              </li>
            </ul>
          )}
          <div className="nav__credits">
            <div className="nav__stem-img-mobile">
              <img src={Stem_icon} alt="STEM Certification Image" />
            </div>
            <Socials credits={false} />
            <p>+386(0)49454929</p>
            <p>© 2015 - 2023 jCoders. All Rights Reserved️</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MobileNavbar;
