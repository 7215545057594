import { success } from "./list/successes";

// Importing carousel
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination } from "swiper";

// Importing Slider information
import SliderInformation from "./SliderInformation";

function SuccessSection(props) {
  const { style } = props;

  return (
    <>
      <Swiper
        effect="fade"
        navigation={true}
        pagination={{ clickable: true }}
        modules={[EffectFade, Navigation, Pagination]}
        className="success__carousel"
        slidesPerView={1}
        autoHeight={true}
      >
        {success.map((i, index) => {
          return (
            <SwiperSlide>
              <SliderInformation
                style={style}
                image={i.img}
                title={i.title}
                desc={i.desc}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
}

export default SuccessSection;
