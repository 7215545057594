import Button from "components/buttons/Buttons";

function ArticleHeader(props) {
  const { title, description, action } = props;
  return (
    <>
      <div className="article__header-container container">
        <h1>{title}</h1>
        <p>{description}</p>
        <Button
          className={[`article__header-btn `]}
          ButtonTitle="Më Shumë"
          variant={"blue"}
          size={"md"}
          buttonAction={action}
        />
      </div>
    </>
  );
}

export default ArticleHeader;
