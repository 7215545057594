import { UilAngleRight } from "@iconscout/react-unicons";

function MediaCards(props) {
  const { key, title, description, img, href, date } = props;

  const [day, month, year] = date.split("/");
  const formattedDate = day + "-" + month + "-" + year;

  return (
    <>
      <div key={key} className="media__cards">
        <div className="media__cards-container">
          <div className="media__cards-img">
            <img loading="lazy" src={img} alt="Media Image" />
          </div>
          <div className="media__cards-content">
            <h1>{title}</h1>
            <span></span>
            <p>{description}</p>
          </div>
          <div className="media__cards-button">
            <a href={href} target="_blank" aria-label="View More">
              Më shumë <UilAngleRight />
            </a>
            <h2>{formattedDate}</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default MediaCards;
