import { useEffect, useState } from "react";
import MediaCards from "pages/media/components/MediaCards";
import { media } from "helpers/media/media";

function MediaCardsSection() {
  const [sortedItems, setSortedItems] = useState([]);

  useEffect(() => {
    const sortedMedia = [...media];

    sortedMedia.sort((a, b) => {
      const dateA = new Date(a.date.split("/").reverse().join("-"));
      const dateB = new Date(b.date.split("/").reverse().join("-"));
      return dateB - dateA;
    });

    setSortedItems(sortedMedia);
  }, []);

  return (
    <>
      <div className="media__cards-wrapper container">
        {sortedItems.map((item) => (
          <MediaCards
            key={item.id}
            title={item.title}
            description={item.description}
            date={item.date}
            img={item.img}
            href={item.link}
          />
        ))}
      </div>
    </>
  );
}

export default MediaCardsSection;
