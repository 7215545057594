import user from "assets/images/icons/user.png";
import users from "assets/images/icons/users.png";
import multiUser from "assets/images/icons/multi_user.png";

function PlanProgramTable() {
  const data1 = [
    { icon: user, name: "1 nxënës", offers: "15%", price: "136€" },
    { icon: users, name: "2 nxënës", offers: "15%", price: "136€/për nxënës" },
    {
      icon: multiUser,
      name: "3+ nxënës",
      offers: "20%",
      price: "128€/për nxënës",
    },
  ];
  const data2 = [
    { icon: user, name: "1 nxënës", offers: "10%", price: "144€" },
    { icon: users, name: "2 nxënës", offers: "10%", price: "144€/për nxënës" },
    {
      icon: multiUser,
      name: "3+ nxënës",
      offers: "15%",
      price: "136€/për nxënës",
    },
  ];

  return (
    <div className="plan__program-table-container">
      <div className="plan__program-table-content">
        <div className="plan__program-table-titles">
          <h4>PËR REGJISTRIMET PARA DATËS</h4>
          <h1>01 Qershor</h1>
        </div>
        <table className="plan__program-table">
          <thead className="plan__program-table-head">
            <tr>
              <th></th>
              <th>
                <h2>Zbritje</h2>
              </th>
              <th>
                <h2>Çmimi</h2>
              </th>
            </tr>
          </thead>
          <tbody className="plan__program-table-body">
            {data1.map((item, index) => (
              <tr key={index}>
                <td>
                  <div>
                    <img src={item.icon} alt="Table Icon" />
                  </div>
                  <h2>{item.name}</h2>
                </td>
                <td>
                  <h2>{item.offers}</h2>
                </td>
                <td>
                  <h2>{item.price}</h2>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="plan__program-table-content">
        <div className="plan__program-table-titles">
          <h4>PËR REGJISTRIMET NË MES DATAVE</h4>
          <h1>01 - 10 Qershor</h1>
        </div>
        <table className="plan__program-table">
          <thead className="plan__program-table-head">
            <tr>
              <th></th>
              <th>
                <h2>Zbritje</h2>
              </th>
              <th>
                <h2>Çmimi</h2>
              </th>
            </tr>
          </thead>
          <tbody className="plan__program-table-body">
            {data2.map((item, index) => (
              <tr key={index}>
                <td>
                  <div>
                    <img src={item.icon} alt="Table icon" />
                  </div>
                  <h2>{item.name}</h2>
                </td>
                <td>
                  <h2>{item.offers}</h2>
                </td>
                <td>
                  <h2>{item.price}</h2>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PlanProgramTable;
