import Fade from "react-reveal/Fade";
import ContactSection from "components/sections/ContactSection";
import { motion } from "framer-motion";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";
import "scss/css/contact/contactStyle.css";

// Importing Layout
import NavBarr from "components/layout/NavBarr";
import Footer from "components/layout/footer/Footer";

// Importing Assets
import ContactSvg from "assets/images/illustrations/image4.webp";

export default function Contact() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavBarr contrast={true} />

      <div className="contact__bloody-svg"></div>

      <section className="contact">
        <Fade top cascade>
          <div className="contact__container">
            <div className="contact__title">
              <h1>Kontakt</h1>
              <h2>
                Na kontaktoni përmes formularit poshtë ose në email
                info@j-coders.com
              </h2>
            </div>
            <ContactSection
              ContactBtnClassName={["blue-btn"]}
              InputClassName="contact__contrast-color"
              ContactBtnTitle="Dërgo Mesazhin"
              ContactSvg={ContactSvg}
            />
            <div className="contact__desc">
              <p>
                Want to become a partner ? <br />
                <span>
                  {" "}
                  If you have the passion and the energy to start your own
                  impact in educating the youth for their future in STEM, and
                  you have the will to take actions to invest in your future
                  while enabling the parents, the schools, teachers and
                  communities to invest for a better future for their children.
                  Contact us and learn how jCoders Academy is helping people
                  like you make an impact by offering Methodology, Systems,
                  Tools and Curriculum.
                </span>
                <br />
                info@j-coders.com
              </p>
            </div>
          </div>
        </Fade>
      </section>

      <BottomFixedButtons />
      <Footer variant={"hidden"} className={"contact-footer"} />
    </motion.div>
  );
}
