import { motion } from "framer-motion";
import RegisterSection from "components/sections/RegisterSection";
import "scss/css/register/registerStyle.css";

// Importing Layout
import Footer from "components/layout/footer/Footer";
import NavBarr from "components/layout/NavBarr";
import RegisterHero from "./sections/RegisterHero";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";

// gzim@labbox.education
// 20B8FE5C062B02740AF155B8832DDA4433C9
// smtp.elasticemail.com

function Register() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavBarr contrast={true} />

      <section className="register__hero">
        <RegisterHero />
      </section>
      <section className="register">
        <div className="register__container">
          <div className="register__title">
            <h1>Regjistrohuni</h1>
            <h2>
              Për t’a regjistruar fëmijën tuaj në Akademinë jCoders, ju lutem
              plotësoni formën e më poshtme me të dhënat për fëmijën tuaj. Ju
              faleminderit që keni zgjedh jCoders 🙂.
            </h2>
            {/* <div className="register__summer">
              <h2>
                Për tu regjistruar në kampin veror klikoni{" "}
                <a href="/summerRegister">këtu</a>
              </h2>
            </div> */}
          </div>

          <RegisterSection />
          <p>
            Te dhenat tuaja jane te mbrojtuara sipas LIGJIT PËR MBROJTJEN E TË
            DHËNAVE PERSONALE, LIGJI Nr. 03/L-172
          </p>
        </div>
      </section>
      <Footer variant={"lightGray"} />
      <BottomFixedButtons />
    </motion.div>
  );
}

export default Register;
