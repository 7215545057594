import classNames from "classnames";

const theme = {
  blue: { color: "#40b1e5", bg: "204, 239, 255", stroke: "" },
  red: { color: "#f15b5b", bg: "255, 204, 204", stroke: "" },
  purple: { color: "#9f64a8", bg: "248, 204, 255", stroke: "" },
  yellow: { color: "#ffb000", bg: "255, 239, 204", stroke: "" },
};

function PlanProgramModule(props) {
  const {
    moduleTitle,
    moduleDesc,
    moduleDuration,
    languageImg,
    moduleTheme,
    key,
    style,
  } = props;
  const _theme = theme[moduleTheme].color;
  const bgColor = theme[moduleTheme].bg;

  return (
    <>
      {/* { border: `2px solid rgba(64, 177, 229, 0.7)` } */}
      <div
        style={style}
        key={key}
        className={`content__card ${moduleTheme}-outline`}
      >
        <div className="content__card-wrapper">
          <div className="content-seperator">
            <div className="content__card-title-cont">
              <h1 style={{ color: _theme }}>{moduleTitle}</h1>
              <div
                className={[classNames(`line-text ${moduleTheme}-line`)]}
              ></div>
            </div>

            <p>{moduleDesc}</p>
          </div>
          <div className="content-seperator">
            <h2>
              Kohëzgjatja:{" "}
              <span style={{ color: _theme }}>
                {moduleDuration} {moduleDuration > 1 ? "Seanca" : "Javë"}
              </span>{" "}
            </h2>
          </div>
        </div>

        <div className="language-img">
          <img src={languageImg} alt="Language Image" />
        </div>
        <span
          style={{
            content: '""',
            position: "absolute",
            top: "0",
            right: "0",
            left: "0",
            bottom: "0",
            width: "100%",
            height: "100%",
            zIndex: "0",
            borderRadius: "inherit",
            backgroundColor: `rgba(${bgColor}, 0.4)`,
          }}
        />
      </div>
    </>
  );
}

export default PlanProgramModule;
