import jcodersIllustration from "assets/images/illustrations/jcoders__icon.webp";

function SliderInformation(props) {
  const { style, key, image, title, desc } = props;
  return (
    <>
      <div style={style} key={key} className="slider__div-container">
        <div className="slider__img-container">
          <div className="slider__img">
            <img loading="lazy" src={image} alt="Slider Info Image" />
          </div>
        </div>
        <div className="slider__info">
          <h1>{title}</h1>
          <p>{desc}</p>
          <div className="slider__info-img">
            <img
              loading="lazy"
              src={jcodersIllustration}
              alt="Slider Illustration Image"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SliderInformation;
