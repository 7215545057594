import img2 from "assets/images/media/cards/jcodersKlanKosova.webp";
import img3 from "assets/images/media/cards/jcodersKtv.webp";
import img4 from "assets/images/media/cards/jcodersTribune.webp";
import img5 from "assets/images/media/cards/jcodersKadriVeseli.webp";
import img6 from "assets/images/media/cards/jcodersMirmengjesiKosov.webp";
import img7 from "assets/images/media/cards/jcodersBonBon.webp";
import img8 from "assets/images/media/cards/jcodersTopShow.webp";
import img9 from "assets/images/media/cards/jcodersSot.webp";
import img10 from "assets/images/media/cards/jcodersImazhi.webp";
import img11 from "assets/images/media/cards/jcodersImazh2.webp";
import img12 from "assets/images/media/cards/jcodersTribuna.webp";
import img13 from "assets/images/media/cards/jcodersKtv2.webp";
import img14 from "assets/images/media/cards/jcodersRtk.webp";
import img15 from "assets/images/media/cards/jcodersAtv.webp";
import img16 from "assets/images/media/cards/jcodersOraPasdites.webp";
import img17 from "assets/images/media/cards/jCodersOxygen.webp";
import img18 from "assets/images/media/cards/jCodersTopChannel.webp";

export const media = [
  {
    id: 1,
    title: "Artikulli i Telegrafit per jCoders",
    description:
      "Në Prishtinë, 13 të rinj, të moshës 8-15 vjet, janë shpërblyer për punën e tyre të palodhshme dhe për aftësitë e marra gjatë vijimit të një trajnimi intensiv të programimit të mbajtur nga Akademia jCodersKëta të rinj kanë përvetësuar gjuhen programuese Scratch, e cila është e krijuar nga institucioni lider për teknologji informative MIT, dhe që është lider në kodim për të rinj.",
    img: "https://telegrafi.com/wp-content/uploads/2016/03/femijet1.jpg",
    link: "https://telegrafi.com/13-femije-certifikohen-si-programer-nga-akademia-jcoders/?fbclid=IwAR2LpjJIxJWlnVexn4lzlsb0KZRlE-1A30ye0t2sygJz1HzblcPEekecJKI",
    date: "14/03/2016",
  },
  {
    id: 2,
    title:
      "Reportazhën të lajmeve për maratonën njëditore të Akademisë jCoders",
    description:
      "Reportazhën në edicionin qëndror të lajmeve për maratonën njëditore të programimit për fëmijët e Akademisë jCoders mund ta ndiqni më poshtë. Falemnderit Klan Kosova.",
    img: img2,
    link: "https://www.facebook.com/jCodersEdu/videos/376692949168003/",
    date: "05/04/2016",
  },
  {
    id: 3,
    title:
      "Vajzat ne TIK mund ta ndiqni intervisten me drejtoreshen ekzekutive te Kosovo ICT Association",
    description:
      "Per nder te dites nderkombetare te vajzave ne TIK mund ta ndiqni intervisten me drejtoreshen ekzekutive te Kosovo ICT Association - STIKKznj.Vjollca Cavolli dhe bashkethemeluesen e Akademise jCoders Arta Shehu Zaimi ne KTV - Kohavision",
    img: img3,
    link: "https://www.youtube.com/watch?v=sjXsf7iDqi8&fbclid=IwAR0ib-JUYf8duA7EQZW6kVn2cjN00hskCs8MgTBQQkvqA-YDYlMySyNjZ9c",
    date: "30/04/2016",
  },
  {
    id: 3,
    title: "jCoders në emisionin Oxygen",
    description:
      "Ne emisionin Oxygen te Shtunen nga 20:30 ndiqni femijet e jCoders, femijet e pare te cilet marin pjese ne Kuiz. Legendary",
    img: img17,
    link: "https://www.facebook.com/jCodersEdu/photos/pcb.387080384795926/387080204795944/?type=3&theater",
    date: "04/05/2016",
  },
  {
    id: 4,
    title: "jCoders Academy në Top Channel emisioni TOP SHOW",
    description:
      "Top Channel në emisionin TOP SHOW, në pjesën e tretë prezentohet puna dhe vlerësimi i Akademisë jCoders i cili u shënua ne ICT Awards IV",
    img: img18,
    link: "https://www.facebook.com/ictawards.org/photos/a.173878102768724/615266728629857/?type=3&theater",
    date: "01/06/2016",
  },
  {
    id: 5,
    title: "Mëngjesi në Tribunë - Mysafiri Arta Shehu",
    description:
      "Intervista e dedikuar Hackathonit te trete ne Tribuna Channel gjate te ciles shihet puna dhe suksesi i femijeve. Intervisten mund ta ndiqni ne linkun meposhte.",
    img: img4,
    link: "https://www.youtube.com/watch?v=BgmxMOg6Ubk&feature=youtu.be&fbclid=IwAR2bfKKwbzgihJ9_TZz_cwscIZUjwQoAWNRzYn_nyjqE0swtbDS6NMnqjn0",
    date: "15/06/2016",
  },
  {
    id: 6,
    title: "Telegrafi për jCoders: Kampi Robo",
    description:
      "Kampi Robo” është kamp residencial njëjavor, në Parkun e Gërmisë në Prishtinë, në të cilin të rinjtë e Kosovës e moshës 12-15-vjeçare, do të kenë mundësinë që t’i thellojnë njohuritë e tyre për teknologjinë dhe natyrën Programi i kampit është dizajnuar ashtu që të rinjtë të mësojnë dhe zbaviten përmes: • Teknologjisë, meqë të rinjtë do të mësojnë për teknologjinë informative në mënyrë interkative, duke fituar konceptet bazë të programimit dhe elektronikës. Gjatë kampit do të krijohen programe dhe lojëra – duke kombinuar materialet nga natyra, programimin dhe elektronikën.",
    img: "https://telegrafi.com/wp-content/uploads/2016/06/robokamp.jpg",
    link: "https://telegrafi.com/kampi-robo-per-femijet-e-moshes-12-15-vjecare-aty-ku-natyra-ndeshet-teknologjine/?fbclid=IwAR18nmd5o40LZSehnzEyavv-y3XVC8_oBn1Pk-U50jhoOYkFr2LlOmTH1Ik",
    date: "27/06/2016",
  },
  {
    id: 7,
    title: "Telegrafi për jCoders",
    description:
      "Pse duhet secili fëmijë të mësojë programim dhe teknologji në akademinë jCoders? Akademia jCoders ka filluar t’i mësojë fëmijët dhe të rinjtë programim dhe teknologji nga viti 2015. Kjo akademi që nga atëherë ka regjistruar një numër të madh të nxënësve, prej të cilëve tashmë kanë filluar të shihen projekte kogja interesante te cilat janë ekspozuar sivjet edhe në DokuTech Pranë akademisë jCoders po ndërtohen gjeneratat e reja të cilat këtij vendi do t’i sjellin një të ardhme më të mirë.",
    img: "https://telegrafi.com/wp-content/uploads/2016/08/f1-5.jpg",
    link: "https://telegrafi.com/pse-duhet-secili-femije-te-mesoje-programim-dhe-teknologji-ne-akademine-jcoders/",
    date: "16/08/2016",
  },
  {
    id: 8,
    title: "Telegrafi për jCoders",
    description:
      "7 shkathtësitë e nevojshme për fëmijën tuaj ashtu që të jetë i përgatitur për të ardhmen. Të gjithë prindërit dëshirojnë më të mirën për fëmijët e tyre. Nga sigurimi i mirëqenies së tyre e deri te ofrimi i mundësive për zhvillimin e shkathtësive të nevojshme për të ardhmen e tyre. Fusha e shkencave kompjuterike është më e fuqishme se kurrë më parë, dhe përdoret në c`do fushë dhe gati çdo profesion. Është jashtëzakonisht e rëndësishme që të rinjtë të ekspozohen ndaj teknologjisë në moshë të re, në aspekt aktiv, duke ndërtuar rolin e tyre si krijues teknologjik. Dhe është shumë e rëndësishme që fëmijët të kuptojnë që bota rreth tyre lejon mundësi të pafundme.",
    img: "https://telegrafi.com/wp-content/uploads/2016/08/IMGL9798.jpg",
    link: "https://telegrafi.com/7-shkathtesite-e-nevojshme-per-femijen-tuaj-ashtu-qe-te-jete-pergatitur-per-te-ardhmen/?fbclid=IwAR1Y8g6nwr0DTPjiGMT6YsKBF4HX4JrPeZ21200TK1XKNy19W-Qi2ZsllOM",
    date: "26/08/2016",
  },
  {
    id: 9,
    title: "Vizita e Kryetarit Kadri Veseli",
    description:
      "Kryetari i Kuvendit të Republikës së Kosovës, z. Kadri Veseli, në kuadër të vizitës në ICK, vizitoj Akademinë jCoders. Mbesim krenar me punën e fëmijëve tonë të cilët me mendjet e tyre te ndritura orët i bëjnë kreative, inovative dhe energjike, me punën e kolegëve ne Formon të cilët punojnë për të sjell një frymë inovative në vend, dhe krenar që kemi mbështetjën e Innovation Centre Kosovo.",
    img: img5,
    link: "https://www.facebook.com/jCodersEdu/videos/435205456650085/",
    date: "08/09/2016",
  },
  {
    id: 10,
    title: "Mirmengjesi Kosove-Intervista.",
    description:
      "Miremengjesi Kosove - Mysafiri i Mengjesit Arta Shehu Zaimi (27.07.2017) ",
    img: img6,
    link: "https://www.youtube.com/watch?v=UimCzLZRJ4E&fbclid=IwAR3LOM_glNKRvazX3yYkjkL-2lCqYl0Ym6kVhS6gNZXGZOuK2QGs_QLoGtk",
    date: "01/08/2017",
  },
  {
    id: 11,
    title: "jCoders ne emisionin Bon Bon RTV 21",
    description:
      "jCoders demonstron funksionin e dorezes e cila perkthen gjuhen e shenjave ne emisionin Bon Bon RTV 21.",
    img: img7,
    link: "https://www.youtube.com/watch?v=0WE42aeDgY4&feature=youtu.be&fbclid=IwAR31LyZnuhrsZT-pLsCR6KRNKurq5QiIwOoeI3xDyR8iIF9as51l5TLtpiw",
    date: "19/03/2017",
  },
  {
    id: 12,
    title: "Telegrafi-Fëmijët në Akademinë jCoders",
    description:
      "Gjatë kohës që shkollat janë në pushim vjetor, jCoders hap akademinë verore gjatë të cilës fëmijët e moshës 8-13 dhe te rinjtë e moshës 13 deri 18 do të mësojnë më shumë rreth krijimeve teknologjike duke krijuar dhe sfiduar vetën. Kësaj radhe planprogrami dhe aktivitetet janë më intensive dhe më atraktive së kurrë më parë.",
    img: "https://telegrafi.com/wp-content/uploads/2017/07/1-463.jpg",
    link: "https://telegrafi.com/femijet-ne-akademine-jcoders/?fbclid=IwAR0-gl5iGMu5Ig7Vfwz_xtloMHORGt1l6EOhypeKTIXKeWvTUTMEgqpd6-Y",
    date: "24/07/2017",
  },
  {
    id: 13,
    title: "TOP SHOW - Raesa me dorezen perkthyese",
    description:
      '"Kjo eshte doreza e cila i jep zerin memeceve, dhe i jep shembull gjithe femijeve qe me nje arduino nje kompjueter edhe disa paisje te vogla mundesh me i kriju shpikje qe munden me ndihmu rreth 70 milion njerez." - Raesa ne TOP SHOW',
    img: img8,
    link: "https://www.facebook.com/jCodersEdu/videos/544974495673180/",
    date: "12/05/2017",
  },
  {
    id: 14,
    title: 'Hackathoni me moton "Ne ndërtojmë të ardhmen"',
    description: "JCoders me projekte te reja ne tekonologji",
    img: img9,
    link: "https://www.youtube.com/watch?v=21QwZhrjg2U&feature=youtu.be&fbclid=IwAR1phqRYTGI2EwBHIdX3LBwVmLBnZwYp7oLucfk9WVTXssRPI1QMOkbaOJk",
    date: "08/07/2018",
  },
  {
    id: 15,
    title: "jCoders ne Imazhi - RTK",
    description:
      '"jCoders mision ka ndertimin e gjeneratave te reja ne fushen e teknologjise duke perdorur metodologji inovative ne mesimdhenie, te cilat qojne drejt profesioneve teknike. "Faleminderit RTK per storjen rreth disa prej projekteve te nxenesve tone!',
    img: img10,
    link: "https://www.facebook.com/jCodersEdu/videos/655252367978725/",
    date: "17/01/2018",
  },
  {
    id: 16,
    title: "Aplikacioni per sistemin periodik",
    description:
      "Per shkak se ne libra shkollor ka mungese te informatave te detajuara rreth sistemit periodik, Lorani ka vendos me ju ndihmu moshatareve te tij duke e kriju aplikacionin qe permban informata shtese rreth ketij sistemi🔬🤓",
    img: img11,
    link: "https://www.facebook.com/jCodersEdu/videos/668578883312740/",
    date: "16/02/2018",
  },
  {
    id: 17,
    title: '"The City of Logic" finalist ne KosICT',
    description:
      'Nxenesja jone Vesa e ka krijuar aplikacionin "City of Logic" me te cilin ishte finaliste kete vit ne ICT Awards. Ndiqni nje pjese te shkurter nga intervista e saj ku e shpjegon se si e ka bere mesimin atraktiv per perdoruesit e aplikacionit. Faleminderit Tribuna Channel',
    img: img12,
    link: "https://www.facebook.com/jCodersEdu/videos/718883984948896/",
    date: "07/06/2018",
  },
  {
    id: 18,
    title: "KTV-Nga nxënës të jCoders në Trajner dhe Asistent Trajner.",
    description:
      "Nga nxënës të jCoders në Trajner dhe Asistent Trajner! Ju ftojmë që të ndiqni këtë storje nga KTV - Kohavision për më shumë",
    img: img13,
    link: "https://www.facebook.com/watch/?v=671534651382010",
    date: "02/12/2022",
  },
  {
    id: 19,
    title: "jCoders Academy mysafir në RTK",
    description:
      "RTK-Mysafiri i Mëngjesit - Fiona Shehu, Akademia jCoders 25.02.2022",
    img: img14,
    link: "https://www.youtube.com/watch?v=DsPylEssTEY",
    date: "25/02/2022",
  },
  {
    id: 20,
    title: "ATV Live - Njoftohu me punën dhe programet në jCoders",
    description:
      "Po e ndajmë me ju këtë video storje që të njoftoheni më shumë me punën dhe misionin e jCoders. Gjendemi në Prishtinë, Prizren, Pejë, Mitrovicë, Gjilan dhe jeni të mirëpritur të na vizitoni në ambientet tona të mbushura më fëmijë të lumtur duke mësuar dhe krijuar gjëra të reja rreth teknologjisë. E falenderojmë mediumin ATV LIVE për vizitën dhe storjen",
    img: img15,
    link: "https://www.facebook.com/jCodersEdu/videos/1355703851468149",
    date: "31/03/2021",
  },
  {
    id: 21,
    title:
      "Ora e Pasdites në Klan Kosova-Muaji mars dedikuar vajzave në teknologji",
    description:
      "Muaji mars dedikuar vajzave në teknologji Muaji i tretë i vitit, merr me vete edhe datën 8 Mars, ditën ndërkombëtare të gruas. Po ky muaj në vendin tonë po konsiderohet edhe si “Muaji i vajzave në teknologji”. Përgjatë 31 ditëve të këtij muaji do të ketë evente me tema të ndryshme, jo vetëm në Prishtinë por edhe në komuna të tjera të Kosovës. Për më shumë detaje, ju ftojmë ta ndjekni kronikën në vazhdim.",
    img: img16,
    link: "https://www.facebook.com/OraePasditesOfficial/videos/775261546727094",
    date: "12/03/2021",
  },

  // // copy Item
  //   {
  //       id: 0,
  //       title: '',
  //       description: '',
  //       img: img3,
  //       link: "",
  //       date: "",
  //   },
];
