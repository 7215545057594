import { useEffect, useRef, useState } from "react";
import { BsPause, BsPlay } from "react-icons/bs";

// Importing assets

function AboutVideo(props) {
  const { src, className } = props;
  const [scroll, setScroll] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);
  const buttonRef = useRef(null);
  const wrapperRef = useRef(null);

  const handlePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    let timeoutId;

    const handleMouseMove = () => {
      if (isPlaying) {
        buttonRef.current.classList.remove("hide-btn");
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          buttonRef.current.classList.add("hide-btn");
        }, 600);
      }
    };

    const handleVideoEnded = () => {
      document.getElementById("navbar").classList.remove("hide-navbar");
      buttonRef.current.classList.remove("hide-btn");
      videoRef.current.pause();
      clearTimeout(timeoutId);
      setIsPlaying(false);
    };

    if (isPlaying) {
      document.getElementById("navbar").classList.add("hide-navbar");
      videoRef.current.play();
      buttonRef.current.classList.remove("hide-btn");
      timeoutId = setTimeout(() => {
        buttonRef.current.classList.add("hide-btn");
      }, 850);
    } else {
      document.getElementById("navbar").classList.remove("hide-navbar");
      buttonRef.current.classList.remove("hide-btn");
      videoRef.current.pause();
    }

    videoRef.current.addEventListener("ended", handleVideoEnded);
    if (isPlaying) {
      wrapperRef.current.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      clearTimeout(timeoutId);
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener("mousemove", handleMouseMove);
        videoRef.current.removeEventListener("ended", handleVideoEnded);
      }
    };
  }, [isPlaying]);

  useEffect(() => {
    if (window.location.href.includes("about")) {
      if (isPlaying) {
        window.addEventListener("scroll", () => {
          if (isPlaying) {
            document.getElementById("navbar").classList.remove("hide-navbar");
          }
        });
      }
    } else {
    }
  }, [isPlaying]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1600) {
        setIsPlaying((prevState) => {
          document.getElementById("navbar").classList.remove("hide-navbar");
          buttonRef.current.classList.remove("hide-btn");
          videoRef.current.pause();
        });
      }
    });
  }, []);

  return (
    <>
      <div
        className={`video__wrapper ${isPlaying ? "video__playing" : ""}`}
        ref={wrapperRef}
      >
        <div className="video__button-wrapper" onClick={handlePlay}></div>
        <button
          ref={buttonRef}
          onClick={handlePlay}
          className={`play__button`}
          aria-label="Play & Pause"
        >
          {!isPlaying ? (
            <BsPlay color="white" width={5} />
          ) : (
            <BsPause color="white" width={5} />
          )}
        </button>
        <video
          ref={videoRef}
          className={className}
          src={src}
          playsInline
        ></video>
      </div>
    </>
  );
}

export default AboutVideo;
