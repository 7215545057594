// Importing React Modules
import "scss/css/home/homeStyle.css";
import { motion } from "framer-motion";

// Importing Layout
import Footer from "components/layout/footer/Footer";

// Components Import
import HomePrograms from "pages/home/HomePrograms";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";

// Svg Import
import WavySvgUp from "assets/svg/wavy_up_lightGray.svg";
import WavySvgDown from "assets/svg/wavy_down_lightGray.svg";
import BloodyDownBlueSvg from "assets/vectors/bloody_vector_down_blue.svg";

// Section Import
import AboutSection from "components/sections/AboutSection";
import NavBarr from "components/layout/NavBarr";
import HeroSection from "components/sections/home/HeroSection";
import Methodology from "components/sections/home/MethodologySection";
import SuccessSection from "components/sections/successSection/SuccessSection";
import Testimonials from "components/sections/home/Testimonials";
import Partners from "components/sections/home/Partners";
import ContactNumber from "components/sections/home/ContactNumber";

export default function Home() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavBarr />
      <section className="home">
        <HeroSection />
      </section>

      <section className="home__about">
        <div className="home__about-container container">
          <AboutSection
            classNameAboutText={["about__text"]}
            classNameAboutBtn={["about__btn primary-btn"]}
            animate={false}
          />
        </div>
      </section>

      <div className="wavy-svg">
        <img loading="lazy" src={WavySvgUp} alt="Section Splitter" />
      </div>
      <section className="home__programs">
        <div className="home__programs-content container">
          <HomePrograms />
        </div>
      </section>
      <div className="wavy-svg">
        <img loading="lazy" src={WavySvgDown} alt="Section Splitter" />
      </div>

      <section className="home__methodology ">
        <Methodology />
      </section>

      <section className="home__success">
        <div className="about__success-container container">
          <div className="about__success-title">
            <h1>Sukseset tona</h1>
          </div>
          <div className="about__success-carousel">
            <SuccessSection />
          </div>
        </div>
      </section>

      <section className="home__testimonials">
        <Testimonials />
      </section>

      <section className="home__partners">
        <Partners />
      </section>

      {/* <div className="bloody-svg"></div>
      <section className="home__contact-number">
        <ContactNumber />
      </section> */}

      <BottomFixedButtons />

      <Footer variant={"white"} />
    </motion.div>
  );
}
