import Button from "components/buttons/Buttons";
import { useEffect } from "react";
import Popup from "reactjs-popup";
import { UilMultiply } from "@iconscout/react-unicons";

function RootModal(props) {
  const {
    open = true,
    closeOnDocumentClick = false,
    children,
    className,
    variant = "red",
    closeModal,
    action,
    buttonText,
  } = props;
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "";
    }
  }, [open]);

  return (
    <>
      <Popup
        open={open}
        position="right center"
        closeOnDocumentClick={closeOnDocumentClick}
        className={`root__modal root__modal ${className} ${variant}__modal`}
      >
        {children}
        <Button
          className={[`red-btn`]}
          ButtonTitle={buttonText}
          buttonAction={action}
          variant={variant == "red" ? "red" : "yellow"}
        />
        <button className="root__modal-close" onClick={closeModal}>
          <UilMultiply size={25} />
        </button>
      </Popup>
    </>
  );
}

export default RootModal;
