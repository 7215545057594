import { motion } from "framer-motion";
// Importing Layout
import NavBarr from "components/layout/NavBarr";

// Importing Assets
import ErrorGif from "../assets/images/errorPage/404.gif";

// Buttons
import Button from "components/buttons/Buttons";
import Socials from "components/buttons/Socials";

function ErrorPage() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavBarr />

      <div className="error__page">
        <h1>Page Not Found</h1>
        <div className="error__container">
          <div className="error__content">
            <div className="error__page-gif">
              <img src={ErrorGif} alt="Error Gif" />
            </div>

            <div className="error__info">
              <h1>Error 404</h1>
              <p>the page you are looking for was not found!</p>
              <Button
                className={["error_page-btn primary-btn"]}
                RedirectLink="/"
                ButtonTitle="Go Back"
                variant={"blue"}
                size={"md"}
              />
            </div>
          </div>
          <Socials />
        </div>
      </div>
    </motion.div>
  );
}

export default ErrorPage;
