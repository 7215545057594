import Navbar from "components/layout/Navbar";

function NavBarr(props) {
  const { NavLinksClassName, hide = false, contrast } = props;
  return (
    <>
      <Navbar
        DesktopNavLink1={hide ? "" : "Ballina"}
        DesktopNavLink2={hide ? "" : "Për ne"}
        DesktopNavLink3={hide ? "" : "Programet"}
        DesktopNavLink4={hide ? "" : "Projektet"}
        DesktopNavLink5={hide ? "" : "Kontakt"}
        MobileNavLink1={hide ? "" : "Ballina"}
        MobileNavLink2={hide ? "" : "Për ne"}
        MobileNavLink3={hide ? "" : "Programet"}
        MobileNavLink4={hide ? "" : "Projektet"}
        MobileNavLink5={hide ? "" : "Kontakt"}
        NavLinksClassName={NavLinksClassName}
        hide={hide}
        contrast={contrast}
      />
    </>
  );
}

export default NavBarr;
