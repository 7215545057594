// importing Assets
import python from "assets/images/planprograms/age15+/python.webp";
import ai from "assets/images/planprograms/age15+/ai.webp";
import data_science from "assets/images/planprograms/age15+/data_science.webp";
import final_project from "assets/images/planprograms/age15+/final_project.webp";
import ai2 from "assets/images/planprograms/age7_9/ai2.webp";
import labbox from "assets/images/planprograms/age7_9/labbox.webp";
import minecraft from "assets/images/planprograms/age7_9/minecraft.webp";
import science from "assets/images/planprograms/age7_9/science.png";

export const modules = [
  // Plan programet te thjeshta
  {
    id: 1,
    title: "HTML Fundamentals",
    description:
      "Moduli i parë në kuadër të ”Web Fundamentals” ka të bëj me njoftimin me dizajnin dhe strukturën e një Webfaqeje. Gjatë këtij moduli nxënësit fillimisht njoftohen me dizajnin e ndërfaqeve “responsive” të përshtatshme për pajisje të ndryshme përmes platformës Figma, më pastaj njoftohen me strukturën bazë të ndërtimit të një Webfaqe me HTML. Nxënësit mësojnë të identifikojnë HTML elementet nga faqet e ndryshme dhe të krijojnë në mënyrë të pavarur faqe me elementet që kanë mësuar. ",
    duration: 24,
    year: 1,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flogospng.org%2Fdownload%2Fhtml-5%2Flogo-html-5-1536.png&f=1&nofb=1&ipt=a5418697544db0c660a4bbc59d3027de35c2a3f4a2b031391037d5e9f720216f&ipo=images",
    type: [1],
  },
  {
    id: 2,
    title: "CSS Fundamentals",
    description:
      "Moduli “CSS Fundamentals” njofton nxënësit me dizajnimin e elementeve të webfaqeve përmes gjuhës stilizuese CSS. Gjatë këtij moduli nxënësit mësojnë implementimin e seksioneve të ndryshme si drop-down menytë, animacionet dhe tranzicionet, clip-paths e të tjera. Ata gjithashtu kuptojnë koncepte si selektorë të ndryshëm të elementeve, teknologjinë SASS krahas teknologjisë CSS. Gjatë këtij moduli nxënësit krijojnë dy projekte të pavarura të cilat mbështesin të gjitha konceptet e mësuara.",
    duration: 24,
    year: 1,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.icon-icons.com%2Ficons2%2F844%2FPNG%2F512%2FCSS3_icon-icons.com_67069.png&f=1&nofb=1&ipt=cbe44ef8b8d1f621fb165fd8c91bac31bd4a6af898b2e454603be7a040d6e858&ipo=images",
    type: [1],
  },
  {
    id: 3,
    title: "Tools and libraries",
    description:
      "jCoders është akademi e orientuar nga nevojat e tregut për punë, prandaj ne mundohemi të stimulojmë ambientin e punës në orët akademike. Për të mundësuar këtë nxënësit mësojnë të bashkëpunojnë me njëri-tjetrin përmes mjeteve si Trello për menaxhimin e punëve të përbashkëta, dhe GitHub për menaxhimin e kodit në ekipe. Në mënyrë që nxënësit të jenë sa më të përgatitur, moduli i fundit bazohet në projekte të vazhdueshme, ku ndër tjerash përfshihet edhe një projekt final individual i nxënësit i cili do të jetë pjesë e portfolios së tyre.",
    duration: 24,
    year: 1,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.pngarts.com%2Ffiles%2F8%2FBlack-Github-Logo-PNG-Image-Background.png&f=1&nofb=1&ipt=220b965bd6d0e9e370c4e5facd8cb4ccda21019b5ecc2caa87cb1f5d8fe141d8&ipo=images",
    type: [1],
  },
  {
    id: 4,
    title: "Javascript Fundamentals",
    description:
      "Pas përfundimit të vitit të parë ku nxënësit mësojnë HTML dhe CSS në mënyrë strukturore, ata kalojnë në vitin e dytë në ‘Programming with JavaScript’. JavaScript është një ndër pjesët më të rëndësishme kur bie fjala tek ndërtimi i Web-it. Në këtë rast si gjuhë programuese, JavaScript lejon nxënësit që të programojnë dhe të krijojnë efekte dinamike brenda Webfaqeve të tyre dhe të zhvillojnë komponente dinamike duke u bazuar në të dhëna reale. Njëkohësisht JavaScript iu hap rrugë drejt teknologjive më të reja (librarive dhe frameworks) Node.js dhe React.JS të cilat kanë për bazë JavaScript.",
    duration: 24,
    year: 2,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.easelsolutions.com%2Fimages%2Fcourses%2Flogos%2Fjavascript.png&f=1&nofb=1&ipt=04d50148d7b0b6be727b14196fd2f672f67cd3ca22004be8fca9d2c8c546409a&ipo=images",
    type: [1],
  },
  {
    id: 5,
    title: "Javascript Intermediate",
    description:
      "Moduli JavaScript Intermediate është i ndërtuar t’i mundësojë nxënësve të eksplorojnë veçoritë më të avancuara të gjuhës. Programi është i përshtatshëm për zhvilluesit që kërkojnë të gjurmojnë më thellë në gjuhë dhe t`i çojnë aftësitë e tyre të programimit në JavaScript në nivelin tjetër. Duke përdorur gjithmonë sintaksën dhe metodat më moderne të gjuhës, nxënësit aktivisht marrin pjesë në projekte të cilat veçojnë aftësitë e tyre.",
    duration: 24,
    year: 2,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.britefish.net%2Fwp-content%2Fuploads%2F2019%2F06%2Flogo-javascript-2.png&f=1&nofb=1&ipt=44fd1744731917af508b8667045be9890b2dc8ce933cd4396a3e9a81dd1c1465&ipo=images",
    type: [1],
  },
  {
    id: 6,
    title: "Javascript Advanced",
    description:
      "Qëllimi i këtij moduli është që nxënësit të mësojnë koncepte të reja që faqja të jetë sa më e gjallë dhe ndërvepruese, ku arrijnë të krijojnë faqe me dizajn kompleks. Gjatë këtij moduli nxënësit aplikojnë metodat që përmirësojnë shpejtësinë dhe performancën e aplikacionit të tyre. Gjatë modulit përfundimtar të vitit, nxënësit përgatiten edhe për projektin e tyre final, duke ndjekur procesin e plotë të zhvillimit të një Web-aplikacioni.",
    duration: 24,
    year: 2,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg: "https://cdn-icons-png.flaticon.com/512/9695/9695720.png",
    type: [1],
  },
  {
    id: 7,
    title: "Node.js essentials",
    description:
      "Mjedisi i fuqishëm i funksionimit Node.js është renditur si teknologjia më e përdorur nga zhvilluesit profesionistë. Node.js është një framework i cili përdoret në Back-end në atë mënyrë qe të na lejoj krijimin e aplikacioneve efikase. Moduli Node essentials njofton nxënësit me gjërat esenciale të programimit me Node duke filluar nga krijimi i një serveri bazik, instalimi i paketës Express.js, përdorim i EJS fajllave, komunikimi me server me anë të metodës POST. Si në çdo modul tjetër edhe Node.js essentials në përfundim ka një projekt praktik i cili mundëson implementimin e koncepteve të lartë-përmendura.",
    duration: 24,
    year: 3,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Flogos-download.com%2Fwp-content%2Fuploads%2F2016%2F09%2FNode_logo_NodeJS.png&f=1&nofb=1&ipt=f5d95abf3a213680077d840a6f8c8dc24eb60ac8480b438f65d37c783575300d&ipo=images",
    type: [1],
  },
  {
    id: 8,
    title: "Databases with Node.js",
    description:
      "Gjatë modulit nxënësit njoftohen me krijimin e një serveri lokal për testim të aplikacionit para se ai të hostohet. Nxënësit do të të njoftohen me disa karakteristika të bazave të të dhënave, duke eksploruar disa lloje të ndryshme të bazave dhe duke mësuar se si të krijojnë një komunikim mes aplikacionit në Node.js dhe Databazës në atë mënyrë që të bëhet menaxhimi efektiv i të dhënave në prapavijë.",
    duration: 16,
    year: 3,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fd1.awsstatic.com%2Fasset-repository%2Fproducts%2Famazon-rds%2F1024px-MySQL.ff87215b43fd7292af172e2a5d9b844217262571.png&f=1&nofb=1&ipt=1124c2b990bc4387c917b4da2d2d2ecc36d0a9cc53557da9642aafec979cecf7&ipo=images",
    type: [1],
  },
  {
    id: 9,
    title: "Database and Libraries",
    description:
      "Nxënësve si zhvillues të Node.js duke ndërtuar një aplikacion, do t`ju duhet të zgjidhni një bazë të dhënash dhe bibliotekë të bazës së të dhënave në një moment të procesit. Duke marë parasysh zgjedhjet e shumëta të bazës së të dhënave që janë në dispozicion, zgjedhja e një baze të dhënash është sfiduese. Nxënësit do mësojnë disa karakteristika të rëndësishme të bazës së të dhënave, do të eksplorojnë disa lloje të ndryshme bazash të dhënash dhe më në fund do të mësojmë se si të lidhim një bazë të dhënash me aplikacionin tuaj Node.js",
    duration: 24,
    year: 3,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Fclipart-library.com%2Fimage_gallery2%2FDatabase-Free-Download-PNG.png&f=1&nofb=1&ipt=c7ac03a53b392abbd17645af206bd7a2153ddb7e9cdd71f75bacd5e896c5ae82&ipo=images",
    type: [1],
  },
  {
    id: 10,
    title: "API & MVC",
    description:
      "Moduli i fundit i këtij viti bazohet në projekte praktike nga nxënësit, çdo nxënës do të zbatoj njohuritë e fituara në projekte reale duke krijuar aplikacione të mbështetura në arkitekturën MVC (Model-View-Controllorer). Gjatë zhvillimit të aplikacioneve nxënësve do t’i nevojitet të hulumtojnë, dizajnojnë dhe programojnë projektet në mënyrë individuale.",
    duration: 16,
    year: 3,
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ficon-library.com%2Fimages%2Ficon-api%2Ficon-api-9.jpg&f=1&nofb=1&ipt=e74b75d4d5f15402ee9eb3a92d8da209da1ca9f53607cd2b20073962a0ec7690&ipo=images",
    type: [1],
  },
  {
    id: 11,
    title: "Computer Basics & Typing",
    description:
      "Gjatë këtij moduli nxënësit do të fitojnë njohuri bazike mbi përdorimin e kompjuterit, si ruajtja dhe organizimi i files dhe folders, instalimi i programeve, regjistrimi i llogarive si dhe shkrimi efektiv në tastierë.",
    duration: 8,
    year: 1,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=http%3A%2F%2Ficons.iconarchive.com%2Ficons%2Fpaomedia%2Fsmall-n-flat%2F1024%2Fdevice-computer-icon.png&f=1&nofb=1&ipt=75962999690df12a13639c7421993c7bc8d32ad728024d936f51c63cd09546d1&ipo=images",
    type: [1],
  },
  {
    id: 12,
    title: "Visual Programming",
    description:
      "Programimi vizual ofron mundësinë për të koduar në mënyrë grafike përmes përdorimit të blloqeve me ikona. Nxënësit shohin lehtë lidhjen midis bllokut të kodit dhe rezultati që manifestohet në program. Ky modul u mundëson nxënësve të krijojnë lojëra kompjuterike dhe njëkohësisht të mësojnë logjikën elementare të programimit.",
    duration: 32,
    year: 1,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn0.iconfinder.com%2Fdata%2Ficons%2Fcomputer-70%2F64%2Fcoding-programmer-code-programming-language-512.png&f=1&nofb=1&ipt=aecfebc94c313ab23413402394ace88e84803f12e6d37b0c8d7b2cb14cb88ec5&ipo=images",
    type: [1],
  },
  {
    id: 13,
    title: "3D Modeling",
    description:
      "Gjatë modulit 3D modelim, nxënësit mësojnë të ndërtojnë objekte të ndryshme përmes manipulimit të formave themelore tri dimensionale. Nxënësve u kërkohet të gjejnë zgjidhje të reja për të ndërtuar atë që duan ose të modelojnë zgjidhje për ndonjë problem nga jeta reale. ",
    duration: 16,
    year: 1,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.squarespace-cdn.com%2Fcontent%2Fv1%2F5baff2a601232c7aee5036f3%2F1539393127143-6N8W9FI90GYM5019WLRK%2Fke17ZwdGBToddI8pDm48kGfiFqkITS6axXxhYYUCnlRZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpxQ1ibo-zdhORxWnJtmNCajDe36aQmu-4Z4SFOss0oowgxUaachD66r8Ra2gwuBSqM%2F3d%2Bmodeling%2Bicon.png&f=1&nofb=1&ipt=70dc88a70f48014a60aa893f78d47d1b5e42351a87fa6e1bf0db6a45aa5dca64&ipo=images",
    type: [1],
  },
  {
    id: 14,
    title: "Electronics",
    description:
      "Gjatë këtij moduli nxënësit përdorin komponente elektronike të krijuara enkas për ta, të cilat iu mundësojnë atyre të ndërtojnë dhe krijojnë projekte të ndryshme me komponente elektronike.  ",
    duration: 16,
    year: 1,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmembers-csforall.imgix.net%2Fmembers%2Flogos%2Flogo-labbox.png&f=1&nofb=1&ipt=b9afc2d843f449e7f72935297d7603779ad6a92a17bf801639d99451f6d2a515&ipo=images",
    type: [1],
  },
  {
    id: 15,
    title: "Research Skills & Internet Safety",
    description:
      "Për të përfituar sa më shumë nga interneti, nxënësit duhet të marrin vendime të zgjuara. Ky modul fuqizon nxënësit të përdorin web-in në mënyrë të sigurtë dhe të mençur, ashtu që nxënësit të jenë eksplorues të sigurtë të botës në internet",
    duration: 8,
    year: 2,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn2.iconfinder.com%2Fdata%2Ficons%2Fseo-internet-marketing-6%2F256%2FKeyword_Research-512.png&f=1&nofb=1&ipt=c65b107231620a774d23a33ce5773a3cafec1da76451514184520c620089df4c&ipo=images",
    type: [1],
  },
  {
    id: 16,
    title: "Block-based Programming",
    description:
      "Scratch është gjuha më e përdorur në botë për kodim për moshat e reja. Bazohet në blloqe me përmbajtje tekstuale dhe “drag-and-drop” metodën për të lehtësuar mësimin e logjikës së programimit pa fokus në sintaksa të komplikuara të kodit. Nxënësit mësojnë konceptet bazike mbi të cilat zhvillohet çdo program. Për të fituar rrjedhshmëri në të kuptuarit e logjikës së programimit, nxënësit krijojnë lojërat e tyre të preferuara.",
    duration: 24,
    year: 2,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.vippng.com%2Fpng%2Ffull%2F510-5106575_scratch-desktop-icon.png&f=1&nofb=1&ipt=609b3a492c93f21fba109c590731e6499eb41615a1e7ec458f21e46858600688&ipo=images",
    type: [1],
  },
  {
    id: 17,
    title: "Advanced block-based programming",
    description:
      "Ky modul iu shërben nxënësve që të kuptojnë koncepte të avancuara të programimit. Kjo iu mundëson atyre të programojnë veprime më komplekse, dhe të krijojnë loja kompjuterike më të sofistikuara. Gjatë këtij moduli nxënësit, gjithashtu mësojnë koncepte të matematikës të cilat lidhen me teknologji dhe logjikë të programimit. ",
    duration: 24,
    year: 2,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ficons.iconarchive.com%2Ficons%2Fgoescat%2Fmacaron%2F512%2Fcode-blocks-icon.png&f=1&nofb=1&ipt=f513802f2ef9c13221532bac23723bd6ea6e3a5e31bbff9601a525acee0d45d2&ipo=images",
    type: [1],
  },
  {
    id: 18,
    title: "Advanced Electronics",
    description:
      "Mënyra më e mirë për të ushqyer shpirtin krijues të moshave të reja është t i mësojmë se si të mendojnë për zgjidhjen e problemeve duke ideuar dhe krijuar projekte reale me komponente elektronike. Gjatë këtij moduli nxënësit fillojnë të kuptojnë fuqinë që teknologjia ka, nëse përdoret për të krijuar zgjidhje të problemeve. ",
    duration: 16,
    year: 2,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmembers-csforall.imgix.net%2Fmembers%2Flogos%2Flogo-labbox.png&f=1&nofb=1&ipt=b9afc2d843f449e7f72935297d7603779ad6a92a17bf801639d99451f6d2a515&ipo=images",
    type: [1],
  },
  {
    id: 19,
    title: "Mobile Apps 1",
    description:
      "Njerëzit me ide të shkëlqyera janë kudo. Çdokush duhet të jetë në gjendje të praktikojë krijimin dixhital pa pasur nevojë për njohuri të thella teknike. Qëllimi i modulit është që të shëndrroj të gjithë nxënësit në krijues aktiv të teknologjisë. Në modulin ‘Mobile Apps’ do të përdorim Thunkable, një mjedis programimi i cili përdoret për të krijuar aplikacione mobile, ku mësohet për konceptet e programimit  si variablat, funksionet dhe kushtet. ",
    duration: 16,
    year: 3,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fplay-lh.googleusercontent.com%2FWgtKRr4z1afw87qy0AXDOTmdQvAKDxqHqM4AFmx7I0GEKgyrMX7wRl8kVM-rErRiDeUC&f=1&nofb=1&ipt=9d46de25454bfbdd98527be84dd35228248ad49223e3f1a054b20a4a1d843b97&ipo=images",
    type: [1],
  },
  {
    id: 20,
    title: "Mobile Apps 2",
    description:
      "Aplikacionet e telefonave mobil tani më janë pjesë e përditshmërisë. Ekzistojnë rregulla të qarta se si duhet të dizajnohen këto aplikacione në mënyrë që të jenë të përshtatshme për përdoruesit. Gjatë ketij moduli nxënësit përveq që thellohen në koncepte të programimit për platforma mobile, ata mësojnë edhe rregullat e dizajnit dhe organizimit të pamjes së aplikacioneve.",
    duration: 24,
    year: 3,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.icon-icons.com%2Ficons2%2F2699%2FPNG%2F512%2Ffigma_logo_icon_170157.png&f=1&nofb=1&ipt=6171dc465da7cac5723fc0d91baac13191af029ca757d6f4335aefefa5ec4e31&ipo=images",
    type: [1],
  },
  {
    id: 21,
    title: "Graphic Design",
    description:
      "Moduli fokusohet në zhvillimin e mendjes kreative te nxënësit. Idetë e tyre prezantohen në formë teksti e imazhi. Qëllimi është të zhvillohet më tutje përqendrimi tek ta, duke i sfiduar në precizitet të lartë, dhe mënyrën se si i çasen kërkesave të projektit nga aspekti i pamjes. Nxënësit mësojnë se si dizajni aplikohet në jetën e përditshme, duke filluar nga logot, postcard, social media posts, dhe hapat e përgjithshëm për dizajne të aplikacioneve. ",
    duration: 16,
    year: 3,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvectorified.com%2Fimages%2Fgraphic-design-icon-png-34.png&f=1&nofb=1&ipt=b09917f0e9c937aa62f7788d58cda1940f5cb3529938a5dcaa6b6f5a099eb60d&ipo=images",
    type: [1],
  },
  {
    id: 22,
    title: "Robotics",
    description:
      "Duke kombinuar sensorë të ndryshëm me aktuatorë dhe pllaka të mençura nxënësit mësohen të ndërtojnë projekte dhe sisteme që hasin në botën reale. Ata mësohen të shkruajnë kod, për të komanduar pajisjet fizike.",
    duration: 16,
    year: 3,
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn0.iconfinder.com%2Fdata%2Ficons%2Fspace-icons%2F110%2FRobot-1024.png&f=1&nofb=1&ipt=485495c72a748cd6c1499c9cc4ce56d5d6413ce6bf8cc92573a8e51c391f13df&ipo=images",
    type: [1],
  },

  //** Copyed items DUPLICATE TODO: These are duplicated items, from plan program 7-10 */
  {
    id: 23,
    title: "Research Skills & Internet Safety",
    description:
      "Për të përfituar sa më shumë nga interneti, nxënësit duhet të marrin vendime të zgjuara. Ky modul fuqizon nxënësit të përdorin web-in në mënyrë të sigurtë dhe të mençur, ashtu që nxënësit të jenë eksplorues të sigurtë të botës në internet",
    duration: 8,
    year: 1,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn2.iconfinder.com%2Fdata%2Ficons%2Fseo-internet-marketing-6%2F256%2FKeyword_Research-512.png&f=1&nofb=1&ipt=c65b107231620a774d23a33ce5773a3cafec1da76451514184520c620089df4c&ipo=images",
    type: [1],
  },
  {
    id: 24,
    title: "Block-based Programming",
    description:
      "Scratch është gjuha më e përdorur në botë për kodim për moshat e reja. Bazohet në blloqe me përmbajtje tekstuale dhe “drag-and-drop” metodën për të lehtësuar mësimin e logjikës së programimit pa fokus në sintaksa të komplikuara të kodit. Nxënësit mësojnë konceptet bazike mbi të cilat zhvillohet çdo program. Për të fituar rrjedhshmëri në të kuptuarit e logjikës së programimit, nxënësit krijojnë lojërat e tyre të preferuara.",
    duration: 24,
    year: 1,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwww.vippng.com%2Fpng%2Ffull%2F510-5106575_scratch-desktop-icon.png&f=1&nofb=1&ipt=609b3a492c93f21fba109c590731e6499eb41615a1e7ec458f21e46858600688&ipo=images",
    type: [1],
  },
  {
    id: 25,
    title: "Advanced block-based programming",
    description:
      "Ky modul iu shërben nxënësve që të kuptojnë koncepte të avancuara të programimit. Kjo iu mundëson atyre të programojnë veprime më komplekse, dhe të krijojnë loja kompjuterike më të sofistikuara. Gjatë këtij moduli nxënësit, gjithashtu mësojnë koncepte të matematikës të cilat lidhen me teknologji dhe logjikë të programimit. ",
    duration: 24,
    year: 1,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ficons.iconarchive.com%2Ficons%2Fgoescat%2Fmacaron%2F512%2Fcode-blocks-icon.png&f=1&nofb=1&ipt=f513802f2ef9c13221532bac23723bd6ea6e3a5e31bbff9601a525acee0d45d2&ipo=images",
    type: [1],
  },
  {
    id: 26,
    title: "Advanced Electronics",
    description:
      "Mënyra më e mirë për të ushqyer shpirtin krijues të moshave të reja është t i mësojmë se si të mendojnë për zgjidhjen e problemeve duke ideuar dhe krijuar projekte reale me komponente elektronike. Gjatë këtij moduli nxënësit fillojnë të kuptojnë fuqinë që teknologjia ka, nëse përdoret për të krijuar zgjidhje të problemeve. ",
    duration: 16,
    year: 1,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fmembers-csforall.imgix.net%2Fmembers%2Flogos%2Flogo-labbox.png&f=1&nofb=1&ipt=b9afc2d843f449e7f72935297d7603779ad6a92a17bf801639d99451f6d2a515&ipo=images",
    type: [1],
  },
  {
    id: 27,
    title: "Mobile Apps 1",
    description:
      "Njerëzit me ide të shkëlqyera janë kudo. Çdokush duhet të jetë në gjendje të praktikojë krijimin dixhital pa pasur nevojë për njohuri të thella teknike. Qëllimi i modulit është që të shëndrroj të gjithë nxënësit në krijues aktiv të teknologjisë. Në modulin ‘Mobile Apps’ do të përdorim Thunkable, një mjedis programimi i cili përdoret për të krijuar aplikacione mobile, ku mësohet për konceptet e programimit  si variablat, funksionet dhe kushtet. ",
    duration: 16,
    year: 2,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fplay-lh.googleusercontent.com%2FWgtKRr4z1afw87qy0AXDOTmdQvAKDxqHqM4AFmx7I0GEKgyrMX7wRl8kVM-rErRiDeUC&f=1&nofb=1&ipt=9d46de25454bfbdd98527be84dd35228248ad49223e3f1a054b20a4a1d843b97&ipo=images",
    type: [1],
  },
  {
    id: 28,
    title: "Mobile Apps 2",
    description:
      "Aplikacionet e telefonave mobil tani më janë pjesë e përditshmërisë. Ekzistojnë rregulla të qarta se si duhet të dizajnohen këto aplikacione në mënyrë që të jenë të përshtatshme për përdoruesit. Gjatë ketij moduli nxënësit përveq që thellohen në koncepte të programimit për platforma mobile, ata mësojnë edhe rregullat e dizajnit dhe organizimit të pamjes së aplikacioneve.",
    duration: 24,
    year: 2,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn.icon-icons.com%2Ficons2%2F2699%2FPNG%2F512%2Ffigma_logo_icon_170157.png&f=1&nofb=1&ipt=6171dc465da7cac5723fc0d91baac13191af029ca757d6f4335aefefa5ec4e31&ipo=images",
    type: [1],
  },
  {
    id: 29,
    title: "Graphic Design",
    description:
      "Moduli fokusohet në zhvillimin e mendjes kreative te nxënësit. Idetë e tyre prezantohen në formë teksti e imazhi. Qëllimi është të zhvillohet më tutje përqendrimi tek ta, duke i sfiduar në precizitet të lartë, dhe mënyrën se si i çasen kërkesave të projektit nga aspekti i pamjes. Nxënësit mësojnë se si dizajni aplikohet në jetën e përditshme, duke filluar nga logot, postcard, social media posts, dhe hapat e përgjithshëm për dizajne të aplikacioneve. ",
    duration: 16,
    year: 2,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fvectorified.com%2Fimages%2Fgraphic-design-icon-png-34.png&f=1&nofb=1&ipt=b09917f0e9c937aa62f7788d58cda1940f5cb3529938a5dcaa6b6f5a099eb60d&ipo=images",
    type: [1],
  },
  {
    id: 30,
    title: "Robotics",
    description:
      "Duke kombinuar sensorë të ndryshëm me aktuatorë dhe pllaka të mençura nxënësit mësohen të ndërtojnë projekte dhe sisteme që hasin në botën reale. Ata mësohen të shkruajnë kod, për të komanduar pajisjet fizike.",
    duration: 16,
    year: 2,
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    bgImg:
      "https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcdn0.iconfinder.com%2Fdata%2Ficons%2Fspace-icons%2F110%2FRobot-1024.png&f=1&nofb=1&ipt=485495c72a748cd6c1499c9cc4ce56d5d6413ce6bf8cc92573a8e51c391f13df&ipo=images",
    type: [1],
  },
  //** END */

  // Copy item
  // {
  //   id: 00,
  //   title: "",
  //   description: "",
  //   duration: 16,
  //   year: 3,
  //   planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
  //   bgImg: "",
  //   type: [1],
  // },

  // Plan programet Verore
  {
    id: 11,
    title: "Java e Minecraftit dhe 3D Modelimit",
    description:
      "Këtë verë për moshat 7-9 kemi zgjedhur dy teknologji shumë praktike dhe interesante Minecraft & 3D Modelim me TinkerCad. Si prind, pa dyshim që keni dëgjuar për Minecraft. Minecraft është një lojë kompjuterike unike, por a e dini se gjithashtu është një mjet i shkëlqyer për të mësuar? Nga aftësitë akademike tek ato sociale, Minecraft mund t’i ndihmojë fëmijët të arrijnë potencialin e tyre të plotë. Sipas një raportit të BBC News të vitit 2016, më shumë se 7,000 klasa në mbarë botën përdorin Minecraft në klasa të shkollave publike dhe private, dhe ky trend vetëm se është rritur. Përfitimet e të mësuarit Minecraft janë po aq të pafundme sa vetë bota e kësaj platforme. Nga dizajni i lojës, kodimi dhe zhvillimi i kreativitetit. Të mësosh modelimin 3D për fëmijët është si të hysh në një botë imagjinate dhe kreativiteti. Nëpërmjet Tinkercad fëmijët mund të mësojnë se si të dizajnojnë dhe ndërtojnë objektet e tyre 3D, personazhet dhe madje edhe botë të tëra. Ata mund të fillojnë duke mësuar bazat, të tilla si të kuptuarit e formave, ngjyrave dhe përmasave.",
    duration: 1,
    year: [1],
    planprogram_id: "f96130c0-e709-4f27-a181-25721624d5d3",
    bgImg: minecraft,
    type: [2],
  },
  {
    id: 12,
    title: "Java e Inteligjencës Artificiale",
    description:
      "Java e Inteligjencës Artificiale përfshinë një kombinim të teknologjive AI me të cilat nxënësit do të kuptojnë se si inteligjenca artificiale mund të përdoret për të krijuar storje të ndryshme, imazhe, tekset. Gjatë kësaj jave ata do të krijojnë librin e tyre të ilustruar duke përdorur këto teknologji. Aplikimi i inteligjencës artificiale në fusha si mjekësia, inxhinieria, financat, dhe shumë të tjera, ka ndihmuar në përmirësimin e proceseve dhe krijimin e zgjidhjeve më të avancuara. Duke u njohur me inteligjencën artificiale qysh në shkollë, nxënësit zhvillojnë njohuri dhe aftësi të nevojshme për t'u përshtatur në një botë ku teknologjia ka një rol gjithnjë e më të rëndësishëm.",
    duration: 1,
    year: [1],
    planprogram_id: "f96130c0-e709-4f27-a181-25721624d5d3",
    bgImg: ai2,
    type: [2],
  },
  {
    id: 12,
    title: "Java e Inxhinierisë me Labbox",
    description:
      "Inxhinieria përdoret për zgjidhjen praktike të sfidave teknike në fusha të ndryshme të jetës. Kështu, gjatë kësaj jave, nxënësit do të krijojnë projekte inxhinierike në Art, Arkitekturë, Astronomi dhe në ditën e fundit ata do të shpikin një projekt me komponentë elektronikë të mësuar në ditët e mëparshme, duke përdorur imagjinatën e tyre.",
    duration: 1,
    year: [1],
    planprogram_id: "f96130c0-e709-4f27-a181-25721624d5d3",
    bgImg: labbox,
    type: [2],
  },
  {
    id: 12,
    title: "Java e Shkencës",
    description:
      "Gjatë javës së shkencës, nxënësit do të mësojnë koncepte si graviteti, molekulat, reaksionet kimike dhe presioni i ajrit. Ata do të marrin rolin e shkencëtarit dhe në bashkëpunim me trajnerin  do të kuptojnë metodën shkencore për të nxjerrë rezultate të bazuara në eksperimente. Realizimi i shumë eksperimenteve të përshtashme për moshën e tyre do t'u lejojë nxënësve të zhvillojnë njohuri të thella mbi konceptet shkencore dhe të kuptojnë se si shkencëtarët veprojnë dhe vënë në provë idetë dhe hipotezat e tyre.",
    duration: 1,
    year: [1],
    planprogram_id: "f96130c0-e709-4f27-a181-25721624d5d3",
    bgImg: science,
    type: [2],
  },
  {
    id: 13,
    title: "Ndërmarrësia dhe Edukimi Financiar",
    description:
      "Në javën e parë, nxënësit do të mësojnë parimet themelore të ndërmarrësisë dhe planifikimit financiar të përshtatura për moshën e tyre. Këtë javë nxënësit do të: Kalojnë nëpër Design Thinking për të identifikuar busines idetë potenciale në bazë të interesave të tyre. Do të njoftohen me Business Model Canvas, ku do të mësohen të mendojnë për biznese të vogla. Ky dokument pastaj do të paraqet planin e punës për javët në vazhdim. Ata do të mësojnë si të krijojnë një buxhet, të llogarisin shpenzimet dhe të përcaktojnë çmimet e produkteve të tyre.",
    duration: 1,
    year: [1],
    planprogram_id: "3db0cec4-23e6-4ab1-9270-2c8076368dee",
    bgImg: "https://cdn-icons-png.flaticon.com/512/2646/2646249.png",
    type: [2],
  },
  {
    id: 14,
    title: "Zhvillimi i Produktit dhe Përmirësimi i tij",
    description:
      "Gjatë javës së dytë, nxënësit do të fokusohen në zhvillimin e produktit. Në grup do të zhvillojnë prototipet e produkteve të tyre. Prototipet do t'i testojnë dhe në bazë të feedbackut do të inicojnë një cikël përmirësimi të produktit. Produkti i zhvilluar do të targetohet për audiencen e përzgjedhur në Business Model Canvas. Qëllimi kryesor për nxënësit gjatë kësaj jave është zhvillimi i një produkti të vlefshëm për klientet e tyre potencial.",
    duration: 1,
    year: [1],
    planprogram_id: "3db0cec4-23e6-4ab1-9270-2c8076368dee",
    bgImg: "https://cdn-icons-png.flaticon.com/512/10434/10434221.png",
    type: [2],
  },
  {
    id: 15,
    title: "Inteligjenca Artificiale dhe Marketingu",
    description:
      "Nxënësit do të eksplorojnë botën e marketingut dhe brendimit. Kjo javë do të ketë theks në mësimin e përdorimit të veglave të ndryshme të Inteligjencës Artificiale. Inteligjenca Artificiale do të përdorët për: Gjenerimin e teksteve të ndryshme, Gjenerimin e logove dhe materialit të dizajnit grafik, Gjenerimin e videove/editimit të fotove. Veglat e AI qe do të përdoren: ChatGPT, MidJourney, runwayML, etj. Materialet promovuese pastaj do t’i adaptojnë në vegla të dizajnit siç jaë Canva dhe Adobe Ilustartor.",
    duration: 1,
    year: [1],
    planprogram_id: "3db0cec4-23e6-4ab1-9270-2c8076368dee",
    bgImg: "https://cdn-icons-png.flaticon.com/512/1129/1129264.png",
    type: [2],
  },
  {
    id: 16,
    title: "Lansimi i Biznesit dhe Panairi",
    description:
      "Nxënësit do të finalizojnë planin e tyre të marketingut dhe të përfundojnë të gjitha aktivititet për lansim të biznesit të tyre. Kjo javë do të përfundojë me organizimin e panairit të bizneseve të vogla, ku secili fëmijë do të përjetoj shitjet e para të produkteve të tyre. Ata gjithashtu do të analizojnë të dhënat e shitjeve dhe do të raportojnë fitimin.",
    duration: 1,
    year: [1],
    planprogram_id: "3db0cec4-23e6-4ab1-9270-2c8076368dee",
    bgImg: "https://cdn-icons-png.flaticon.com/512/951/951857.png",
    type: [2],
  },
  {
    id: 17,
    title: "Bazat e python",
    description:
      "Python është një nga gjuhët programuese më të përdorura dhe të preferuara në botë. Për shkak që është e përshtatshme për fillestarët dhe ka zbatim te gjerë për zgjidhjen e problemeve në fusha të ndryshme, ne e kemi zgjedhur këtë gjuhë programuese për kampin e verës për moshat 15+. Gjatë javës së parë nxënësit do të mësojnë konceptet themelore të programimit me Python, duke zgjidhur detyra praktike të kodimit dhe duke zhvilluar projekte të vogla. Në fund të kësaj jave nxënësit do të fitojnë njohuri të sintaksës, koncepteve bazë dhe praktikave më të mira të Pythonit, përmes të cilave do të mund të ndërtojnë logjikën e një programi apo aplikacioni.",
    duration: 1,
    year: [1],
    planprogram_id: "d248f9d0-e35c-490f-91d3-fc6634a89b67",
    bgImg: python,
    type: [2],
  },
  {
    id: 18,
    title: "Data Science",
    description:
      "Pasi që në javën e parë nxënësit marrin një bazë të mirë në programim me python, ata do të vazhdojnë dhe të thellohen në të kuptuarit e “Data Science”. Nxënësit do të mësojnë se çka është Data Science dhe si ajo po transformon industri të ndryshme. Vizualizimi i të dhënave me anë të data science ka ndryshuar fundamentalisht mënyrën se si merren vendime në shumë fusha të rëndësishme siç janë mjekësia, tregtia, energjetika etj. Gjatë kësaj jave nxënësit do të eksplorojnë më thellë punën me të dhëna dhe manipulimin e tyre, do të bëjnë sortimi, filtrimi dhe vizualizimi e të dhënave. ",
    duration: 1,
    year: [1],
    planprogram_id: "d248f9d0-e35c-490f-91d3-fc6634a89b67",
    bgImg: data_science,
    type: [2],
  },
  {
    id: 19,
    title: "Inteligjenca Artificiale",
    description:
      "Nxënësit do të mësojnë se çka është inteligjenca artificiale dhe machine learning ku përfshihen koncepte si supervised learning, unsupervised learning, reinforcement learning si dhe aplikimet e inteligjencës artificiale në botën reale. Data science dhe inteligjenca artificiale janë disiplina të ndërthurura ngushtë që punojnë së bashku për të bërë parashikime dhe për të automatizuar procese. Gjatë kësaj jave do të mësojnë se si të bëjnë parashikime sa më të sakta duke trajnuar një model nga të dhënat e përzgjedhura dhe duke përdorur algoritmet e inteligjencës artificiale. Kjo javë është krijuar si për fillestarët ashtu edhe për entuziastët e inteligjencës artificiale dhe ofron një bazë solide në kuptimin dhe zbatimin e algoritmeve të inteligjencës artificiale.",
    duration: 1,
    year: [1],
    planprogram_id: "d248f9d0-e35c-490f-91d3-fc6634a89b67",
    bgImg: ai,
    type: [2],
  },
  {
    id: 20,
    title: "Projekti final",
    description:
      "Gjatë kësaj jave do të simulohet një ambient i punës, ku nxënësit do të pranojnë kërkesa të kjarta nga “klienti” për të zgjidhur ndonjë problem përmes teknologjive që kanë mësuar. Ata do të punojnë në grupe dhe do të zhvillojnë projektin nga ideimi e deri te produkti final, pra nxënësit do të krijojnë një software që e kryen një punë konkrete. Trajneri në këtë rast do të kthehet në rolin e udhëheqësit të ekipit dhe do t’iu ndihmoj nxënësve në organizim, në hulumtim për konceptet të reja dhe në problem-zgjidhje. Në ditën e fundit të kësaj jave do të organizohet maratona e kodimit - Hackathon. Në këtë ditë nxënësit do të sfidohen në finalizimin e projektit të tyre real të cilin do e bëjnë pjesë të portfolios së tyre.",
    duration: 1,
    year: [1],
    planprogram_id: "d248f9d0-e35c-490f-91d3-fc6634a89b67",
    bgImg: final_project,
    type: [2],
  },

  // {
  //   id: 0,
  //   title: "CopyItem",
  //   description: "",
  //   duration: 1,
  //   year: [1],
  //   planprogram_id: "",
  //   bgImg: "",
  //   type: ["parregullt"],
  // },
];
