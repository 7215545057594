import { Link } from "react-router-dom";
import Socials from "components/buttons/Socials";
import FooterInfo from "./FooterInfo";

const svgVariant = {
  blue: "blue",
  purple: "purple",
  lightGray: "secondWhite2",
  gray: "secondWhite",
  white: "white",
  yellow: "yellow",
  red: "red",
  hidden: "hidden",
};

function Footer(props) {
  const { style, className, animation = true, variant = "yellow" } = props;
  const svgColor = svgVariant[variant];

  return (
    <footer
      style={style}
      // ${animation ? "footer__animation" : ""}
      className={`footer 
      ${className ? className : ""}`}
    >
      <div className={[`footer__vector-up footer__vector-${svgColor}`]}></div>
      <div className="footer__wrapper">
        <div className="footer__title">
          <h1>
            Faleminderit <span>Për</span> Vizitën
          </h1>
        </div>
        <div className="footer__places">
          <FooterInfo />
        </div>

        <div className="footer__info">
          <div className="working__days working__info">
            <h3>Ditët e punës</h3>
            <p style={{ display: "flex", flexDirection: "column" }}>
              E Hëne - E Enjte <span>09:00 - 20:00</span>E Premte{" "}
              <span>09:00 - 17:30</span>
            </p>
          </div>
          <div className="footer__info-wrapper">
            <div className="footer__nav">
              <ul>
                <li>
                  <Link to="/programs" aria-label="Planprograms">
                    Programet
                  </Link>
                </li>
                <li>
                  <Link to="/register" aria-label="Register">
                    Regjistrohuni
                  </Link>
                </li>
                <li>
                  <Link to="/contact" aria-label="Contact">
                    Kontakt
                  </Link>
                </li>
              </ul>
            </div>
            <Socials />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
