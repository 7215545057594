import PlanProgramTable from "./PlanProgramTable";

const colors = {
  blue: "#40b1e5",
  red: "#f15b5b",
  purple: "#9f64a8",
  yellow: "#ffb000",
};

function PlanProgramInformation(props) {
  const { theme } = props;
  const color = colors[theme];
  return (
    <>
      <div className="plan__program-info-titles">
        <h1 style={{ color: color }}>Informata tjera</h1>
        <h3>Çmimi i kampit veror është 160€ për nxënës.</h3>
      </div>
      <PlanProgramTable />
      <div className="plan__program-info-desc">
        <div>
          <p>
            *Zbritja vlenë për nxënësit të cilët janë familjar, apo janë të
            referuar nga ju
          </p>
          <span>
            Çmimi i kampit veror për nxënësit aktiv është 136€ për nxënës në çdo
            kohë.
          </span>
        </div>
        <div>
          <h1>OPSIONET PER VIJIMIN NË KAMPIN VEROR</h1>
          <span className="plan__program-info-options">
            <h3 style={{ backgroundColor: color }}>KORRIK: 03 - 28 Korrik</h3>
            <h3>GUSHT: 31 Korrik - 25 Gusht</h3>
          </span>
        </div>
      </div>
    </>
  );
}

export default PlanProgramInformation;
