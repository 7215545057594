const info = [
  {
    id: 1,
    place: "Prishtina (Bregu i Diellit)",
    workingTime: "Hëne - Premte 9:00 - 20:00",
    phoneNumber: "(+383) 049 600 567",
    phoneNumber2: "(+383) 049 600 567",
    email: "info@j-coders.com",
    street: "Rifat Burdzeviç, Prishtina",
  },
  {
    id: 2,
    place: "Prishtina (Arbëri)",
    workingTime: "Hëne - Premte 9:00 - 20:00",
    phoneNumber: "(+383) 049 600 567",
    phoneNumber2: "(+383) 049 600 567",
    email: "info@j-coders.com",
    street: "Ahmet Krasniqi Arbëri, Prishtina, 10000 ",
  },
  {
    id: 3,
    place: "Mitrovica",
    workingTime: "Hëne - Premte 9:00 - 20:00",
    phoneNumber: "(+383) 049 600 653",
    phoneNumber2: "(+383) 049 600 653",
    email: "info@j-coders.com",
    street: "Shemsi Ahmeti, Mitrovicë, 40000",
  },
  {
    id: 4,
    place: "Gjilani",
    workingTime: "Hëne - Premte 9:00 - 20:00",
    phoneNumber: "(+383) 049 600 379",
    phoneNumber2: "(+383) 049 600 379",
    email: "info@j-coders.com",
    street: "Sadullah Brestovci, Gjilan, 60000",
  },
  {
    id: 5,
    place: "Peja",
    workingTime: "Hëne - Premte 9:00 - 20:00",
    phoneNumber: "(+383) 049 600 373",
    phoneNumber2: "(+383) 049 600 373",
    email: "info@j-coders.com",
    street: "Elena Gjika, Peje, 30000",
  },
];

function FooterInfo() {
  return (
    <>
      {info.map((i, index) => {
        return (
          <div key={index} className="footer__place-card">
            <h2>{i.place}</h2>
            {/* <div className="working__days working__info">
                  <h3>Ditët e punës</h3>
                  <p>{i.workingTime} </p>
                </div> */}
            <div className="working__numbers working__info">
              <h3>Numri</h3>
              <p>{i.phoneNumber}</p>
              {/* <p>{i.phoneNumber2}</p> */}
            </div>
            <div className="working__email working__info">
              <h3>Email</h3>
              <p>{i.email} </p>
            </div>
            <div className="working__location working__info">
              <h3>Rruga</h3>
              <p>{i.street}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default FooterInfo;
