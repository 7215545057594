function RootInput(props) {
  const {
    name,
    type,
    value,
    onChange,
    required = true,
    placeholder,
    className,
    id,
    disabled,
    pattern,
    ariaLabel,
  } = props;
  return (
    <>
      <input
        name={name}
        type={type}
        value={value}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
        id={id}
        disabled={disabled}
        pattern={pattern}
        aria-label={ariaLabel}
      />
    </>
  );
}

export default RootInput;
