import NavBarr from "components/layout/NavBarr";
import "scss/css/article/articleStyle.css";
import ArticleHeader from "pages/article/sections/ArticleHeader";
import { useEffect, useRef, useState } from "react";
import ArticleAbout from "pages/article/sections/ArticleAbout";
import ArticleSeparator from "pages/article/sections/ArticleSeparator";
import Footer from "components/layout/footer/Footer";
import ArticleIntegration from "pages/article/sections/ArticleIntegration";
import ArticleUse from "pages/article/sections/ArticleUse";
import ArticleHelp from "pages/article/sections/ArticleHelp";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";

// Importing Assets
import ArticleIntegrationSvg from "assets/svg/articleIntegration.svg";
import ArticleHelpImg from "assets/svg/articleHelp.svg";
import ArticleHelpImg2 from "assets/svg/articleHelpDown.svg";
import ArticleRegister from "./sections/ArticleRegister";

function Article() {
  const aboutRef = useRef(null);
  const [isMobile, setIsMobile] = useState(false);

  const scrollToSection = () => {
    const section = aboutRef.current;
    const topOffset = section.getBoundingClientRect().top;
    const scrollPosition = topOffset + window.pageYOffset - 80;

    window.scrollTo({ top: scrollPosition, behavior: "smooth" });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <NavBarr contrast={false} />

      <section className="article__header">
        <ArticleHeader
          title={"Inteligjenca Artificiale dhe rëndësia e saj"}
          description={
            "Duke u bërë gati për kampet verore, jemi shumë të lumtur që po bëjmë integrimin e teknologjive të fundit si kjo e Inteligjencës Artificiale në programin tonë veror për herë të parë."
          }
          action={scrollToSection}
        />
      </section>

      <section className="article__about" ref={aboutRef}>
        <ArticleAbout
          title={"Inteligjenca Artificiale"}
          desc={
            "Mirëpo, cka është Inteligjenca artificiale, si dhe pse është e rëndesishme të mësohet, e kemi trajtuar si temë në këtë artikul. Cka është inteligjenca artificiale? Inteligjenca artificale është teknologji e cila ka aftësi të mësoje vet. Kjo teknologji trajnohet me sasi të mëdha të dhënave, dhe kështu arrin të analizojë, identifikojë paterna dhe struktura dhe të vijë në përfundim në bazë të dhënave mbi të cilat është trajnuar. Së fundi inteligjenca artificiale është trajnuar mbi sasi shumë të mëdha të texteve të shkruara nga interneti, dhe kështu ka arritë të kuptojë gjuhën e folur të njeriut, gramatikën dhe kuptimin e fjalive. Si e tillë tani ajo di të përgjigjet në gjuhën e njeriut dhe kështu është bërë i mundshëm përdorimi i inteligjences artificale nga secili prej nesh."
          }
        />
      </section>

      <section className="article__separator">
        <ArticleSeparator
          desc={
            "Duke e pare fuqinë e veglave të AI, ne besojmë që përdorimi i tyre është shkathësi e domosdoshme dhe e menjëhershme, pasi që potenciali i këtyre veglave është i pakrahasueshëm me cdo teknologji tjetër deri më tani."
          }
        />
      </section>

      <section className="article__integration">
        <ArticleIntegration />
      </section>
      <div className="article__integration-vector">
        <img
          loading="lazy"
          src={ArticleIntegrationSvg}
          alt="Section Splitter"
        />
      </div>

      <section className="article__use">
        <ArticleUse />
      </section>

      <div
        className="article__help-vector"
        style={{
          height: isMobile ? "100px" : "",
        }}
      >
        <img loading="lazy" src={ArticleHelpImg} alt="Article Image" />
      </div>
      <section className="article__help">
        <ArticleHelp />
      </section>
      <div
        className="article__help-vector"
        style={{
          height: isMobile ? "100px" : "175px",
          alignItems: "flex-start",
          transform: "none",
        }}
      >
        <img loading="lazy" src={ArticleHelpImg2} alt="Article image" />
      </div>

      <section className="article__register">
        <ArticleRegister />
      </section>

      <BottomFixedButtons />

      <Footer variant={"white"} />
    </>
  );
}

export default Article;
