import { useState, useRef } from "react";
import { cities, planPrograms, preferences } from "helpers/Helpers";
import { ErrorModal, SuccessModal } from "components/modals/EmailModal";
import ReCAPTCHA from "react-google-recaptcha";
import RegisterInput from "components/inputs/RegisterInput";
import SelectMenu from "components/inputs/SelectMenu";
import TextArea from "components/inputs/TextArea";
import DateInput from "components/inputs/DateInput";
import MultipleOptions from "components/dropdown/MultipleOptions";

const options = [
  { id: 1, name: "E Hënë / E Merkurë 12:00 - 13:00" },
  { id: 2, name: "E Hënë / E Merkurë 13:00 - 14:00" },
  { id: 3, name: "E Hënë / E Merkurë 16:00 - 17:00" },
  { id: 4, name: "E Hënë / E Merkurë 17:00 - 18:00" },
  { id: 5, name: "E Hënë / E Merkurë 18:00 - 19:00" },
  { id: 6, name: "E Hënë / E Merkurë 19:00 - 20:00" },
  { id: 7, name: "E Martë / E Enjte 12:00 - 13:00" },
  { id: 8, name: "E Martë / E Enjte 13:00 - 14:00" },
  { id: 9, name: "E Martë / E Enjte 16:00 - 17:00" },
  { id: 10, name: "E Martë / E Enjte 17:00 - 18:00" },
  { id: 11, name: "E Martë / E Enjte 18:00 - 19:00" },
  { id: 12, name: "E Martë / E Enjte 19:00 - 20:00" },
];

function Register() {
  const recaptchaRef = useRef(null);
  const [formState, setFormState] = useState({});
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [modalVariant, setModalVariant] = useState(1);
  const [resetOptions, setResetOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [selectedTime, setSelectedTime] = useState([]);

  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.status == 200) {
      setFormState({});
      setPhoneNumber();
      setResetOptions(true);
      setOpenModal(true);
      setModalVariant(1);
      setSelectedTime([]);
      // if (recaptchaRef.current) {
      //   recaptchaRef.current.reset();
      // }
    } else {
      setOpenModal(true);
      setModalVariant(2);
    }

    return response.json();
  }
  async function handleSubmit(event) {
    event.preventDefault();
    if (captchaVerified == false) {
      setOpenModal(true);
      setModalVariant(3);
      return;
    }
    const cityRegistered = cities.find((i, index) => {
      return i.id == formState.city;
    });
    const planRegistered = planPrograms.find((i, index) => {
      return i.id == formState.planProgram;
    });
    const preferenceRegistered = preferences.find((i, index) => {
      return i.id == formState.preference;
    });
    const schedule = selectedTime.reduce((prev, next) => {
      return prev + " | " + next;
    }, "");

    const url = "http://mailsender.j-coders.com/api/regular-register";
    const data = {
      name: formState.name,
      birthday: formState.birthday,
      phone_number: formState.phoneNumber,
      email: formState.email,
      school: formState.school,
      city: cityRegistered.name,
      planprogram: planRegistered.name,
      preference: preferenceRegistered.name,
      message: formState.message,
      schedule: schedule,
    };

    const response = await postData(url, data);
  }

  const handleSelect = (options) => {
    setSelectedTime(options);
  };
  const handleChange = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value });
  };

  const handlePhoneNumberChange = (event) => {
    const { value } = event.target;

    // Replace all non-digit characters with an empty string and limit the length to 9 characters
    const cleanedValue = value.replace(/[^\d]/g, "").substring(0, 9);

    // Insert a "-" after every three digits
    let formattedValue = "";
    for (let i = 0; i < cleanedValue.length; i++) {
      if (i > 0 && i % 3 === 0) {
        formattedValue += "-";
      }
      formattedValue += cleanedValue.charAt(i);
    }

    // Update the state with the formatted value
    setPhoneNumber(formattedValue);
    setFormState({ ...formState, [event.target.name]: formattedValue });
  };

  return (
    <>
      <form action="" className="register__wrapper" onSubmit={handleSubmit}>
        <div className="register__input-double">
          <RegisterInput
            name="name"
            type="text"
            className="register-name"
            id="register-name"
            placeholder="Emri dhe mbiemri i fëmijut"
            value={formState.name || ""}
            onChange={handleChange}
          />
          <DateInput
            label="Ditëlindja"
            name="birthday"
            id="register-birthday"
            placeholder="Ditëlindja"
            min={new Date("2000-01-01")}
            max={new Date("2018-12-31")}
            title={"Please fill out this field | Ditëlindja | Birthday"}
            value={formState.birthday || ""}
            onChange={handleChange}
          />
        </div>
        <div className="register__input-double">
          <RegisterInput
            name="phoneNumber"
            type="tel"
            className="register-number"
            id="register-number"
            placeholder="Numri i telefonit i prindit"
            value={phoneNumber || ""}
            onChange={handlePhoneNumberChange}
          />
          <RegisterInput
            name="email"
            type="email"
            className="register-email"
            id="register-email"
            placeholder="Emaili i prindit"
            value={formState.email || ""}
            onChange={handleChange}
          />
        </div>

        <RegisterInput
          name="school"
          type="text"
          className="register-school"
          id="register-school"
          placeholder="Emri i shkollës që vijon fëmiju"
          value={formState.school || ""}
          onChange={handleChange}
          required={false}
        />

        <SelectMenu
          name="city"
          id="register-city"
          className="register__input register-city"
          onChange={handleChange}
          value={resetOptions ? "" : formState.city}
          label={"Qyteti në të cilin regjistroheni"}
          options={cities}
          disabled={selectedOption}
        />

        <SelectMenu
          name="planProgram"
          id="register-program"
          className="register__input register-program"
          onClick={() => {
            setSelectedOption(true);
            setResetOptions(false);
          }}
          onChange={handleChange}
          value={resetOptions ? "" : formState.planProgram}
          label={"Planprogrami"}
          options={planPrograms}
          disabled={selectedOption}
        />

        <SelectMenu
          name="preference"
          id="register-preference"
          className="register__input register-preference"
          onClick={() => {
            setSelectedOption(true);
            setResetOptions(false);
          }}
          onChange={handleChange}
          value={resetOptions ? "" : formState.preference}
          label={"Preferenca e ndjekjes së trajnimit:"}
          options={preferences}
          disabled={selectedOption}
        />

        <TextArea
          name={"message"}
          id={"message"}
          className={"register__input register-message"}
          placeholder={"Kerkëse/Informatë shtese (opsionale)"}
          onChange={handleChange}
          value={formState.message || ""}
        />

        <div className="register__time-select">
          <h2 htmlFor="register__cards-time">
            Zgjedhni oraret e përshtatshme për trajnim:
          </h2>
          <div className="register__cards-container" id="register__cards-time">
            <MultipleOptions options={options} onSelect={handleSelect} />
          </div>
        </div>

        <div className="register__btn-container">
          {(captchaVerified && (
            <button
              type={captchaVerified ? "submit" : ""}
              className={[
                `btn register-form-btn ${
                  captchaVerified ? "" : "disabled-btn"
                }`,
              ]}
              disabled={!captchaVerified}
              aria-label="Submit Register Form"
            >
              Regjistrohuni
            </button>
          )) || (
            <button
              className={[
                `btn register-form-btn ${
                  captchaVerified ? "" : "disabled-btn"
                }`,
              ]}
              disabled={true}
              aria-label="Submit Register Form"
            >
              Regjistrohuni
            </button>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6LdHFmEmAAAAALtMULJMFdNDjUQfla7eOlnR7NKx"
            onChange={() => {
              setCaptchaVerified(true);
            }}
            onExpired={() => {
              setCaptchaVerified(false);
            }}
            size={"compact"}
          />
        </div>
      </form>
      {(modalVariant == 1 && (
        <SuccessModal
          open={openModal}
          className={""}
          variant={"yellow"}
          closeModal={() => {
            setOpenModal(false);
          }}
          action={() => {
            setOpenModal(false);
          }}
          buttonText={"close"}
        />
      )) ||
        (modalVariant == 2 && (
          <ErrorModal
            open={openModal}
            className={""}
            variant={"red"}
            closeModal={() => {
              setOpenModal(false);
            }}
            action={() => {
              setOpenModal(false);
            }}
            buttonText={"close"}
            captchaError={false}
          />
        )) ||
        (modalVariant == 3 && (
          <ErrorModal
            open={openModal}
            className={""}
            variant={"red"}
            closeModal={() => {
              setOpenModal(false);
            }}
            action={() => {
              setOpenModal(false);
            }}
            buttonText={"close"}
            captchaError={true}
          />
        ))}
    </>
  );
}

export default Register;
