import ArticleImg from "assets/images/illustrations/image2.webp";
import AiLogo from "assets/images/article/integration/ai_logo.png";
import { UilAngleDown } from "@iconscout/react-unicons";

const text = [
  {
    id: 1,
    text: "1. ChatGPT - Program i inteligjencës artificiale i cili kupton gjuhën natyrale të folur. ChatGPT kupton përmbajtjën e shkruar dhe përgjigjet në pytjet tona në formë duke analizuar informata nga interneti*. *Informatat deri ne vitin 2021",
    theme: "#F15B5B",
  },
  {
    id: 2,
    text: "2. MidJourney - Program i inteligjences artificiale i cili kupton gjuhën natyrale të folur dhe përgjigjet duke konvertuar gjuhen në imazhe të gjeneruara. Kjo vegël gjeneron imazhe të dizajnuara ose reale, varësisht nga kërkesa jonë",
    theme: "#9F64A8",
  },
  {
    id: 3,
    text: "3. RunwayML - Program i inteligjencës artificiale i cili kupton gjuhën natyrale të folur dhe gjeneron video. Kjo do te thotë që për përdorimin e inteligjencës artificale përdorim gjuhën e folur.",
    theme: "#55C0A6",
  },
];

function ArticleIntegration() {
  return (
    <>
      <div className="article__integration-container container">
        <div className="article__integration-text">
          <div className="article__integration-title">
            <h1>Integrimi në kampin verorë</h1>
            <p>
              Andaj në programin tonë verorë kemi bërë integrimin e
              Inteligjencës artificiale. Fëmijët do të njoftohen me disa lloje
              të programeve të inteligjencës artificale sic janë:
            </p>
          </div>
          <div className="article__integration-image">
            <img loading="lazy" src={AiLogo} alt="Article Integration Image" />
          </div>
        </div>
        <div className="article__integration-content">
          <UilAngleDown />
          <div className="article__integration__content-wrapper">
            {text.map((i) => {
              return (
                <div key={i.id} style={{ border: `1px solid ${i.theme}` }}>
                  <p>{i.text}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="article__integration-img">
          <img
            loading="lazy"
            src={ArticleImg}
            alt="Article Integration Image"
          />
        </div>
      </div>
    </>
  );
}

export default ArticleIntegration;
