import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { EffectFade, Navigation, Pagination } from "swiper";

// Importing Assets
import usage1 from "assets/images/article/usage/unnamed1.webp";
import usage2 from "assets/images/article/usage/unnamed2.webp";
import usage3 from "assets/images/article/usage/unnamed3.webp";
import usage4 from "assets/images/article/usage/unnamed4.webp";

const useImg = [
  {
    id: 1,
    img: usage1,
  },
  {
    id: 2,
    img: usage2,
  },
  {
    id: 3,
    img: usage3,
  },
  {
    id: 4,
    img: usage4,
  },
];

function ArticleUse() {
  return (
    <>
      <div className="article__use-container container">
        <div className="article__use-content">
          <h1>Përdorimi i Inteligjencës Artificiale</h1>
          <p>
            Kjo do te thotë që për përdorimin e inteligjencës artificale
            përdorim gjuhën e folur. Pytjet ose kerkesat e bëra drejtë
            inteligjencës artificale quhen Prompte #prompt Sa më mirë dijmë të
            bëjme pytje, aq me mirë inteligjenca artificiale arrin të përgjigjet
            në formë të plotë mbi pytjet/kërkesat tona Ja disa shembuj si
            Inteligjenca Artificale ka përshpejtuar punën disa ditore të
            profesionistëve të ndryshëm në disa sekonda: dizajnereve të logove,
            krijueseve të kontentit, bile dhe dizjanerëve te interiorit.
          </p>
        </div>
        <div className="article__use-carousel">
          <Swiper
            effect="fade"
            navigation={true}
            pagination={{ clickable: true }}
            modules={[EffectFade, Navigation, Pagination]}
            className="article__carousel"
            slidesPerView={1}
          >
            {useImg.map((i, index) => {
              return (
                <SwiperSlide>
                  <div className="carousel__img">
                    <div>
                      <img loading="lazy" src={i.img} alt="Article Use Image" />
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default ArticleUse;
