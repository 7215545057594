import { team } from "helpers/Helpers";

function TeamSection(props) {
  return (
    <>
      {team.map((i, index) => {
        return (
          <div key={index} className="team__cards">
            <div className="team__img-container">
              <img loading="lazy" src={i.image} alt="Team Image" />
            </div>

            <div className="team__description">
              <h1>{i.name}</h1>
              <p>{i.position}</p>
              <p>{i.email}</p>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default TeamSection;
