const theme = {
  blue: "#40B1E5",
  red: "#783942",
  purple: "#84509F",
  yellow: "#FFFFFF",
};

function HeaderBackground(props) {
  const { color } = props;
  const _theme = theme[color];

  return (
    <div className="header__bg-illustration-cnt">
      <div className="header__bg-illustration">
        <svg
          width="286"
          height="489"
          viewBox="0 0 286 489"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.6736 34.1909C43.2167 26.1941 36.8791 18.0831 27.5456 17.2834C20.7471 16.6551 14.9857 19.8538 10.3765 24.3663C7.61104 23.738 4.67271 24.8233 4.03896 28.3647C0.294023 49.9561 -3.16284 73.6609 4.96078 94.7382C8.18719 103.078 14.4672 109.304 24.0311 108.504C33.768 107.704 41.085 99.5362 45.3484 91.4252C53.9906 74.7462 54.1058 51.3269 46.6736 34.1909ZM34.3441 87.3126C31.6938 91.8822 24.7801 100.679 18.8458 95.595C14.1791 91.5966 13.142 81.4863 12.4507 75.8886C10.8951 63.1508 12.2778 50.2417 14.3519 37.6181C14.5824 37.3897 14.8128 37.1041 15.0433 36.7613C18.8458 31.2778 25.3563 25.4516 31.4634 31.4492C36.0149 35.9045 37.8586 42.6447 38.9532 48.6423C41.2002 61.3229 40.9698 75.9457 34.3441 87.3126Z"
            fill={_theme}
          />
          <path
            d="M98.1814 144.319C94.7245 136.322 88.3869 128.211 79.0534 127.411C72.2549 126.783 66.4935 129.982 61.8843 134.494C59.1189 133.866 56.1805 134.951 55.5468 138.493C51.8018 160.084 48.345 183.789 56.4686 204.866C59.695 213.206 65.975 219.432 75.5389 218.632C85.2758 217.832 92.5928 209.664 96.8562 201.553C105.441 184.874 105.556 161.455 98.1814 144.319ZM85.7943 197.44C83.144 202.01 76.2303 210.807 70.296 205.723C65.6293 201.724 64.5922 191.614 63.9008 186.016C62.3453 173.279 63.728 160.37 65.8021 147.746C66.0326 147.518 66.263 147.232 66.4935 146.889C70.296 141.406 76.8065 135.58 82.9136 141.577C87.4651 146.032 89.3088 152.773 90.4034 158.77C92.6504 171.451 92.4199 186.074 85.7943 197.44Z"
            fill={_theme}
          />
          <path
            d="M110.223 14.8277C99.5064 7.74486 87.5802 12.1431 82.0492 21.2252C80.897 21.8535 80.0327 22.9959 79.6294 24.6524C75.2508 43.5591 72.1396 63.7796 72.4277 83.2004C72.5429 90.9687 74.5018 98.9084 80.7241 104.106C86.9465 109.304 95.2429 109.247 102.041 105.192C116.791 96.338 120.536 77.9454 121.227 62.1802C121.861 46.5294 125.548 24.938 110.223 14.8277ZM104.576 85.371C102.157 89.9977 95.531 99.4225 89.1934 96.0524C82.3949 92.4539 84.0081 81.4868 84.3538 75.3179C85.1028 62.6943 86.4855 50.1279 88.7901 37.6758C90 36.4191 90.749 34.6484 90.4609 32.535C89.4239 25.6805 96.9137 20.8825 102.848 23.9098C109.877 27.4513 110.107 35.4481 109.992 42.2454C109.762 56.2398 111.202 72.519 104.576 85.371Z"
            fill={_theme}
          />
          <path
            d="M164.726 12.5992C158.504 22.3096 152.8 32.3057 147.615 42.6444C144.331 49.2703 154.356 55.1537 157.697 48.4706C158.734 46.4143 159.771 44.4151 160.808 42.4159C157.697 61.2084 153.376 79.8866 153.376 99.079C153.376 106.505 165.014 106.505 165.014 99.079C165.072 70.8617 174.348 43.6725 175.558 15.5695C175.903 9.51476 167.722 8.02964 164.726 12.5992Z"
            fill={_theme}
          />
          <path
            d="M24.607 128.327C18.327 138.037 12.6232 148.033 7.49551 158.372C4.2115 164.998 14.2364 170.881 17.578 164.198C18.6151 162.142 19.6521 160.142 20.6892 158.143C17.578 176.936 13.2569 195.614 13.2569 214.806C13.2569 222.232 24.895 222.232 24.895 214.806C24.9527 186.589 34.2286 159.4 35.4385 131.297C35.7842 125.242 27.6029 123.7 24.607 128.327Z"
            fill={_theme}
          />
          <path
            d="M219.112 5.63117C218.593 -0.252191 211.68 -2.02291 208.223 2.71805C199.984 14.0849 194.28 26.8798 191.284 40.5315C189.729 47.7857 200.963 50.8702 202.519 43.616C203.729 38.0182 205.457 32.6489 207.589 27.5081C206.321 51.9555 200.848 76.2315 200.733 100.793C200.675 108.219 212.371 108.219 212.371 100.793C212.601 68.9201 221.762 37.6184 219.112 5.63117Z"
            fill={_theme}
          />
          <path
            d="M266.185 134.437C265.667 128.554 258.753 126.783 255.296 131.524C247.057 142.891 241.353 155.686 238.357 169.338C236.802 176.592 248.037 179.676 249.592 172.422C250.802 166.824 252.53 161.455 254.662 156.314C253.395 180.762 247.921 205.038 247.806 229.599C247.748 237.025 259.444 237.025 259.444 229.599C259.675 197.669 268.893 166.367 266.185 134.437Z"
            fill={_theme}
          />
          <path
            d="M281.797 14.6558C277.592 8.20128 269.641 3.91727 261.805 5.91648C253.566 8.02992 248.784 15.6269 245.5 22.824C245.04 23.8522 244.867 24.8232 244.924 25.7371C238.068 43.1587 233.978 61.4371 232.768 80.1154C232.307 87.541 230.463 98.5651 235.072 105.077C239.566 111.36 248.612 111.531 255.295 109.189C272.464 103.249 277.592 84.0567 280.991 68.2915C283.007 59.0381 284.448 49.6704 285.427 40.2456C286.291 31.6205 286.752 22.1957 281.797 14.6558ZM274.02 37.9608C273.271 46.7573 271.888 55.4967 270.044 64.1218C268.431 71.8901 266.76 80.1725 263.073 87.2554C260.48 92.1677 253.566 100.907 246.71 99.0221C241.41 97.5941 244.406 79.944 244.809 75.5458C246.365 58.9239 250.628 42.6447 256.85 27.108C257.139 26.3654 257.311 25.68 257.254 24.9946C259.616 20.3107 263.303 15.1128 268.604 17.6832C275.69 21.1104 274.538 31.7347 274.02 37.9608Z"
            fill={_theme}
          />
          <path
            d="M221.706 136.72C217.5 130.266 209.549 125.982 201.713 127.981C193.475 130.094 188.693 137.691 185.409 144.888C184.948 145.917 184.775 146.888 184.832 147.802C177.976 165.223 173.886 183.502 172.676 202.18C172.215 209.605 170.371 220.63 174.98 227.141C179.474 233.424 188.52 233.596 195.203 231.254C212.372 225.313 217.5 206.121 220.899 190.356C222.916 181.103 224.356 171.735 225.335 162.31C226.2 153.742 226.661 144.317 221.706 136.72ZM213.928 160.025C213.179 168.822 211.796 177.561 209.952 186.186C208.339 193.955 206.668 202.237 202.981 209.32C200.388 214.232 193.475 222.972 186.619 221.087C181.318 219.659 184.314 202.008 184.717 197.61C186.273 180.988 190.536 164.709 196.759 149.172C197.047 148.43 197.22 147.744 197.162 147.059C199.524 142.375 203.211 137.177 208.512 139.748C215.599 143.175 214.446 153.799 213.928 160.025Z"
            fill={_theme}
          />
          <path
            d="M153.204 128.496C148.076 122.727 139.607 119.642 132.174 122.727C124.339 125.982 120.709 134.208 118.52 141.805C118.232 142.89 118.174 143.861 118.405 144.775C114.199 162.996 112.758 181.675 114.314 200.353C114.89 207.778 114.717 218.917 120.248 224.686C125.606 230.227 134.594 229.141 140.874 225.885C157.006 217.546 159.253 197.839 160.348 181.732C160.982 172.307 161.039 162.825 160.636 153.343C160.175 144.661 159.253 135.293 153.204 128.496ZM148.883 152.658C149.401 161.454 149.343 170.308 148.767 179.104C148.249 186.987 147.903 195.44 145.253 202.98C143.409 208.235 137.821 217.832 130.792 216.975C125.318 216.289 125.722 198.411 125.491 194.012C124.569 177.333 126.413 160.597 130.388 144.375C130.561 143.575 130.619 142.89 130.504 142.205C132.174 137.235 135.055 131.58 140.701 133.351C148.076 135.807 148.537 146.431 148.883 152.658Z"
            fill={_theme}
          />
          <path
            d="M195.953 287.576C192.496 279.579 186.158 271.468 176.825 270.669C170.026 270.04 164.265 273.239 159.656 277.752C156.89 277.123 153.952 278.208 153.318 281.75C149.573 303.341 146.116 327.046 154.24 348.123C157.466 356.463 163.746 362.689 173.31 361.889C183.047 361.09 190.364 352.921 194.628 344.81C203.27 328.131 203.327 304.712 195.953 287.576ZM183.566 340.698C180.916 345.267 174.002 354.064 168.068 348.98C163.401 344.982 162.364 334.872 161.672 329.274C160.117 316.536 161.499 303.627 163.574 291.003C163.804 290.775 164.035 290.489 164.265 290.147C168.068 284.663 174.578 278.837 180.685 284.834C185.237 289.29 187.08 296.03 188.175 302.028C190.422 314.708 190.191 329.331 183.566 340.698Z"
            fill={_theme}
          />
          <path
            d="M98.1814 397.703C94.7245 389.706 88.3869 381.595 79.0534 380.795C72.2549 380.167 66.4935 383.366 61.8843 387.878C59.1189 387.25 56.1805 388.335 55.5468 391.876C51.8018 413.468 48.345 437.173 56.4686 458.25C59.695 466.589 65.975 472.815 75.5389 472.016C85.2758 471.216 92.5928 463.048 96.8562 454.937C105.441 438.258 105.556 414.839 98.1814 397.703ZM85.7943 450.824C83.144 455.394 76.2303 464.19 70.296 459.107C65.6293 455.108 64.5922 444.998 63.9008 439.4C62.3453 426.662 63.728 413.753 65.8021 401.13C66.0326 400.901 66.263 400.616 66.4935 400.273C70.296 394.79 76.8065 388.963 82.9136 394.961C87.4651 399.416 89.3088 406.156 90.4034 412.154C92.6504 424.835 92.4199 439.457 85.7943 450.824Z"
            fill={_theme}
          />
          <path
            d="M259.443 268.213C248.727 261.13 236.801 265.528 231.27 274.61C230.118 275.238 229.253 276.381 228.85 278.037C224.471 296.944 221.36 317.164 221.648 336.585C221.764 344.353 223.722 352.293 229.945 357.491C236.167 362.689 244.464 362.632 251.262 358.576C266.011 349.723 269.756 331.33 270.448 315.565C271.139 299.914 274.826 278.323 259.443 268.213ZM253.797 338.756C251.377 343.382 244.752 352.807 238.414 349.437C231.616 345.839 233.229 334.872 233.575 328.703C234.324 316.079 235.706 303.513 238.011 291.061C239.221 289.804 239.97 288.033 239.682 285.92C238.645 279.065 246.134 274.267 252.069 277.295C259.098 280.836 259.328 288.833 259.213 295.63C259.04 309.625 260.48 325.904 253.797 338.756Z"
            fill={_theme}
          />
          <path
            d="M125.491 265.985C119.211 275.696 113.507 285.692 108.379 296.031C105.095 302.657 115.12 308.54 118.462 301.857C119.499 299.801 120.536 297.801 121.573 295.802C118.462 314.595 114.141 333.273 114.141 352.465C114.141 359.891 125.779 359.891 125.779 352.465C125.836 324.248 135.112 297.059 136.322 268.956C136.668 262.901 128.487 261.416 125.491 265.985Z"
            fill={_theme}
          />
          <path
            d="M24.607 381.709C18.327 391.42 12.6232 401.416 7.49551 411.755C4.2115 418.381 14.2364 424.264 17.578 417.581C18.6151 415.525 19.6521 413.525 20.6892 411.526C17.578 430.319 13.2569 448.997 13.2569 468.189C13.2569 475.615 24.895 475.615 24.895 468.189C24.9527 439.972 34.2286 412.783 35.4385 384.68C35.7842 378.625 27.6029 377.083 24.607 381.709Z"
            fill={_theme}
          />
          <path
            d="M28.0064 259.016C27.4878 253.133 20.5741 251.362 17.1173 256.103C8.87842 267.47 3.1746 280.265 0.178652 293.917C-1.37693 301.171 9.85786 304.255 11.4134 297.001C12.6233 291.403 14.3518 286.034 16.4835 280.893C15.216 305.341 9.74263 329.617 9.6274 354.178C9.56979 361.604 21.2655 361.604 21.2655 354.178C21.496 322.305 30.6566 291.004 28.0064 259.016Z"
            fill={_theme}
          />
          <path
            d="M266.185 387.764C265.667 381.88 258.753 380.109 255.296 384.85C247.057 396.217 241.353 409.012 238.357 422.664C236.802 429.918 248.037 433.003 249.592 425.748C250.802 420.151 252.53 414.781 254.662 409.64C253.395 434.088 247.921 458.364 247.806 482.925C247.748 490.351 259.444 490.351 259.444 482.925C259.675 451.052 268.893 419.751 266.185 387.764Z"
            fill={_theme}
          />
          <path
            d="M90.6344 267.984C86.4285 261.529 78.4778 257.245 70.6422 259.245C62.4033 261.358 57.6214 268.955 54.3373 276.152C53.8764 277.18 53.7036 278.151 53.7612 279.065C46.9051 296.487 42.8145 314.765 41.6046 333.443C41.1437 340.869 39.3 351.893 43.9091 358.405C48.4031 364.688 57.4485 364.86 64.1318 362.518C81.3009 356.577 86.4285 337.385 89.8278 321.62C91.8443 312.366 93.2846 302.999 94.2641 293.574C95.1859 285.006 95.6468 275.581 90.6344 267.984ZM82.8564 291.346C82.1075 300.143 80.7247 308.882 78.8811 317.507C77.2679 325.275 75.597 333.558 71.9097 340.641C69.3171 345.553 62.4034 354.292 55.5472 352.407C50.2467 350.979 53.2427 333.329 53.646 328.931C55.2016 312.309 59.465 296.03 65.6874 280.493C65.9754 279.751 66.1483 279.065 66.0907 278.38C68.4529 273.696 72.1402 268.498 77.4407 271.068C84.5273 274.496 83.4326 285.12 82.8564 291.346Z"
            fill={_theme}
          />
          <path
            d="M221.706 390.048C217.5 383.594 209.549 379.31 201.713 381.309C193.475 383.422 188.693 391.019 185.409 398.217C184.948 399.245 184.775 400.216 184.832 401.13C177.976 418.551 173.886 436.83 172.676 455.508C172.215 462.934 170.371 473.958 174.98 480.469C179.474 486.753 188.52 486.924 195.203 484.582C212.372 478.642 217.5 459.449 220.899 443.684C222.916 434.431 224.356 425.063 225.335 415.638C226.2 407.07 226.661 397.645 221.706 390.048ZM213.928 413.41C213.179 422.207 211.796 430.946 209.952 439.571C208.339 447.34 206.668 455.622 202.981 462.705C200.388 467.617 193.475 476.357 186.619 474.472C181.318 473.044 184.314 455.394 184.717 450.995C186.273 434.374 190.536 418.094 196.759 402.558C197.047 401.815 197.22 401.13 197.162 400.444C199.524 395.76 203.211 390.562 208.512 393.133C215.599 396.56 214.446 407.184 213.928 413.41Z"
            fill={_theme}
          />
          <path
            d="M153.204 381.881C148.076 376.111 139.607 373.027 132.174 376.111C124.339 379.367 120.709 387.593 118.52 395.19C118.232 396.275 118.174 397.246 118.405 398.16C114.199 416.381 112.758 435.059 114.314 453.738C114.89 461.163 114.717 472.302 120.248 478.071C125.606 483.611 134.594 482.526 140.874 479.27C157.006 470.931 159.253 451.224 160.348 435.116C160.982 425.692 161.039 416.21 160.636 406.728C160.175 398.046 159.253 388.678 153.204 381.881ZM148.883 406.042C149.401 414.839 149.343 423.692 148.767 432.489C148.249 440.371 147.903 448.825 145.253 456.365C143.409 461.62 137.821 471.216 130.792 470.359C125.318 469.674 125.722 451.795 125.491 447.397C124.569 430.718 126.413 413.982 130.388 397.76C130.561 396.96 130.619 396.275 130.504 395.589C132.174 390.62 135.055 384.965 140.701 386.736C148.076 389.192 148.537 399.816 148.883 406.042Z"
            fill={_theme}
          />
        </svg>
        <svg
          width="286"
          height="489"
          viewBox="0 0 286 489"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.6736 34.1909C43.2167 26.1941 36.8791 18.0831 27.5456 17.2834C20.7471 16.6551 14.9857 19.8538 10.3765 24.3663C7.61104 23.738 4.67271 24.8233 4.03896 28.3647C0.294023 49.9561 -3.16284 73.6609 4.96078 94.7382C8.18719 103.078 14.4672 109.304 24.0311 108.504C33.768 107.704 41.085 99.5362 45.3484 91.4252C53.9906 74.7462 54.1058 51.3269 46.6736 34.1909ZM34.3441 87.3126C31.6938 91.8822 24.7801 100.679 18.8458 95.595C14.1791 91.5966 13.142 81.4863 12.4507 75.8886C10.8951 63.1508 12.2778 50.2417 14.3519 37.6181C14.5824 37.3897 14.8128 37.1041 15.0433 36.7613C18.8458 31.2778 25.3563 25.4516 31.4634 31.4492C36.0149 35.9045 37.8586 42.6447 38.9532 48.6423C41.2002 61.3229 40.9698 75.9457 34.3441 87.3126Z"
            fill={_theme}
          />
          <path
            d="M98.1814 144.319C94.7245 136.322 88.3869 128.211 79.0534 127.411C72.2549 126.783 66.4935 129.982 61.8843 134.494C59.1189 133.866 56.1805 134.951 55.5468 138.493C51.8018 160.084 48.345 183.789 56.4686 204.866C59.695 213.206 65.975 219.432 75.5389 218.632C85.2758 217.832 92.5928 209.664 96.8562 201.553C105.441 184.874 105.556 161.455 98.1814 144.319ZM85.7943 197.44C83.144 202.01 76.2303 210.807 70.296 205.723C65.6293 201.724 64.5922 191.614 63.9008 186.016C62.3453 173.279 63.728 160.37 65.8021 147.746C66.0326 147.518 66.263 147.232 66.4935 146.889C70.296 141.406 76.8065 135.58 82.9136 141.577C87.4651 146.032 89.3088 152.773 90.4034 158.77C92.6504 171.451 92.4199 186.074 85.7943 197.44Z"
            fill={_theme}
          />
          <path
            d="M110.223 14.8277C99.5064 7.74486 87.5802 12.1431 82.0492 21.2252C80.897 21.8535 80.0327 22.9959 79.6294 24.6524C75.2508 43.5591 72.1396 63.7796 72.4277 83.2004C72.5429 90.9687 74.5018 98.9084 80.7241 104.106C86.9465 109.304 95.2429 109.247 102.041 105.192C116.791 96.338 120.536 77.9454 121.227 62.1802C121.861 46.5294 125.548 24.938 110.223 14.8277ZM104.576 85.371C102.157 89.9977 95.531 99.4225 89.1934 96.0524C82.3949 92.4539 84.0081 81.4868 84.3538 75.3179C85.1028 62.6943 86.4855 50.1279 88.7901 37.6758C90 36.4191 90.749 34.6484 90.4609 32.535C89.4239 25.6805 96.9137 20.8825 102.848 23.9098C109.877 27.4513 110.107 35.4481 109.992 42.2454C109.762 56.2398 111.202 72.519 104.576 85.371Z"
            fill={_theme}
          />
          <path
            d="M164.726 12.5992C158.504 22.3096 152.8 32.3057 147.615 42.6444C144.331 49.2703 154.356 55.1537 157.697 48.4706C158.734 46.4143 159.771 44.4151 160.808 42.4159C157.697 61.2084 153.376 79.8866 153.376 99.079C153.376 106.505 165.014 106.505 165.014 99.079C165.072 70.8617 174.348 43.6725 175.558 15.5695C175.903 9.51476 167.722 8.02964 164.726 12.5992Z"
            fill={_theme}
          />
          <path
            d="M24.607 128.327C18.327 138.037 12.6232 148.033 7.49551 158.372C4.2115 164.998 14.2364 170.881 17.578 164.198C18.6151 162.142 19.6521 160.142 20.6892 158.143C17.578 176.936 13.2569 195.614 13.2569 214.806C13.2569 222.232 24.895 222.232 24.895 214.806C24.9527 186.589 34.2286 159.4 35.4385 131.297C35.7842 125.242 27.6029 123.7 24.607 128.327Z"
            fill={_theme}
          />
          <path
            d="M219.112 5.63117C218.593 -0.252191 211.68 -2.02291 208.223 2.71805C199.984 14.0849 194.28 26.8798 191.284 40.5315C189.729 47.7857 200.963 50.8702 202.519 43.616C203.729 38.0182 205.457 32.6489 207.589 27.5081C206.321 51.9555 200.848 76.2315 200.733 100.793C200.675 108.219 212.371 108.219 212.371 100.793C212.601 68.9201 221.762 37.6184 219.112 5.63117Z"
            fill={_theme}
          />
          <path
            d="M266.185 134.437C265.667 128.554 258.753 126.783 255.296 131.524C247.057 142.891 241.353 155.686 238.357 169.338C236.802 176.592 248.037 179.676 249.592 172.422C250.802 166.824 252.53 161.455 254.662 156.314C253.395 180.762 247.921 205.038 247.806 229.599C247.748 237.025 259.444 237.025 259.444 229.599C259.675 197.669 268.893 166.367 266.185 134.437Z"
            fill={_theme}
          />
          <path
            d="M281.797 14.6558C277.592 8.20128 269.641 3.91727 261.805 5.91648C253.566 8.02992 248.784 15.6269 245.5 22.824C245.04 23.8522 244.867 24.8232 244.924 25.7371C238.068 43.1587 233.978 61.4371 232.768 80.1154C232.307 87.541 230.463 98.5651 235.072 105.077C239.566 111.36 248.612 111.531 255.295 109.189C272.464 103.249 277.592 84.0567 280.991 68.2915C283.007 59.0381 284.448 49.6704 285.427 40.2456C286.291 31.6205 286.752 22.1957 281.797 14.6558ZM274.02 37.9608C273.271 46.7573 271.888 55.4967 270.044 64.1218C268.431 71.8901 266.76 80.1725 263.073 87.2554C260.48 92.1677 253.566 100.907 246.71 99.0221C241.41 97.5941 244.406 79.944 244.809 75.5458C246.365 58.9239 250.628 42.6447 256.85 27.108C257.139 26.3654 257.311 25.68 257.254 24.9946C259.616 20.3107 263.303 15.1128 268.604 17.6832C275.69 21.1104 274.538 31.7347 274.02 37.9608Z"
            fill={_theme}
          />
          <path
            d="M221.706 136.72C217.5 130.266 209.549 125.982 201.713 127.981C193.475 130.094 188.693 137.691 185.409 144.888C184.948 145.917 184.775 146.888 184.832 147.802C177.976 165.223 173.886 183.502 172.676 202.18C172.215 209.605 170.371 220.63 174.98 227.141C179.474 233.424 188.52 233.596 195.203 231.254C212.372 225.313 217.5 206.121 220.899 190.356C222.916 181.103 224.356 171.735 225.335 162.31C226.2 153.742 226.661 144.317 221.706 136.72ZM213.928 160.025C213.179 168.822 211.796 177.561 209.952 186.186C208.339 193.955 206.668 202.237 202.981 209.32C200.388 214.232 193.475 222.972 186.619 221.087C181.318 219.659 184.314 202.008 184.717 197.61C186.273 180.988 190.536 164.709 196.759 149.172C197.047 148.43 197.22 147.744 197.162 147.059C199.524 142.375 203.211 137.177 208.512 139.748C215.599 143.175 214.446 153.799 213.928 160.025Z"
            fill={_theme}
          />
          <path
            d="M153.204 128.496C148.076 122.727 139.607 119.642 132.174 122.727C124.339 125.982 120.709 134.208 118.52 141.805C118.232 142.89 118.174 143.861 118.405 144.775C114.199 162.996 112.758 181.675 114.314 200.353C114.89 207.778 114.717 218.917 120.248 224.686C125.606 230.227 134.594 229.141 140.874 225.885C157.006 217.546 159.253 197.839 160.348 181.732C160.982 172.307 161.039 162.825 160.636 153.343C160.175 144.661 159.253 135.293 153.204 128.496ZM148.883 152.658C149.401 161.454 149.343 170.308 148.767 179.104C148.249 186.987 147.903 195.44 145.253 202.98C143.409 208.235 137.821 217.832 130.792 216.975C125.318 216.289 125.722 198.411 125.491 194.012C124.569 177.333 126.413 160.597 130.388 144.375C130.561 143.575 130.619 142.89 130.504 142.205C132.174 137.235 135.055 131.58 140.701 133.351C148.076 135.807 148.537 146.431 148.883 152.658Z"
            fill={_theme}
          />
          <path
            d="M195.953 287.576C192.496 279.579 186.158 271.468 176.825 270.669C170.026 270.04 164.265 273.239 159.656 277.752C156.89 277.123 153.952 278.208 153.318 281.75C149.573 303.341 146.116 327.046 154.24 348.123C157.466 356.463 163.746 362.689 173.31 361.889C183.047 361.09 190.364 352.921 194.628 344.81C203.27 328.131 203.327 304.712 195.953 287.576ZM183.566 340.698C180.916 345.267 174.002 354.064 168.068 348.98C163.401 344.982 162.364 334.872 161.672 329.274C160.117 316.536 161.499 303.627 163.574 291.003C163.804 290.775 164.035 290.489 164.265 290.147C168.068 284.663 174.578 278.837 180.685 284.834C185.237 289.29 187.08 296.03 188.175 302.028C190.422 314.708 190.191 329.331 183.566 340.698Z"
            fill={_theme}
          />
          <path
            d="M98.1814 397.703C94.7245 389.706 88.3869 381.595 79.0534 380.795C72.2549 380.167 66.4935 383.366 61.8843 387.878C59.1189 387.25 56.1805 388.335 55.5468 391.876C51.8018 413.468 48.345 437.173 56.4686 458.25C59.695 466.589 65.975 472.815 75.5389 472.016C85.2758 471.216 92.5928 463.048 96.8562 454.937C105.441 438.258 105.556 414.839 98.1814 397.703ZM85.7943 450.824C83.144 455.394 76.2303 464.19 70.296 459.107C65.6293 455.108 64.5922 444.998 63.9008 439.4C62.3453 426.662 63.728 413.753 65.8021 401.13C66.0326 400.901 66.263 400.616 66.4935 400.273C70.296 394.79 76.8065 388.963 82.9136 394.961C87.4651 399.416 89.3088 406.156 90.4034 412.154C92.6504 424.835 92.4199 439.457 85.7943 450.824Z"
            fill={_theme}
          />
          <path
            d="M259.443 268.213C248.727 261.13 236.801 265.528 231.27 274.61C230.118 275.238 229.253 276.381 228.85 278.037C224.471 296.944 221.36 317.164 221.648 336.585C221.764 344.353 223.722 352.293 229.945 357.491C236.167 362.689 244.464 362.632 251.262 358.576C266.011 349.723 269.756 331.33 270.448 315.565C271.139 299.914 274.826 278.323 259.443 268.213ZM253.797 338.756C251.377 343.382 244.752 352.807 238.414 349.437C231.616 345.839 233.229 334.872 233.575 328.703C234.324 316.079 235.706 303.513 238.011 291.061C239.221 289.804 239.97 288.033 239.682 285.92C238.645 279.065 246.134 274.267 252.069 277.295C259.098 280.836 259.328 288.833 259.213 295.63C259.04 309.625 260.48 325.904 253.797 338.756Z"
            fill={_theme}
          />
          <path
            d="M125.491 265.985C119.211 275.696 113.507 285.692 108.379 296.031C105.095 302.657 115.12 308.54 118.462 301.857C119.499 299.801 120.536 297.801 121.573 295.802C118.462 314.595 114.141 333.273 114.141 352.465C114.141 359.891 125.779 359.891 125.779 352.465C125.836 324.248 135.112 297.059 136.322 268.956C136.668 262.901 128.487 261.416 125.491 265.985Z"
            fill={_theme}
          />
          <path
            d="M24.607 381.709C18.327 391.42 12.6232 401.416 7.49551 411.755C4.2115 418.381 14.2364 424.264 17.578 417.581C18.6151 415.525 19.6521 413.525 20.6892 411.526C17.578 430.319 13.2569 448.997 13.2569 468.189C13.2569 475.615 24.895 475.615 24.895 468.189C24.9527 439.972 34.2286 412.783 35.4385 384.68C35.7842 378.625 27.6029 377.083 24.607 381.709Z"
            fill={_theme}
          />
          <path
            d="M28.0064 259.016C27.4878 253.133 20.5741 251.362 17.1173 256.103C8.87842 267.47 3.1746 280.265 0.178652 293.917C-1.37693 301.171 9.85786 304.255 11.4134 297.001C12.6233 291.403 14.3518 286.034 16.4835 280.893C15.216 305.341 9.74263 329.617 9.6274 354.178C9.56979 361.604 21.2655 361.604 21.2655 354.178C21.496 322.305 30.6566 291.004 28.0064 259.016Z"
            fill={_theme}
          />
          <path
            d="M266.185 387.764C265.667 381.88 258.753 380.109 255.296 384.85C247.057 396.217 241.353 409.012 238.357 422.664C236.802 429.918 248.037 433.003 249.592 425.748C250.802 420.151 252.53 414.781 254.662 409.64C253.395 434.088 247.921 458.364 247.806 482.925C247.748 490.351 259.444 490.351 259.444 482.925C259.675 451.052 268.893 419.751 266.185 387.764Z"
            fill={_theme}
          />
          <path
            d="M90.6344 267.984C86.4285 261.529 78.4778 257.245 70.6422 259.245C62.4033 261.358 57.6214 268.955 54.3373 276.152C53.8764 277.18 53.7036 278.151 53.7612 279.065C46.9051 296.487 42.8145 314.765 41.6046 333.443C41.1437 340.869 39.3 351.893 43.9091 358.405C48.4031 364.688 57.4485 364.86 64.1318 362.518C81.3009 356.577 86.4285 337.385 89.8278 321.62C91.8443 312.366 93.2846 302.999 94.2641 293.574C95.1859 285.006 95.6468 275.581 90.6344 267.984ZM82.8564 291.346C82.1075 300.143 80.7247 308.882 78.8811 317.507C77.2679 325.275 75.597 333.558 71.9097 340.641C69.3171 345.553 62.4034 354.292 55.5472 352.407C50.2467 350.979 53.2427 333.329 53.646 328.931C55.2016 312.309 59.465 296.03 65.6874 280.493C65.9754 279.751 66.1483 279.065 66.0907 278.38C68.4529 273.696 72.1402 268.498 77.4407 271.068C84.5273 274.496 83.4326 285.12 82.8564 291.346Z"
            fill={_theme}
          />
          <path
            d="M221.706 390.048C217.5 383.594 209.549 379.31 201.713 381.309C193.475 383.422 188.693 391.019 185.409 398.217C184.948 399.245 184.775 400.216 184.832 401.13C177.976 418.551 173.886 436.83 172.676 455.508C172.215 462.934 170.371 473.958 174.98 480.469C179.474 486.753 188.52 486.924 195.203 484.582C212.372 478.642 217.5 459.449 220.899 443.684C222.916 434.431 224.356 425.063 225.335 415.638C226.2 407.07 226.661 397.645 221.706 390.048ZM213.928 413.41C213.179 422.207 211.796 430.946 209.952 439.571C208.339 447.34 206.668 455.622 202.981 462.705C200.388 467.617 193.475 476.357 186.619 474.472C181.318 473.044 184.314 455.394 184.717 450.995C186.273 434.374 190.536 418.094 196.759 402.558C197.047 401.815 197.22 401.13 197.162 400.444C199.524 395.76 203.211 390.562 208.512 393.133C215.599 396.56 214.446 407.184 213.928 413.41Z"
            fill={_theme}
          />
          <path
            d="M153.204 381.881C148.076 376.111 139.607 373.027 132.174 376.111C124.339 379.367 120.709 387.593 118.52 395.19C118.232 396.275 118.174 397.246 118.405 398.16C114.199 416.381 112.758 435.059 114.314 453.738C114.89 461.163 114.717 472.302 120.248 478.071C125.606 483.611 134.594 482.526 140.874 479.27C157.006 470.931 159.253 451.224 160.348 435.116C160.982 425.692 161.039 416.21 160.636 406.728C160.175 398.046 159.253 388.678 153.204 381.881ZM148.883 406.042C149.401 414.839 149.343 423.692 148.767 432.489C148.249 440.371 147.903 448.825 145.253 456.365C143.409 461.62 137.821 471.216 130.792 470.359C125.318 469.674 125.722 451.795 125.491 447.397C124.569 430.718 126.413 413.982 130.388 397.76C130.561 396.96 130.619 396.275 130.504 395.589C132.174 390.62 135.055 384.965 140.701 386.736C148.076 389.192 148.537 399.816 148.883 406.042Z"
            fill={_theme}
          />
        </svg>
      </div>
      <div className="header__bg-illustration">
        <svg
          width="286"
          height="489"
          viewBox="0 0 286 489"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.6736 34.1909C43.2167 26.1941 36.8791 18.0831 27.5456 17.2834C20.7471 16.6551 14.9857 19.8538 10.3765 24.3663C7.61104 23.738 4.67271 24.8233 4.03896 28.3647C0.294023 49.9561 -3.16284 73.6609 4.96078 94.7382C8.18719 103.078 14.4672 109.304 24.0311 108.504C33.768 107.704 41.085 99.5362 45.3484 91.4252C53.9906 74.7462 54.1058 51.3269 46.6736 34.1909ZM34.3441 87.3126C31.6938 91.8822 24.7801 100.679 18.8458 95.595C14.1791 91.5966 13.142 81.4863 12.4507 75.8886C10.8951 63.1508 12.2778 50.2417 14.3519 37.6181C14.5824 37.3897 14.8128 37.1041 15.0433 36.7613C18.8458 31.2778 25.3563 25.4516 31.4634 31.4492C36.0149 35.9045 37.8586 42.6447 38.9532 48.6423C41.2002 61.3229 40.9698 75.9457 34.3441 87.3126Z"
            fill={_theme}
          />
          <path
            d="M98.1814 144.319C94.7245 136.322 88.3869 128.211 79.0534 127.411C72.2549 126.783 66.4935 129.982 61.8843 134.494C59.1189 133.866 56.1805 134.951 55.5468 138.493C51.8018 160.084 48.345 183.789 56.4686 204.866C59.695 213.206 65.975 219.432 75.5389 218.632C85.2758 217.832 92.5928 209.664 96.8562 201.553C105.441 184.874 105.556 161.455 98.1814 144.319ZM85.7943 197.44C83.144 202.01 76.2303 210.807 70.296 205.723C65.6293 201.724 64.5922 191.614 63.9008 186.016C62.3453 173.279 63.728 160.37 65.8021 147.746C66.0326 147.518 66.263 147.232 66.4935 146.889C70.296 141.406 76.8065 135.58 82.9136 141.577C87.4651 146.032 89.3088 152.773 90.4034 158.77C92.6504 171.451 92.4199 186.074 85.7943 197.44Z"
            fill={_theme}
          />
          <path
            d="M110.223 14.8277C99.5064 7.74486 87.5802 12.1431 82.0492 21.2252C80.897 21.8535 80.0327 22.9959 79.6294 24.6524C75.2508 43.5591 72.1396 63.7796 72.4277 83.2004C72.5429 90.9687 74.5018 98.9084 80.7241 104.106C86.9465 109.304 95.2429 109.247 102.041 105.192C116.791 96.338 120.536 77.9454 121.227 62.1802C121.861 46.5294 125.548 24.938 110.223 14.8277ZM104.576 85.371C102.157 89.9977 95.531 99.4225 89.1934 96.0524C82.3949 92.4539 84.0081 81.4868 84.3538 75.3179C85.1028 62.6943 86.4855 50.1279 88.7901 37.6758C90 36.4191 90.749 34.6484 90.4609 32.535C89.4239 25.6805 96.9137 20.8825 102.848 23.9098C109.877 27.4513 110.107 35.4481 109.992 42.2454C109.762 56.2398 111.202 72.519 104.576 85.371Z"
            fill={_theme}
          />
          <path
            d="M164.726 12.5992C158.504 22.3096 152.8 32.3057 147.615 42.6444C144.331 49.2703 154.356 55.1537 157.697 48.4706C158.734 46.4143 159.771 44.4151 160.808 42.4159C157.697 61.2084 153.376 79.8866 153.376 99.079C153.376 106.505 165.014 106.505 165.014 99.079C165.072 70.8617 174.348 43.6725 175.558 15.5695C175.903 9.51476 167.722 8.02964 164.726 12.5992Z"
            fill={_theme}
          />
          <path
            d="M24.607 128.327C18.327 138.037 12.6232 148.033 7.49551 158.372C4.2115 164.998 14.2364 170.881 17.578 164.198C18.6151 162.142 19.6521 160.142 20.6892 158.143C17.578 176.936 13.2569 195.614 13.2569 214.806C13.2569 222.232 24.895 222.232 24.895 214.806C24.9527 186.589 34.2286 159.4 35.4385 131.297C35.7842 125.242 27.6029 123.7 24.607 128.327Z"
            fill={_theme}
          />
          <path
            d="M219.112 5.63117C218.593 -0.252191 211.68 -2.02291 208.223 2.71805C199.984 14.0849 194.28 26.8798 191.284 40.5315C189.729 47.7857 200.963 50.8702 202.519 43.616C203.729 38.0182 205.457 32.6489 207.589 27.5081C206.321 51.9555 200.848 76.2315 200.733 100.793C200.675 108.219 212.371 108.219 212.371 100.793C212.601 68.9201 221.762 37.6184 219.112 5.63117Z"
            fill={_theme}
          />
          <path
            d="M266.185 134.437C265.667 128.554 258.753 126.783 255.296 131.524C247.057 142.891 241.353 155.686 238.357 169.338C236.802 176.592 248.037 179.676 249.592 172.422C250.802 166.824 252.53 161.455 254.662 156.314C253.395 180.762 247.921 205.038 247.806 229.599C247.748 237.025 259.444 237.025 259.444 229.599C259.675 197.669 268.893 166.367 266.185 134.437Z"
            fill={_theme}
          />
          <path
            d="M281.797 14.6558C277.592 8.20128 269.641 3.91727 261.805 5.91648C253.566 8.02992 248.784 15.6269 245.5 22.824C245.04 23.8522 244.867 24.8232 244.924 25.7371C238.068 43.1587 233.978 61.4371 232.768 80.1154C232.307 87.541 230.463 98.5651 235.072 105.077C239.566 111.36 248.612 111.531 255.295 109.189C272.464 103.249 277.592 84.0567 280.991 68.2915C283.007 59.0381 284.448 49.6704 285.427 40.2456C286.291 31.6205 286.752 22.1957 281.797 14.6558ZM274.02 37.9608C273.271 46.7573 271.888 55.4967 270.044 64.1218C268.431 71.8901 266.76 80.1725 263.073 87.2554C260.48 92.1677 253.566 100.907 246.71 99.0221C241.41 97.5941 244.406 79.944 244.809 75.5458C246.365 58.9239 250.628 42.6447 256.85 27.108C257.139 26.3654 257.311 25.68 257.254 24.9946C259.616 20.3107 263.303 15.1128 268.604 17.6832C275.69 21.1104 274.538 31.7347 274.02 37.9608Z"
            fill={_theme}
          />
          <path
            d="M221.706 136.72C217.5 130.266 209.549 125.982 201.713 127.981C193.475 130.094 188.693 137.691 185.409 144.888C184.948 145.917 184.775 146.888 184.832 147.802C177.976 165.223 173.886 183.502 172.676 202.18C172.215 209.605 170.371 220.63 174.98 227.141C179.474 233.424 188.52 233.596 195.203 231.254C212.372 225.313 217.5 206.121 220.899 190.356C222.916 181.103 224.356 171.735 225.335 162.31C226.2 153.742 226.661 144.317 221.706 136.72ZM213.928 160.025C213.179 168.822 211.796 177.561 209.952 186.186C208.339 193.955 206.668 202.237 202.981 209.32C200.388 214.232 193.475 222.972 186.619 221.087C181.318 219.659 184.314 202.008 184.717 197.61C186.273 180.988 190.536 164.709 196.759 149.172C197.047 148.43 197.22 147.744 197.162 147.059C199.524 142.375 203.211 137.177 208.512 139.748C215.599 143.175 214.446 153.799 213.928 160.025Z"
            fill={_theme}
          />
          <path
            d="M153.204 128.496C148.076 122.727 139.607 119.642 132.174 122.727C124.339 125.982 120.709 134.208 118.52 141.805C118.232 142.89 118.174 143.861 118.405 144.775C114.199 162.996 112.758 181.675 114.314 200.353C114.89 207.778 114.717 218.917 120.248 224.686C125.606 230.227 134.594 229.141 140.874 225.885C157.006 217.546 159.253 197.839 160.348 181.732C160.982 172.307 161.039 162.825 160.636 153.343C160.175 144.661 159.253 135.293 153.204 128.496ZM148.883 152.658C149.401 161.454 149.343 170.308 148.767 179.104C148.249 186.987 147.903 195.44 145.253 202.98C143.409 208.235 137.821 217.832 130.792 216.975C125.318 216.289 125.722 198.411 125.491 194.012C124.569 177.333 126.413 160.597 130.388 144.375C130.561 143.575 130.619 142.89 130.504 142.205C132.174 137.235 135.055 131.58 140.701 133.351C148.076 135.807 148.537 146.431 148.883 152.658Z"
            fill={_theme}
          />
          <path
            d="M195.953 287.576C192.496 279.579 186.158 271.468 176.825 270.669C170.026 270.04 164.265 273.239 159.656 277.752C156.89 277.123 153.952 278.208 153.318 281.75C149.573 303.341 146.116 327.046 154.24 348.123C157.466 356.463 163.746 362.689 173.31 361.889C183.047 361.09 190.364 352.921 194.628 344.81C203.27 328.131 203.327 304.712 195.953 287.576ZM183.566 340.698C180.916 345.267 174.002 354.064 168.068 348.98C163.401 344.982 162.364 334.872 161.672 329.274C160.117 316.536 161.499 303.627 163.574 291.003C163.804 290.775 164.035 290.489 164.265 290.147C168.068 284.663 174.578 278.837 180.685 284.834C185.237 289.29 187.08 296.03 188.175 302.028C190.422 314.708 190.191 329.331 183.566 340.698Z"
            fill={_theme}
          />
          <path
            d="M98.1814 397.703C94.7245 389.706 88.3869 381.595 79.0534 380.795C72.2549 380.167 66.4935 383.366 61.8843 387.878C59.1189 387.25 56.1805 388.335 55.5468 391.876C51.8018 413.468 48.345 437.173 56.4686 458.25C59.695 466.589 65.975 472.815 75.5389 472.016C85.2758 471.216 92.5928 463.048 96.8562 454.937C105.441 438.258 105.556 414.839 98.1814 397.703ZM85.7943 450.824C83.144 455.394 76.2303 464.19 70.296 459.107C65.6293 455.108 64.5922 444.998 63.9008 439.4C62.3453 426.662 63.728 413.753 65.8021 401.13C66.0326 400.901 66.263 400.616 66.4935 400.273C70.296 394.79 76.8065 388.963 82.9136 394.961C87.4651 399.416 89.3088 406.156 90.4034 412.154C92.6504 424.835 92.4199 439.457 85.7943 450.824Z"
            fill={_theme}
          />
          <path
            d="M259.443 268.213C248.727 261.13 236.801 265.528 231.27 274.61C230.118 275.238 229.253 276.381 228.85 278.037C224.471 296.944 221.36 317.164 221.648 336.585C221.764 344.353 223.722 352.293 229.945 357.491C236.167 362.689 244.464 362.632 251.262 358.576C266.011 349.723 269.756 331.33 270.448 315.565C271.139 299.914 274.826 278.323 259.443 268.213ZM253.797 338.756C251.377 343.382 244.752 352.807 238.414 349.437C231.616 345.839 233.229 334.872 233.575 328.703C234.324 316.079 235.706 303.513 238.011 291.061C239.221 289.804 239.97 288.033 239.682 285.92C238.645 279.065 246.134 274.267 252.069 277.295C259.098 280.836 259.328 288.833 259.213 295.63C259.04 309.625 260.48 325.904 253.797 338.756Z"
            fill={_theme}
          />
          <path
            d="M125.491 265.985C119.211 275.696 113.507 285.692 108.379 296.031C105.095 302.657 115.12 308.54 118.462 301.857C119.499 299.801 120.536 297.801 121.573 295.802C118.462 314.595 114.141 333.273 114.141 352.465C114.141 359.891 125.779 359.891 125.779 352.465C125.836 324.248 135.112 297.059 136.322 268.956C136.668 262.901 128.487 261.416 125.491 265.985Z"
            fill={_theme}
          />
          <path
            d="M24.607 381.709C18.327 391.42 12.6232 401.416 7.49551 411.755C4.2115 418.381 14.2364 424.264 17.578 417.581C18.6151 415.525 19.6521 413.525 20.6892 411.526C17.578 430.319 13.2569 448.997 13.2569 468.189C13.2569 475.615 24.895 475.615 24.895 468.189C24.9527 439.972 34.2286 412.783 35.4385 384.68C35.7842 378.625 27.6029 377.083 24.607 381.709Z"
            fill={_theme}
          />
          <path
            d="M28.0064 259.016C27.4878 253.133 20.5741 251.362 17.1173 256.103C8.87842 267.47 3.1746 280.265 0.178652 293.917C-1.37693 301.171 9.85786 304.255 11.4134 297.001C12.6233 291.403 14.3518 286.034 16.4835 280.893C15.216 305.341 9.74263 329.617 9.6274 354.178C9.56979 361.604 21.2655 361.604 21.2655 354.178C21.496 322.305 30.6566 291.004 28.0064 259.016Z"
            fill={_theme}
          />
          <path
            d="M266.185 387.764C265.667 381.88 258.753 380.109 255.296 384.85C247.057 396.217 241.353 409.012 238.357 422.664C236.802 429.918 248.037 433.003 249.592 425.748C250.802 420.151 252.53 414.781 254.662 409.64C253.395 434.088 247.921 458.364 247.806 482.925C247.748 490.351 259.444 490.351 259.444 482.925C259.675 451.052 268.893 419.751 266.185 387.764Z"
            fill={_theme}
          />
          <path
            d="M90.6344 267.984C86.4285 261.529 78.4778 257.245 70.6422 259.245C62.4033 261.358 57.6214 268.955 54.3373 276.152C53.8764 277.18 53.7036 278.151 53.7612 279.065C46.9051 296.487 42.8145 314.765 41.6046 333.443C41.1437 340.869 39.3 351.893 43.9091 358.405C48.4031 364.688 57.4485 364.86 64.1318 362.518C81.3009 356.577 86.4285 337.385 89.8278 321.62C91.8443 312.366 93.2846 302.999 94.2641 293.574C95.1859 285.006 95.6468 275.581 90.6344 267.984ZM82.8564 291.346C82.1075 300.143 80.7247 308.882 78.8811 317.507C77.2679 325.275 75.597 333.558 71.9097 340.641C69.3171 345.553 62.4034 354.292 55.5472 352.407C50.2467 350.979 53.2427 333.329 53.646 328.931C55.2016 312.309 59.465 296.03 65.6874 280.493C65.9754 279.751 66.1483 279.065 66.0907 278.38C68.4529 273.696 72.1402 268.498 77.4407 271.068C84.5273 274.496 83.4326 285.12 82.8564 291.346Z"
            fill={_theme}
          />
          <path
            d="M221.706 390.048C217.5 383.594 209.549 379.31 201.713 381.309C193.475 383.422 188.693 391.019 185.409 398.217C184.948 399.245 184.775 400.216 184.832 401.13C177.976 418.551 173.886 436.83 172.676 455.508C172.215 462.934 170.371 473.958 174.98 480.469C179.474 486.753 188.52 486.924 195.203 484.582C212.372 478.642 217.5 459.449 220.899 443.684C222.916 434.431 224.356 425.063 225.335 415.638C226.2 407.07 226.661 397.645 221.706 390.048ZM213.928 413.41C213.179 422.207 211.796 430.946 209.952 439.571C208.339 447.34 206.668 455.622 202.981 462.705C200.388 467.617 193.475 476.357 186.619 474.472C181.318 473.044 184.314 455.394 184.717 450.995C186.273 434.374 190.536 418.094 196.759 402.558C197.047 401.815 197.22 401.13 197.162 400.444C199.524 395.76 203.211 390.562 208.512 393.133C215.599 396.56 214.446 407.184 213.928 413.41Z"
            fill={_theme}
          />
          <path
            d="M153.204 381.881C148.076 376.111 139.607 373.027 132.174 376.111C124.339 379.367 120.709 387.593 118.52 395.19C118.232 396.275 118.174 397.246 118.405 398.16C114.199 416.381 112.758 435.059 114.314 453.738C114.89 461.163 114.717 472.302 120.248 478.071C125.606 483.611 134.594 482.526 140.874 479.27C157.006 470.931 159.253 451.224 160.348 435.116C160.982 425.692 161.039 416.21 160.636 406.728C160.175 398.046 159.253 388.678 153.204 381.881ZM148.883 406.042C149.401 414.839 149.343 423.692 148.767 432.489C148.249 440.371 147.903 448.825 145.253 456.365C143.409 461.62 137.821 471.216 130.792 470.359C125.318 469.674 125.722 451.795 125.491 447.397C124.569 430.718 126.413 413.982 130.388 397.76C130.561 396.96 130.619 396.275 130.504 395.589C132.174 390.62 135.055 384.965 140.701 386.736C148.076 389.192 148.537 399.816 148.883 406.042Z"
            fill={_theme}
          />
        </svg>
        <svg
          width="286"
          height="489"
          viewBox="0 0 286 489"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M46.6736 34.1909C43.2167 26.1941 36.8791 18.0831 27.5456 17.2834C20.7471 16.6551 14.9857 19.8538 10.3765 24.3663C7.61104 23.738 4.67271 24.8233 4.03896 28.3647C0.294023 49.9561 -3.16284 73.6609 4.96078 94.7382C8.18719 103.078 14.4672 109.304 24.0311 108.504C33.768 107.704 41.085 99.5362 45.3484 91.4252C53.9906 74.7462 54.1058 51.3269 46.6736 34.1909ZM34.3441 87.3126C31.6938 91.8822 24.7801 100.679 18.8458 95.595C14.1791 91.5966 13.142 81.4863 12.4507 75.8886C10.8951 63.1508 12.2778 50.2417 14.3519 37.6181C14.5824 37.3897 14.8128 37.1041 15.0433 36.7613C18.8458 31.2778 25.3563 25.4516 31.4634 31.4492C36.0149 35.9045 37.8586 42.6447 38.9532 48.6423C41.2002 61.3229 40.9698 75.9457 34.3441 87.3126Z"
            fill={_theme}
          />
          <path
            d="M98.1814 144.319C94.7245 136.322 88.3869 128.211 79.0534 127.411C72.2549 126.783 66.4935 129.982 61.8843 134.494C59.1189 133.866 56.1805 134.951 55.5468 138.493C51.8018 160.084 48.345 183.789 56.4686 204.866C59.695 213.206 65.975 219.432 75.5389 218.632C85.2758 217.832 92.5928 209.664 96.8562 201.553C105.441 184.874 105.556 161.455 98.1814 144.319ZM85.7943 197.44C83.144 202.01 76.2303 210.807 70.296 205.723C65.6293 201.724 64.5922 191.614 63.9008 186.016C62.3453 173.279 63.728 160.37 65.8021 147.746C66.0326 147.518 66.263 147.232 66.4935 146.889C70.296 141.406 76.8065 135.58 82.9136 141.577C87.4651 146.032 89.3088 152.773 90.4034 158.77C92.6504 171.451 92.4199 186.074 85.7943 197.44Z"
            fill={_theme}
          />
          <path
            d="M110.223 14.8277C99.5064 7.74486 87.5802 12.1431 82.0492 21.2252C80.897 21.8535 80.0327 22.9959 79.6294 24.6524C75.2508 43.5591 72.1396 63.7796 72.4277 83.2004C72.5429 90.9687 74.5018 98.9084 80.7241 104.106C86.9465 109.304 95.2429 109.247 102.041 105.192C116.791 96.338 120.536 77.9454 121.227 62.1802C121.861 46.5294 125.548 24.938 110.223 14.8277ZM104.576 85.371C102.157 89.9977 95.531 99.4225 89.1934 96.0524C82.3949 92.4539 84.0081 81.4868 84.3538 75.3179C85.1028 62.6943 86.4855 50.1279 88.7901 37.6758C90 36.4191 90.749 34.6484 90.4609 32.535C89.4239 25.6805 96.9137 20.8825 102.848 23.9098C109.877 27.4513 110.107 35.4481 109.992 42.2454C109.762 56.2398 111.202 72.519 104.576 85.371Z"
            fill={_theme}
          />
          <path
            d="M164.726 12.5992C158.504 22.3096 152.8 32.3057 147.615 42.6444C144.331 49.2703 154.356 55.1537 157.697 48.4706C158.734 46.4143 159.771 44.4151 160.808 42.4159C157.697 61.2084 153.376 79.8866 153.376 99.079C153.376 106.505 165.014 106.505 165.014 99.079C165.072 70.8617 174.348 43.6725 175.558 15.5695C175.903 9.51476 167.722 8.02964 164.726 12.5992Z"
            fill={_theme}
          />
          <path
            d="M24.607 128.327C18.327 138.037 12.6232 148.033 7.49551 158.372C4.2115 164.998 14.2364 170.881 17.578 164.198C18.6151 162.142 19.6521 160.142 20.6892 158.143C17.578 176.936 13.2569 195.614 13.2569 214.806C13.2569 222.232 24.895 222.232 24.895 214.806C24.9527 186.589 34.2286 159.4 35.4385 131.297C35.7842 125.242 27.6029 123.7 24.607 128.327Z"
            fill={_theme}
          />
          <path
            d="M219.112 5.63117C218.593 -0.252191 211.68 -2.02291 208.223 2.71805C199.984 14.0849 194.28 26.8798 191.284 40.5315C189.729 47.7857 200.963 50.8702 202.519 43.616C203.729 38.0182 205.457 32.6489 207.589 27.5081C206.321 51.9555 200.848 76.2315 200.733 100.793C200.675 108.219 212.371 108.219 212.371 100.793C212.601 68.9201 221.762 37.6184 219.112 5.63117Z"
            fill={_theme}
          />
          <path
            d="M266.185 134.437C265.667 128.554 258.753 126.783 255.296 131.524C247.057 142.891 241.353 155.686 238.357 169.338C236.802 176.592 248.037 179.676 249.592 172.422C250.802 166.824 252.53 161.455 254.662 156.314C253.395 180.762 247.921 205.038 247.806 229.599C247.748 237.025 259.444 237.025 259.444 229.599C259.675 197.669 268.893 166.367 266.185 134.437Z"
            fill={_theme}
          />
          <path
            d="M281.797 14.6558C277.592 8.20128 269.641 3.91727 261.805 5.91648C253.566 8.02992 248.784 15.6269 245.5 22.824C245.04 23.8522 244.867 24.8232 244.924 25.7371C238.068 43.1587 233.978 61.4371 232.768 80.1154C232.307 87.541 230.463 98.5651 235.072 105.077C239.566 111.36 248.612 111.531 255.295 109.189C272.464 103.249 277.592 84.0567 280.991 68.2915C283.007 59.0381 284.448 49.6704 285.427 40.2456C286.291 31.6205 286.752 22.1957 281.797 14.6558ZM274.02 37.9608C273.271 46.7573 271.888 55.4967 270.044 64.1218C268.431 71.8901 266.76 80.1725 263.073 87.2554C260.48 92.1677 253.566 100.907 246.71 99.0221C241.41 97.5941 244.406 79.944 244.809 75.5458C246.365 58.9239 250.628 42.6447 256.85 27.108C257.139 26.3654 257.311 25.68 257.254 24.9946C259.616 20.3107 263.303 15.1128 268.604 17.6832C275.69 21.1104 274.538 31.7347 274.02 37.9608Z"
            fill={_theme}
          />
          <path
            d="M221.706 136.72C217.5 130.266 209.549 125.982 201.713 127.981C193.475 130.094 188.693 137.691 185.409 144.888C184.948 145.917 184.775 146.888 184.832 147.802C177.976 165.223 173.886 183.502 172.676 202.18C172.215 209.605 170.371 220.63 174.98 227.141C179.474 233.424 188.52 233.596 195.203 231.254C212.372 225.313 217.5 206.121 220.899 190.356C222.916 181.103 224.356 171.735 225.335 162.31C226.2 153.742 226.661 144.317 221.706 136.72ZM213.928 160.025C213.179 168.822 211.796 177.561 209.952 186.186C208.339 193.955 206.668 202.237 202.981 209.32C200.388 214.232 193.475 222.972 186.619 221.087C181.318 219.659 184.314 202.008 184.717 197.61C186.273 180.988 190.536 164.709 196.759 149.172C197.047 148.43 197.22 147.744 197.162 147.059C199.524 142.375 203.211 137.177 208.512 139.748C215.599 143.175 214.446 153.799 213.928 160.025Z"
            fill={_theme}
          />
          <path
            d="M153.204 128.496C148.076 122.727 139.607 119.642 132.174 122.727C124.339 125.982 120.709 134.208 118.52 141.805C118.232 142.89 118.174 143.861 118.405 144.775C114.199 162.996 112.758 181.675 114.314 200.353C114.89 207.778 114.717 218.917 120.248 224.686C125.606 230.227 134.594 229.141 140.874 225.885C157.006 217.546 159.253 197.839 160.348 181.732C160.982 172.307 161.039 162.825 160.636 153.343C160.175 144.661 159.253 135.293 153.204 128.496ZM148.883 152.658C149.401 161.454 149.343 170.308 148.767 179.104C148.249 186.987 147.903 195.44 145.253 202.98C143.409 208.235 137.821 217.832 130.792 216.975C125.318 216.289 125.722 198.411 125.491 194.012C124.569 177.333 126.413 160.597 130.388 144.375C130.561 143.575 130.619 142.89 130.504 142.205C132.174 137.235 135.055 131.58 140.701 133.351C148.076 135.807 148.537 146.431 148.883 152.658Z"
            fill={_theme}
          />
          <path
            d="M195.953 287.576C192.496 279.579 186.158 271.468 176.825 270.669C170.026 270.04 164.265 273.239 159.656 277.752C156.89 277.123 153.952 278.208 153.318 281.75C149.573 303.341 146.116 327.046 154.24 348.123C157.466 356.463 163.746 362.689 173.31 361.889C183.047 361.09 190.364 352.921 194.628 344.81C203.27 328.131 203.327 304.712 195.953 287.576ZM183.566 340.698C180.916 345.267 174.002 354.064 168.068 348.98C163.401 344.982 162.364 334.872 161.672 329.274C160.117 316.536 161.499 303.627 163.574 291.003C163.804 290.775 164.035 290.489 164.265 290.147C168.068 284.663 174.578 278.837 180.685 284.834C185.237 289.29 187.08 296.03 188.175 302.028C190.422 314.708 190.191 329.331 183.566 340.698Z"
            fill={_theme}
          />
          <path
            d="M98.1814 397.703C94.7245 389.706 88.3869 381.595 79.0534 380.795C72.2549 380.167 66.4935 383.366 61.8843 387.878C59.1189 387.25 56.1805 388.335 55.5468 391.876C51.8018 413.468 48.345 437.173 56.4686 458.25C59.695 466.589 65.975 472.815 75.5389 472.016C85.2758 471.216 92.5928 463.048 96.8562 454.937C105.441 438.258 105.556 414.839 98.1814 397.703ZM85.7943 450.824C83.144 455.394 76.2303 464.19 70.296 459.107C65.6293 455.108 64.5922 444.998 63.9008 439.4C62.3453 426.662 63.728 413.753 65.8021 401.13C66.0326 400.901 66.263 400.616 66.4935 400.273C70.296 394.79 76.8065 388.963 82.9136 394.961C87.4651 399.416 89.3088 406.156 90.4034 412.154C92.6504 424.835 92.4199 439.457 85.7943 450.824Z"
            fill={_theme}
          />
          <path
            d="M259.443 268.213C248.727 261.13 236.801 265.528 231.27 274.61C230.118 275.238 229.253 276.381 228.85 278.037C224.471 296.944 221.36 317.164 221.648 336.585C221.764 344.353 223.722 352.293 229.945 357.491C236.167 362.689 244.464 362.632 251.262 358.576C266.011 349.723 269.756 331.33 270.448 315.565C271.139 299.914 274.826 278.323 259.443 268.213ZM253.797 338.756C251.377 343.382 244.752 352.807 238.414 349.437C231.616 345.839 233.229 334.872 233.575 328.703C234.324 316.079 235.706 303.513 238.011 291.061C239.221 289.804 239.97 288.033 239.682 285.92C238.645 279.065 246.134 274.267 252.069 277.295C259.098 280.836 259.328 288.833 259.213 295.63C259.04 309.625 260.48 325.904 253.797 338.756Z"
            fill={_theme}
          />
          <path
            d="M125.491 265.985C119.211 275.696 113.507 285.692 108.379 296.031C105.095 302.657 115.12 308.54 118.462 301.857C119.499 299.801 120.536 297.801 121.573 295.802C118.462 314.595 114.141 333.273 114.141 352.465C114.141 359.891 125.779 359.891 125.779 352.465C125.836 324.248 135.112 297.059 136.322 268.956C136.668 262.901 128.487 261.416 125.491 265.985Z"
            fill={_theme}
          />
          <path
            d="M24.607 381.709C18.327 391.42 12.6232 401.416 7.49551 411.755C4.2115 418.381 14.2364 424.264 17.578 417.581C18.6151 415.525 19.6521 413.525 20.6892 411.526C17.578 430.319 13.2569 448.997 13.2569 468.189C13.2569 475.615 24.895 475.615 24.895 468.189C24.9527 439.972 34.2286 412.783 35.4385 384.68C35.7842 378.625 27.6029 377.083 24.607 381.709Z"
            fill={_theme}
          />
          <path
            d="M28.0064 259.016C27.4878 253.133 20.5741 251.362 17.1173 256.103C8.87842 267.47 3.1746 280.265 0.178652 293.917C-1.37693 301.171 9.85786 304.255 11.4134 297.001C12.6233 291.403 14.3518 286.034 16.4835 280.893C15.216 305.341 9.74263 329.617 9.6274 354.178C9.56979 361.604 21.2655 361.604 21.2655 354.178C21.496 322.305 30.6566 291.004 28.0064 259.016Z"
            fill={_theme}
          />
          <path
            d="M266.185 387.764C265.667 381.88 258.753 380.109 255.296 384.85C247.057 396.217 241.353 409.012 238.357 422.664C236.802 429.918 248.037 433.003 249.592 425.748C250.802 420.151 252.53 414.781 254.662 409.64C253.395 434.088 247.921 458.364 247.806 482.925C247.748 490.351 259.444 490.351 259.444 482.925C259.675 451.052 268.893 419.751 266.185 387.764Z"
            fill={_theme}
          />
          <path
            d="M90.6344 267.984C86.4285 261.529 78.4778 257.245 70.6422 259.245C62.4033 261.358 57.6214 268.955 54.3373 276.152C53.8764 277.18 53.7036 278.151 53.7612 279.065C46.9051 296.487 42.8145 314.765 41.6046 333.443C41.1437 340.869 39.3 351.893 43.9091 358.405C48.4031 364.688 57.4485 364.86 64.1318 362.518C81.3009 356.577 86.4285 337.385 89.8278 321.62C91.8443 312.366 93.2846 302.999 94.2641 293.574C95.1859 285.006 95.6468 275.581 90.6344 267.984ZM82.8564 291.346C82.1075 300.143 80.7247 308.882 78.8811 317.507C77.2679 325.275 75.597 333.558 71.9097 340.641C69.3171 345.553 62.4034 354.292 55.5472 352.407C50.2467 350.979 53.2427 333.329 53.646 328.931C55.2016 312.309 59.465 296.03 65.6874 280.493C65.9754 279.751 66.1483 279.065 66.0907 278.38C68.4529 273.696 72.1402 268.498 77.4407 271.068C84.5273 274.496 83.4326 285.12 82.8564 291.346Z"
            fill={_theme}
          />
          <path
            d="M221.706 390.048C217.5 383.594 209.549 379.31 201.713 381.309C193.475 383.422 188.693 391.019 185.409 398.217C184.948 399.245 184.775 400.216 184.832 401.13C177.976 418.551 173.886 436.83 172.676 455.508C172.215 462.934 170.371 473.958 174.98 480.469C179.474 486.753 188.52 486.924 195.203 484.582C212.372 478.642 217.5 459.449 220.899 443.684C222.916 434.431 224.356 425.063 225.335 415.638C226.2 407.07 226.661 397.645 221.706 390.048ZM213.928 413.41C213.179 422.207 211.796 430.946 209.952 439.571C208.339 447.34 206.668 455.622 202.981 462.705C200.388 467.617 193.475 476.357 186.619 474.472C181.318 473.044 184.314 455.394 184.717 450.995C186.273 434.374 190.536 418.094 196.759 402.558C197.047 401.815 197.22 401.13 197.162 400.444C199.524 395.76 203.211 390.562 208.512 393.133C215.599 396.56 214.446 407.184 213.928 413.41Z"
            fill={_theme}
          />
          <path
            d="M153.204 381.881C148.076 376.111 139.607 373.027 132.174 376.111C124.339 379.367 120.709 387.593 118.52 395.19C118.232 396.275 118.174 397.246 118.405 398.16C114.199 416.381 112.758 435.059 114.314 453.738C114.89 461.163 114.717 472.302 120.248 478.071C125.606 483.611 134.594 482.526 140.874 479.27C157.006 470.931 159.253 451.224 160.348 435.116C160.982 425.692 161.039 416.21 160.636 406.728C160.175 398.046 159.253 388.678 153.204 381.881ZM148.883 406.042C149.401 414.839 149.343 423.692 148.767 432.489C148.249 440.371 147.903 448.825 145.253 456.365C143.409 461.62 137.821 471.216 130.792 470.359C125.318 469.674 125.722 451.795 125.491 447.397C124.569 430.718 126.413 413.982 130.388 397.76C130.561 396.96 130.619 396.275 130.504 395.589C132.174 390.62 135.055 384.965 140.701 386.736C148.076 389.192 148.537 399.816 148.883 406.042Z"
            fill={_theme}
          />
        </svg>
      </div>
    </div>
  );
}

export default HeaderBackground;
