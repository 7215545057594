import { useState, useRef, useEffect } from "react";
import Fade from "react-reveal/Fade";
import { motion } from "framer-motion";
import { projects as prs } from "helpers/projects";
import { UilTimes } from "@iconscout/react-unicons";
import "scss/css/projects/projectStyle.css";

// Importing Layout
import NavBarr from "components/layout/NavBarr";
import Footer from "components/layout/footer/Footer";

// Importing components
import ProjectCards from "components/cards/ProjectCards";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";

// importing Assets
import img from "assets/images/illustrations/image5.webp";
import WavesSvg from "assets/vectors/projects_roundy_vector_down.svg";

export default function Projects() {
  const projectsRef = useRef(null);
  const [filterMode, setFilterMode] = useState("all");
  const [fullscreenImg, setFullscreenImg] = useState(false);
  const [fullscreenImgSrc, setFullscreenImgSrc] = useState("");
  const [overflowHidden, setOverflowHidden] = useState("");
  const [hrefLink, setHrefLink] = useState("");
  const [projects, setProjects] = useState(prs);
  const [refOffset, setRefOffset] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setProjects(
      prs.filter((item, index) => {
        if (filterMode == "all") {
          return item;
        }
        return item.tag == filterMode;
      })
    );
  }, [filterMode]);

  document.body.style.overflow = overflowHidden;

  const handleScrollIntoSection = () => {
    if (projectsRef.current) {
      if (refOffset) {
        if (isMobile) {
          const top = projectsRef.current.offsetTop + 0;
          window.scroll({
            top,
            behavior: "smooth",
          });
        } else {
          const top = projectsRef.current.offsetTop + 95;
          window.scroll({
            top,
            behavior: "smooth",
          });
        }
      } else if (!refOffset) {
        const top = projectsRef.current.offsetTop - 50;
        window.scroll({
          top,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <NavBarr contrast={true} />
      <header className="projects">
        <Fade top cascade>
          <div className="projects__container container">
            <div className="projects__content">
              <h1>Ku ka punë ka projekte!</h1>
              <p>
                Gjithmonë e kemi besu që mënyra ma e mirë më prezentu punën tonë
                është përmes projekteve që i kanë zhvillu nxënësit tonë. Me
                shumë kënaqësi, në këtë faqe, i prezentojmë punën e nxënësve
                tonë nëpër module të ndryshme
              </p>
              <button
                className="btn projects__btn m-btn"
                onClick={() => {
                  setRefOffset(false);
                  handleScrollIntoSection();
                }}
                aria-label="See Projects"
              >
                Shiko projekte
              </button>
            </div>
            <div className="projects__img">
              <img src={img} alt="Projects Header Image" />
            </div>
          </div>
        </Fade>
      </header>

      <div className="projects__waves-svg">
        <img src={WavesSvg} alt="Section Splitter" />
      </div>

      <section className="projects__cards-section" ref={projectsRef}>
        <div className="projects__cards-container">
          <div className="projects__cards-title">
            <h1>Projektet</h1>
            <ul>
              <div>
                <li
                  onClick={() => {
                    setFilterMode("all");
                    setRefOffset(true);
                    handleScrollIntoSection();
                  }}
                  className={filterMode == "all" ? "active" : ""}
                >
                  All
                </li>
              </div>
              <div>
                <li
                  onClick={() => {
                    setFilterMode("latest");
                    setRefOffset(true);
                    handleScrollIntoSection();
                  }}
                  className={filterMode == "latest" ? "active" : ""}
                >
                  Latest
                </li>
              </div>
              <div>
                <li
                  onClick={() => {
                    setFilterMode("trending");
                    setRefOffset(true);
                    handleScrollIntoSection();
                  }}
                  className={filterMode == "trending" ? "active" : ""}
                >
                  Trending
                </li>
              </div>
              <div>
                <li
                  onClick={() => {
                    setFilterMode("popular");
                    setRefOffset(true);
                    handleScrollIntoSection();
                  }}
                  className={filterMode == "popular" ? "active" : ""}
                >
                  Popular
                </li>
              </div>
            </ul>
          </div>
          <div className="projects__cards-wrapper">
            {projects.map((i, index) => {
              const href = i.href === "" ? true : i.href;

              return (
                <ProjectCards
                  key={i.id}
                  imageSrc={i.image}
                  title={i.title}
                  description={i.description}
                  module={i.module}
                  tag={i.tag}
                  href={href}
                  action={() => {
                    setFullscreenImgSrc(i.image);
                    setFullscreenImg(true);
                    setOverflowHidden("hidden");
                    setHrefLink(href);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className={`projects__fullscreen-view ${fullscreenImg}`}>
          <div className="projects__fullscreen-view-container">
            <div>
              <a href={hrefLink} target="_blank">
                <img
                  loading="lazy"
                  src={fullscreenImgSrc}
                  alt="Project Fullscreen image"
                />
              </a>
            </div>
            <button
              onClick={() => {
                setFullscreenImg(false);
                setOverflowHidden("");
              }}
              aria-label="Close Fullscreen"
            >
              <UilTimes size="40" />
            </button>
          </div>
        </div>
      </section>
      <BottomFixedButtons />
      <Footer variant={"white"} />
    </motion.div>
  );
}
