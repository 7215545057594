import { useEffect, useState } from "react";
import HeaderBackground from "pages/planProgramUnregular/components/HeaderBackground";

// Importing assets
import blueTitle from "assets/images/programs_pages/vectors/blueTitle.png";
import greenTitle from "assets/images/programs_pages/vectors/greenTitle.png";
import yellowTitle from "assets/images/programs_pages/vectors/yellowTitle.png";
import darkBlueTitle from "assets/images/programs_pages/vectors/darkBlueTitle.png";

const colorTheme = {
  blue: {
    span: "#0C1B2B",
    bg: "#0C1B2B",
    contrast: "#ffb000",
    titleBg: yellowTitle,
  },
  // red: { span: "#F15B5B", bg: "#0C1B2B", contrast: "" ,titleBg: blueTitle,},
  purple: {
    span: "#9A63AA",
    bg: "#0C1B2B",
    contrast: "#8DC63F",
    titleBg: greenTitle,
  },
  yellow: {
    span: "#F2B400",
    bg: "#6A730F",
    contrast: "#fff",
    titleBg: darkBlueTitle,
  },
};

function PlanProgramHeader(props) {
  const { title, subtitle, theme } = props;
  const spanBg = colorTheme[theme].span;
  const headerBg = colorTheme[theme].bg;
  const contrastColor = colorTheme[theme].contrast;
  const titleBg = colorTheme[theme].titleBg;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <header
        className="programs__content-header"
        style={{ backgroundColor: headerBg }}
      >
        <HeaderBackground color={theme} />
        <div className="programs__content-container-header container">
          <div className="programs__content-text-header">
            <h1>
              {title} <br />{" "}
              <span
                style={{
                  content: "",
                  backgroundColor: contrastColor,
                  width: "90px",
                  borderRadius: "35px",
                  height: "3px",
                }}
              />
            </h1>
            <span
              className="programs__content-text-subtitle"
              style={{
                backgroundColor: `${
                  isMobile && contrastColor == "#fff"
                    ? "#152E4A"
                    : "" || isMobile
                    ? contrastColor
                    : ""
                }`,
                maxWidth: `${isMobile ? "max-content" : ""}`,
              }}
            >
              <img src={titleBg} alt="Programs Header image" />
              <h1>{subtitle}</h1>
            </span>
            {/* <p>{desc}</p>
            <a
              onClick={action}
              className={`btn`}
              style={{
                backgroundColor: contrastColor,
                color: contrastColor == "#fff" ? "#000" : "",
              }}
            >
              Më Shumë
            </a> */}
          </div>
          {/* <div className="programs__content-image">
            <HeaderCharacter color={contrastColor} />
          </div> */}
        </div>
        <span
          style={{
            content: '""',
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            left: "0",
            width: "100%",
            height: "100%",
            zIndex: "0",
            opacity: "1",
            backgroundColor: spanBg,
          }}
        />
      </header>
    </>
  );
}

export default PlanProgramHeader;
