// Material UI
import TextField from "@material-ui/core/TextField";

function DateInput(props) {
  const {
    name,
    value,
    onChange,
    className,
    label,
    id,
    required = true,
    disabled = false,
    min,
    max,
    placeHolder,
    title,
  } = props;

  return (
    <div className="register__input-date">
      <label htmlFor={id}>
        {label}
        <span>{required ? "*" : ""}</span>
      </label>
      <TextField
        id={id}
        name={name}
        type="date"
        // defaultValue="2017-05-24"
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        placeholder={placeHolder}
        title={title}
        minDate={min} // set minimum date
        maxDate={max} // set maximum date
      />
    </div>
  );
}

export default DateInput;
