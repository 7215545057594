// Importing Assets
import project1 from "assets/images/projects/ColorBallCollecting.webp";
import project2 from "assets/images/projects/superMario.webp";
import project3 from "assets/images/projects/raceTo22.webp";
import project4 from "assets/images/projects/kahootGame.webp";
import project5 from "assets/images/projects/paintGame.webp";
import project6 from "assets/images/projects/fupicatGame.webp";
import project7 from "assets/images/projects/fightingGame.webp";
import project8 from "assets/images/projects/theFloorIsLava.webp";
import project9 from "assets/images/projects/jetpackMania.webp";
import project10 from "assets/images/projects/skribleGame.webp";
import project11 from "assets/images/projects/brickBreaker.webp";
import project12 from "assets/images/projects/packmanGame.webp";
import project13 from "assets/images/projects/pianoGame.webp";
import project14 from "assets/images/projects/oxygenGame.webp";
import project15 from "assets/images/projects/guessTheColor.webp";
import project16 from "assets/images/projects/jcodersApp.webp";
import project17 from "assets/images/projects/rockPaperScissors.webp";
import project18 from "assets/images/projects/textColorGame.webp";
import project19 from "assets/images/projects/AleaFurniture.webp";
import project20 from "assets/images/projects/OzonePage.webp";
import project21 from "assets/images/projects/kalkulatorGame.webp";
import project22 from "assets/images/projects/pingPongGame.webp";
import project23 from "assets/images/projects/smartFlower.webp";
import project24 from "assets/images/projects/robot.webp";
import project25 from "assets/images/projects/smartBridge.webp";
import project26 from "assets/images/projects/smartCan.webp";
import project27 from "assets/images/projects/animations.webp";
import project28 from "assets/images/projects/webPage.webp";
import project29 from "assets/images/projects/arislsProducts.webp";
import project30 from "assets/images/projects/workingTable.webp";
import project31 from "assets/images/projects/meccaSA.webp";
import project32 from "assets/images/projects/mitrovica.webp";
import project33 from "assets/images/projects/minigame.webp";

export const projects = [
  {
    id: 1,
    tag: "popular",
    image: project1,
    title: "Color Ball Collecting",
    description:
      "Nga: Nxënesit në modulin Kodu Game Lab gjatë akademisë së rregullt",
    module: "Kodu Game Lab",
    href: "",
  },
  {
    id: 2,
    tag: "all",
    image: project2,
    title: "Super Mario",
    description:
      "Nga: Nxënesit në modulin Kodu Game Lab gjatë akademisë së rregullt",
    module: "Kodu Game Lab",
    href: "",
  },
  {
    id: 3,
    tag: "all",
    image: project3,
    title: "Race to 22",
    description:
      "Nga: Nxënesit në modulin Kodu Game Lab gjatë akademisë së rregullt",
    module: "Kodu Game Lab",
    href: "",
  },
  {
    id: 4,
    tag: "all",
    image: project4,
    title: "Kahoot Game",
    description:
      "Nga: Nxënesit në modulin Kodu Game Lab gjatë akademisë së rregullt",
    module: "Kodu Game Lab",
    href: "",
  },
  {
    id: 5,
    tag: "all",
    image: project5,
    title: "Paint Game",
    description: "Nga: Nxënesit në modulin Scratch gjatë akademisë së rregullt",
    module: "Scratch",
    href: "",
  },
  {
    id: 6,
    tag: "all",
    image: project6,
    title: "Fucipat",
    description: "Nga: Nxënesit në modulin Scratch gjatë akademisë së rregullt",
    module: "Scratch",
    href: "",
  },
  {
    id: 7,
    tag: "popular",
    image: project7,
    title: "Fighting Game",
    description: "Nga: Nxënesit në modulin Scratch gjatë akademisë së rregullt",
    module: "Scratch",
    href: "",
  },
  {
    id: 8,
    tag: "latest",
    image: project8,
    title: "The floor is lava",
    description: "Nga: Nxënesit në modulin Scratch gjatë akademisë së rregullt",
    module: "Scratch",
    href: "",
  },
  {
    id: 9,
    tag: "popular",
    image: project9,
    title: "Jetpack",
    description: "Nga: Nxënesit në modulin Scratch gjatë akademisë së rregullt",
    module: "Scratch",
    href: "",
  },
  {
    id: 10,
    tag: "all",
    image: project10,
    title: "Skribbl game",
    description: "Nga: Nxënesit në modulin Tynker gjatë akademisë së rregullt",
    module: "Tynker",
    href: "",
  },
  {
    id: 11,
    tag: "latest",
    image: project11,
    title: "Brick Breaker",
    description: "Nga: Nxënesit në modulin Tynker gjatë akademisë së rregullt",
    module: "Tynker",
    href: "",
  },
  {
    id: 12,
    tag: "trending",
    image: project12,
    title: "Packman",
    description: "Nga: Nxënesit në modulin Tynker gjatë akademisë së rregullt",
    module: "Tynker",
    href: "",
  },
  {
    id: 13,
    tag: "trending",
    image: project13,
    title: "Piano Game",
    description: "Nga: Nxënesit në modulin Tynker gjatë akademisë së rregullt",
    module: "Tynker",
    href: "",
  },
  {
    id: 14,
    tag: "all",
    image: project14,
    title: "Oxygen Game",
    description:
      "Nga: Nxënesit në modulin Mobile apps 1 gjatë akademisë së rregullt",
    module: "Mobile apps 1",
    href: "",
  },
  {
    id: 15,
    tag: "all",
    image: project15,
    title: "Guess the color",
    description:
      "Nga: Nxënesit në modulin Mobile apps 1 gjatë akademisë së rregullt",
    module: "Mobile apps 1",
    href: "",
  },
  {
    id: 16,
    tag: "all",
    image: project16,
    title: "jCoders App",
    description:
      "Nga: Nxënesit në modulin Mobile apps 1 gjatë akademisë së rregullt",
    module: "Mobile apps 1",
    href: "",
  },
  {
    id: 17,
    tag: "all",
    image: project17,
    title: "Text Color Game",
    description:
      "Nga: Nxënesit në modulin Mobile apps 1 gjatë akademisë së rregullt",
    module: "Mobile apps 1",
    href: "",
  },
  {
    id: 18,
    tag: "all",
    image: project18,
    title: "Rock Paper Scissors",
    description:
      "Nga: Nxënesit në modulin Mobile apps 1 gjatë akademisë së rregullt",
    module: "Mobile apps 1",
    href: "",
  },
  {
    id: 19,
    tag: "popular",
    image: project19,
    title: "Alea Furniture",
    description:
      "Nga: Nxënesit në modulin UI Design gjatë akademisë së rregullt",
    module: "UI Design",
    href: "https://www.figma.com/file/4uDUkPr67dPIgDJi57IbMK/Alea?type=design&node-id=0%3A1&t=0tk09IcQdx16dsq6-1",
  },
  {
    id: 20,
    tag: "trending",
    image: project20,
    title: "Ozone Page",
    description:
      "Nga: Nxënesit në modulin UI Design gjatë akademisë së rregullt",
    module: "UI Design",
    href: "https://www.figma.com/file/VeTC1toNLTACGWCvRjQYiC/OZONE-PAGE?type=design&t=0tk09IcQdx16dsq6-1",
  },
  {
    id: 21,
    tag: "latest",
    image: project21,
    title: "Calculator",
    description:
      "Nga: Nxënesit në modulin Mobile apps 2 gjatë akademisë së rregullt",
    module: "Mobile apps 2",
    href: "",
  },
  {
    id: 22,
    tag: "latest",
    image: project22,
    title: "Pong Game",
    description:
      "Nga: Nxënesit në modulin Mobile apps 2 gjatë akademisë së rregullt",
    module: "Mobile apps 2",
    href: "",
  },
  {
    id: 23,
    tag: "latest",
    image: project23,
    title: "Smart Flower",
    description:
      "Nga: Nxënesit në modulin Robotike gjatë akademisë së rregullt",
    module: "Robotike",
    href: "",
  },
  {
    id: 24,
    tag: "popular",
    image: project24,
    title: "Robot",
    description:
      "Nga: Nxënesit në modulin Robotike gjatë akademisë së rregullt",
    module: "Robotike",
    href: "",
  },
  {
    id: 25,
    tag: "trending",
    image: project25,
    title: "Automatic Bridge",
    description:
      "Nga: Nxënesit në modulin Robotike gjatë akademisë së rregullt",
    module: "Robotike",
    href: "",
  },
  {
    id: 26,
    tag: "trending",
    image: project26,
    title: "Automatic trash-can",
    description:
      "Nga: Nxënesit në modulin Robotike gjatë akademisë së rregullt",
    module: "Robotike",
    href: "",
  },
  {
    id: 27,
    tag: "latest",
    image: project27,
    title: "Css Animation",
    description:
      "Nga: Suela Preniqi në modulin HTML & CSS gjatë akademisë së rregullt",
    module: "HTML & CSS",
    href: "https://animations-jcoders.netlify.app/",
  },
  {
    id: 28,
    tag: "latest",
    image: project28,
    title: "Blog Page",
    description:
      "Nga: Deniza Loshi në modulin HTML & CSS gjatë akademisë së rregullt",
    module: "HTML & CSS",
    href: "https://denizaloshi.netlify.app/",
  },
  {
    id: 29,
    tag: "latest",
    image: project29,
    title: "E-commerce",
    description:
      "Nga: Aris në modulin Web Fundamentals gjatë akademisë së rregullt",
    module: "HTML & CSS",
    href: "https://jcoders-aris.netlify.app/",
  },
  {
    id: 30,
    tag: "latest",
    image: project30,
    title: "Working Table",
    description:
      "Nga: Erion Mustafa në modulin 3D Modelim gjatë akademisë së rregullt",
    module: "3D Modelim",
    href: "https://www.tinkercad.com/things/aO0yoyw4Ccu-projekti-final",
  },
  {
    id: 31,
    tag: "latest",
    image: project31,
    title: "Mecca",
    description:
      "Nga: Ruvejda Maxhuni në modulin 3D Modelim gjatë akademisë së rregullt",
    module: "3D Modelim",
    href: "https://www.tinkercad.com/things/gtDnKqK4z6H-mecca-in-saudi-arabia",
  },
  {
    id: 32,
    tag: "all",
    image: project32,
    title: "Mitrovica",
    description:
      "Nga: Fortesa Peci në modulin 3D Modelim gjatë akademisë së rregullt",
    module: "3D Modelim",
    href: "https://www.tinkercad.com/things/2RyrsEttzn5-funky-duup-lappi",
  },
  {
    id: 33,
    tag: "popular",
    image: project33,
    title: "Minigames",
    description:
      "Nga: Drin Vllasi në modulin Web Fundamentals në javascript gjatë akademisë së rregullt",
    module: "Web Fundamentals",
    href: "https://drinvllasi.netlify.app/",
  },
  // Copy project item
  // {
  //   id: 0,
  //   tag: "all",
  //   image: project2,
  //   title: "Viti 2023",
  //   description: "Nga: Nxënesit në modulin  gjatë akademisë së rregullt",
  //   module: "",
  //   href:"",
  // },
];
