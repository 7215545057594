import Button from "components/buttons/Buttons";
import { planProgramsCards } from "helpers/planProgramsCards";
import { useState } from "react";

// Importing bg
import binaryBgPurple from "assets/images/illustrations/binary/purple.webp";
import binaryBgBlue from "assets/images/illustrations/binary/blue.webp";
import binaryBgLightBlue from "assets/images/illustrations/binary/lightBlue.webp";
import binaryBgYellow from "assets/images/illustrations/binary/yellow.webp";
import binaryBgRed from "assets/images/illustrations/binary/red.webp";

const binaryBg = {
  blue: binaryBgBlue,
  darkBlue: binaryBgBlue,
  purple: binaryBgPurple,
  lightBlue: binaryBgLightBlue,
  yellow: binaryBgYellow,
  red: binaryBgRed,
};

function HomePrograms(props) {
  const unRegularPlanProgram = planProgramsCards.filter((item) => {
    return item.type === 2;
  });

  const regularPlanProgram = planProgramsCards.filter((item) => {
    return item.type === 1;
  });
  return (
    <>
      {/* <div className="home__programs-wrapper">
        <div className="home__programs-title">
          <h1>Planprogramet Verore</h1>
        </div>
        <div className="home__programs-container">
          {unRegularPlanProgram.map((i, index) => {
            const bg = binaryBg[i.theme];

            return (
              <div
                key={index}
                className={`home__programs-card ${i.theme}-theme`}
              >
                <div className="home__programs-img">
                  <img loading="lazy" src={i.img} alt="PlanProgramImage" />
                </div>
                <div className="home__programs-content-card">
                  <div className="home__programs-content-titles">
                    <div className="home__programs-subtitle">
                      <h2>{i.age}</h2>
                    </div>
                    <span>
                      <h2 className="summer__program-title">{i.title}</h2>
                    </span>
                  </div>
                  <Button
                    className={["home__programs-btn"]}
                    RedirectLink={`/planPrograms/${i.redirect}`}
                    ButtonTitle="Më Shumë"
                    size={"sm"}
                    variant={"purple"}
                    ariaLabel={"Më Shumë"}
                  />
                </div>
                <div className="home__programs-bgImg">
                  <img src={bg} alt="Programs Card Image" />
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className="home__programs-wrapper">
        <div className="home__programs-title">
          <h1>Planprogramet</h1>
          {/* <h2>Plan Programet të thjeshta</h2> */}
        </div>
        <div className="home__programs-container">
          {regularPlanProgram.map((i, index) => {
            const bg = binaryBg[i.theme];
            return (
              <div
                key={index}
                className={`home__programs-card ${i.theme}-theme`}
              >
                <div className="home__programs-img">
                  <img loading="lazy" src={i.img} alt="PlanProgramImage" />
                </div>
                <div className="home__programs-content-card">
                  <div className="home__programs-content-titles">
                    <div className="home__programs-subtitle">
                      <h2>{i.age}</h2>
                    </div>
                    <span>
                      <h1>{i.title}</h1>
                      <h2>{i.subtitle}</h2>
                    </span>
                  </div>
                  <Button
                    className={["home__programs-btn"]}
                    RedirectLink={`/planPrograms/${i.redirect}`}
                    ButtonTitle="Më Shumë"
                    size={"sm"}
                    variant={"purple"}
                    ariaLabel={"Më Shumë"}
                  />
                </div>
                <div className="home__programs-bgImg">
                  <img src={bg} alt="Programs Card Image" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default HomePrograms;
