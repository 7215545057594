function ArticleSeparator(props) {
  const { desc } = props;
  return (
    <>
      <div className="article__separator-container container">
        <p>{desc}</p>
      </div>
    </>
  );
}

export default ArticleSeparator;
