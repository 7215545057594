import { useEffect, useState } from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { testimonials } from "helpers/Helpers";
import testimonialsImg from "assets/images/testimonials/testimonialsBg.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

function Testimonials() {
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth <= 992);
    }

    handleResize(); // set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <>
      <div className="home__testimonials-container container">
        <div className="home__testimonials-info-img">
          <img loading="lazy" src={testimonialsImg} alt="Testimonial Image" />
        </div>
        <div className="home__testimonials-info-container">
          <div className="home__testimonials-title">
            <h1>Të tjerët për jCoders</h1>
            <h2>Mesazhe nga klientet tanë</h2>
          </div>
          <div className="home__testimonials-content">
            {isMobileScreen === false && (
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                autoHeight={false}
              >
                {testimonials.map((i, index) => {
                  return (
                    <SwiperSlide
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div key={index} className="home__testimonials-cards">
                        <div className="home__testimonials-img">
                          <img
                            loading="lazy"
                            src={i.image}
                            alt="Testimonial Image"
                          />
                        </div>
                        <div className="home__testimonials-info">
                          <p>{i.desc}</p>
                          <h3>{i.title}</h3>
                          <h4>{i.role}</h4>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
            {isMobileScreen && (
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                autoHeight={true}
              >
                {testimonials.map((i, index) => {
                  return (
                    <SwiperSlide
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div key={index} className="home__testimonials-cards">
                        <div className="home__testimonials-img">
                          <img
                            loading="lazy"
                            src={i.image}
                            alt="Testimonial Image"
                          />
                        </div>
                        <div className="home__testimonials-info">
                          <p>{i.desc}</p>
                          <h3>{i.title}</h3>
                          <h4>{i.role}</h4>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
