/**
 * Project Started in .. 11/07/2022
 * Created & Developed by Adonis Xhemajli
 * GitHub: nxh01
 * Website: xhemajli.com
 */
import { BrowserRouter } from "react-router-dom";
import "./style.css";
import AnimatedRoutes from "./AnimatedRoutes";

function App() {
  return (
    <>
      <BrowserRouter>
        <AnimatedRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
