import Typewriter from "typewriter-effect";
import Fade from "react-reveal/Fade";
import Button from "components/buttons/Buttons";

function HeroTitles() {
  return (
    <>
      <Fade top cascade>
        <h1>
          <Typewriter
            options={{
              delay: 60,
              loop: true,
            }}
            onInit={(typewriter) => {
              typewriter

                .typeString("Edukim. Teknologji. Kreativitet.")
                .pauseFor(3500)
                .deleteAll()
                .typeString("Edukim")
                .pauseFor(900)
                .deleteAll()
                .typeString("Teknologji")
                .pauseFor(900)
                .deleteAll()
                .typeString("Kreativitet")
                .pauseFor(900)
                .deleteAll()
                .start();
            }}
          />
        </h1>
        <div>
          <p>
            Duke ndërtuar kulturën e inovacionit dhe kreativitetit nga mosha 8.
          </p>
        </div>
      </Fade>
      <Fade top>
        <Button
          className={["home__btn"]}
          RedirectLink="/register"
          ButtonTitle="Regjistrohu"
          size={"md"}
          ariaLabel={"Regjistrohu"}
        />
      </Fade>
    </>
  );
}

export default HeroTitles;
