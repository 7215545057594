import { useState, useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import Stem_icon from "assets/images/stem_icon.png";
import NavHeader from "./NavHeader";
import MobileNavbar from "./MobileNavbar";
import Logo from "assets/images/icon/jcoders_logo.png";
import LogoWhite from "assets/images/icon/jcoders_logoWhite.png";

export default function Navbar(props) {
  const { NavLinksClassName, hide, contrast = false } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const [collapse, setCollapse] = useState(true);
  const [scroll, setScroll] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobileScreen(window.innerWidth <= 992);
    }

    handleResize(); // set initial state
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleMenu = () => {
    setCollapse((prevState) => !prevState);
    if (collapse) {
      document.body.style = "overflow:hidden";
    } else {
      document.body.removeAttribute("style");
    }
  };

  // const RemoveMenuIcon = () => {
  //   if (collapse) {
  //     document.body.removeAttribute("style");
  //   } else {
  //   }
  // };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  const NavbarLogoClick = () => {
    if (location.pathname === "/") {
      window.scrollTo(0, 0);
    } else {
      navigate("/");
    }
    if (collapse === false) {
      setCollapse(true);
      document.body.removeAttribute("style");
    } else {
    }
  };

  return (
    <>
      {/* {(isMobileScreen && ( */}
      {isMobileScreen && (
        <MobileNavbar
          collapse={collapse}
          toggleMenu={toggleMenu}
          HiddenNavLinksClass={props.HiddenNavLinksClass}
          MobileNavLink1={props.MobileNavLink1}
          MobileNavLink3={props.MobileNavLink3}
          MobileNavLink2={props.MobileNavLink2}
          MobileNavLink4={props.MobileNavLink4}
          MobileNavLinkRegister={props.MobileNavLinkRegister}
          MobileNavLink5={props.MobileNavLink5}
          RegisterClassName={props.RegisterClassName}
          errorPage={props.errorPage}
          hide={hide}
        />
      )}
      {/* )) || ( */}
      <nav
        id="navbar"
        className={`navbar ${
          contrast && isMobileScreen === false ? "contrast-nav" : ""
        } ${scroll && collapse ? "sticky-nav" : ""}`}
      >
        {isMobileScreen === false && <NavHeader hide={hide} />}

        <div className="nav__container">
          {(scroll && collapse && (
            <div className="nav__icon">
              <img onClick={NavbarLogoClick} src={Logo} alt="jCoders Logo" />
            </div>
          )) ||
            (contrast && isMobileScreen === false && (
              <div className="nav__icon">
                <img onClick={NavbarLogoClick} src={Logo} alt="jCoders Logo" />
              </div>
            )) || (
              <div className="nav__icon">
                <img
                  onClick={NavbarLogoClick}
                  src={LogoWhite}
                  alt="jCoders Logo"
                />
              </div>
            )}

          <div
            onClick={toggleMenu}
            className={`nav__collapse ${!collapse ? "open-collapse" : ""}`}
          >
            <div
              className={`nav__collapse-btn ${
                scroll && collapse ? "blue-color" : "white-color"
              }`}
            ></div>
          </div>

          {isMobileScreen === false && (
            <div className={`nav__menu`}>
              <ul className={NavLinksClassName}>
                {hide === false && (
                  <>
                    <li>
                      <NavLink
                        activeclassname="active-link"
                        to="/"
                        aria-label="Ballina"
                        // style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {props.DesktopNavLink1}
                      </NavLink>
                    </li>
                    <li className={props.HiddenNavLinksClass}>
                      <NavLink
                        activeclassname="active-link"
                        to="/programs"
                        aria-label="Programet"
                        // style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {props.DesktopNavLink3}
                      </NavLink>
                    </li>
                    <li className={props.HiddenNavLinksClass}>
                      <NavLink
                        activeclassname="active-link"
                        to="/projects"
                        aria-label="Projektet"
                        // style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {props.DesktopNavLink4}
                      </NavLink>
                    </li>
                    <li className={props.HiddenNavLinksClass}>
                      <NavLink
                        activeclassname="active-link"
                        to="/about"
                        aria-label="Për ne"
                        // style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {props.DesktopNavLink2}
                      </NavLink>
                    </li>
                    <li className={props.HiddenNavLinksClass}>
                      <NavLink
                        activeclassname="active-link"
                        to="/media"
                        aria-label="Çka ka t’re"
                        // style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        Çka ka t’re
                      </NavLink>
                    </li>
                    <li
                      className={[
                        `register-display-none ${props.RegisterClassName}`,
                      ]}
                    >
                      <NavLink
                        activeclassname="active-link"
                        to="/register"
                        aria-label="Regjistrohu"
                        // style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {props.DesktopNavLinkRegister}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeclassname="active-link"
                        to="/contact"
                        aria-label="Kontakt"
                        // style={{ fontFamily: "Poppins, sans-serif" }}
                      >
                        {props.DesktopNavLink5}
                      </NavLink>
                    </li>
                  </>
                )}
                {isMobileScreen == false && (
                  <li
                    style={{
                      width: "100px",
                      height: "52px",
                      position: "relative",
                    }}
                  >
                    <div className="nav__stem-img">
                      <img src={Stem_icon} alt="STEM Certification" />
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
        </div>
      </nav>
      {/* )} */}
    </>
  );
}
