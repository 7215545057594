import { SwiperSlide } from "swiper/react";
import PaginationCarousel from "components/carousels/PaginationCarousel";

// Assets
import img1 from "assets/images/partners/partner1.webp";
import img2 from "assets/images/partners/partner2.png";
import img3 from "assets/images/partners/partner3.png";
import img4 from "assets/images/partners/partner4.webp";
import img5 from "assets/images/partners/partner5.png";
import img6 from "assets/images/partners/partner6.webp";
import img7 from "assets/images/partners/partner7.png";
import img8 from "assets/images/partners/partner8.png";
import img9 from "assets/images/partners/partner9.webp";

const partners = [
  { id: 1, img: img1 },
  { id: 2, img: img2 },
  { id: 3, img: img3 },
  { id: 4, img: img4 },
  { id: 5, img: img5 },
  { id: 6, img: img6 },
  { id: 7, img: img7 },
  { id: 8, img: img8 },
  { id: 9, img: img9 },
];

function Partners() {
  return (
    <>
      <div className="home__partners-container container">
        <div className="home__partners-title">
          <h1>Partnerët dhe shokët</h1>
          {/* <h2>Partnerat tanë</h2> */}
        </div>
        <div className="home__partners-content">
          <PaginationCarousel>
            {partners.map((i, index) => {
              return (
                <SwiperSlide>
                  <div key={index} className="home__partners-img">
                    <img loading="lazy" src={i.img} alt="Partners Icon" />
                  </div>
                </SwiperSlide>
              );
            })}
          </PaginationCarousel>
        </div>
      </div>
    </>
  );
}

export default Partners;
