function RootInput(props) {
  const {
    name,
    value,
    onChange,
    required = false,
    placeholder,
    className,
    id,
  } = props;
  return (
    <div className="register__input-container">
      <label htmlFor={id}>
        {placeholder}
        <span>{required ? "*" : ""}</span>
      </label>
      <textarea
        name={name}
        id={id}
        className={`register__input ${className}`}
        // placeholder={placeholder}
        cols="30"
        rows="10"
        onChange={onChange}
        value={value}
        required={required}
        maxLength={"500"}
      ></textarea>
    </div>
  );
}

export default RootInput;
