import { useEffect, useState } from "react";

const DropDownMultiple = (props) => {
  const { options, onSelect } = props;
  const [clickedItems, setClickedItems] = useState([]);

  function handleClick(clickedItem) {
    if (clickedItems.includes(clickedItem.name)) {
      setClickedItems((prevClickedItems) =>
        prevClickedItems.filter((item) => item !== clickedItem.name)
      );
    } else {
      setClickedItems((prevClickedItems) => [
        ...prevClickedItems,
        clickedItem.name,
      ]);
    }
  }

  useEffect(() => {
    onSelect(clickedItems);
  }, [clickedItems]);

  return (
    <>
      {options.map((i) => (
        <div className="register__cards-parent" name="schedule">
          <div
            key={i.id}
            className={[
              `register__cards ${
                clickedItems.includes(i.name) ? "clicked" : ""
              }`,
            ]}
            onClick={() => handleClick(i)}
          >
            <h1>{i.name}</h1>
          </div>
        </div>
      ))}
    </>
  );
};

export default DropDownMultiple;
