import mediaHeaderImg from "assets/images/illustrations/image2.webp";
import Fade from "react-reveal/Fade";

function MediaHeader() {
  return (
    <>
      <div className="media__container container">
        <Fade top cascade>
          <div className="media__info">
            <h1>Çka ka t’re</h1>
            <span></span>
            <p>Lajmet e fundit për aktivitetet e jCoders</p>
          </div>
          <div className="media__img">
            <img src={mediaHeaderImg} alt="Media header image" />
          </div>
        </Fade>
      </div>
    </>
  );
}

export default MediaHeader;
