import classNames from "classnames";

// import header svg
import blueRobot from "assets/images/programs_pages/blue_robot.png";
import redRobot from "assets/images/programs_pages/red_robot.png";
import purpleRobot from "assets/images/programs_pages/purple_robot.png";
import yellowRobot from "assets/images/programs_pages/yellow_robot.png";
import lightGreenRobot from "assets/images/programs_pages/light_green_robot.png";
import greenRobot from "assets/images/programs_pages/green_robot.png";

const robotSvg = {
  blue: { img: blueRobot },
  red: { img: redRobot },
  purple: { img: purpleRobot },
  yellow: { img: yellowRobot },
  green: { img: greenRobot },
};

function PlanProgramHeader(props) {
  const { title, subtitle, desc, action, theme } = props;
  const headerSVG = robotSvg[theme].img;

  return (
    <>
      <div className="programs__content-container-header container">
        <div className="programs__content-text-header">
          <h1>
            {title} <br />{" "}
            <span className={classNames(`${theme}-text`)}>{subtitle}</span>
          </h1>
          <p>{desc}</p>
          <a
            onClick={action}
            className={`btn ${theme}-btn`}
            aria-label="View More"
          >
            Më Shumë
          </a>
        </div>
        <div className="programs__content-image">
          <img src={headerSVG} alt="Header Image" />
        </div>
      </div>
    </>
  );
}

export default PlanProgramHeader;
