import image1 from "assets/images/illustrations/image5.webp";
import image2 from "assets/images/illustrations/image3.webp";
import image3 from "assets/images/illustrations/image1.webp";
import image4 from "assets/images/illustrations/image2.webp";

// Importing success image
import img1 from "assets/images/success/project1.webp";
import img2 from "assets/images/success/project2.webp";
import img3 from "assets/images/success/project3.webp";
import img4 from "assets/images/success/project4.webp";

export const success = [
  {
    id: 1,
    date: "18 - May 2013",
    title: "Akademia jCoders merr Certifikimin STEM nga Cognia",
    desc: "Akademia jCoders është i vetmi institucion në Ballkan i certifikuar për STEM (Shkencë, Teknologji, Inxhinieri dhe Matematikë) nga instituti amerikan COGNIA.",
    img: img1,
    illustration: image1,
    size: 250,
    color: "purple",
  },
  {
    id: 2,
    date: "5 - May 2015",
    title: "Akademia jCoders akreditohet sërisht në nivel shtetror.",
    desc: "Që nga viti 2016 Akademia jCoders është e akredituar nga Autoriteti Kombëtar i Kualifikemeve.",
    img: img2,
    illustration: image2,
    size: 350,
    color: "red",
  },
  {
    id: 3,
    date: "20 - Jun 2020",
    title:
      "Nxënësit e jCoders fitues të çmimit “Ylli në Ngritje” nga ICT Awards",
    desc: "Në vitin 2017 tre nxënës, Raesa Nikshiqi, Penda Smajljaj dhe Loran Pllana, nga Akademia jCoders fitojnë çmimin prestigjioz “Ylli në Ngritje” nga ICT Awards me tri projekte inovative të krijuara.",
    img: img3,
    illustration: image3,
    size: 400,
    color: "purple",
  },
  {
    id: 4,
    date: "6 - Feb 2021",
    title: "Akademia jCoders krijon dhe implementon programin Tech Heroes",
    desc: "Së bashku me IOM, Akademia jCoders ka dizajnuar një program i cili ka për qëllim t’i bashkojë të gjitha etnitë e Kosovës drejt një qëllimi të perbashkët, që është zgjidhja e problemeve ne komunitetet e tyre. Ky program ka pasur 4 edicione ku janë trajnuar më shumë se 700 nxënës të etnive të ndryshme dhe janë zhvilluar më shumë se 60 zgjidhje të ndryshme teknologjike.",
    img: img4,
    illustration: image4,
    size: 320,
    color: "red",
  },
];
