import { motion } from "framer-motion";
import { UilExpandArrowsAlt, UilShare } from "@iconscout/react-unicons";
import { Fade } from "react-reveal";
import { useEffect, useState } from "react";

function ProjectCards(props) {
  const { key, title, description, imageSrc, module, action, tag, href } =
    props;

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };
  return (
    <>
      {(isMobile && (
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          key={key}
          className="project__cards"
          item
        >
          <div className="project__img">
            <img loading="lazy" src={imageSrc} alt="Project Image" />
          </div>
          <div className="project__info">
            <div className="project__info-content">
              <h1>{title}</h1>
              <p>{description}</p>
              <h2>Moduli: {module}</h2>
              <span>#{tag}</span>
            </div>
            <button
              className="project__info-fullscreen"
              onClick={action}
              aria-label="Fullscreen"
            >
              <UilExpandArrowsAlt size={"80"} color={"#fff"} />
            </button>
            <a
              className={`project__link ${href === true ? "disabled" : ""}`}
              href={href}
              target="_blank"
              aria-label="View Project Link"
            >
              <UilShare size={"40"} />
            </a>
          </div>
        </motion.div>
      )) || (
        <Fade bottom cascade>
          <div>
            <div
              // variants={container}
              // initial="hidden"
              // animate="show"
              key={key}
              className="project__cards"
              // item
            >
              <div className="project__img">
                <img loading="lazy" src={imageSrc} alt="Project Image" />
              </div>
              <div className="project__info">
                <div className="project__info-content">
                  <h1>{title}</h1>
                  <p>{description}</p>
                  <h2>Moduli: {module}</h2>
                  <span>#{tag}</span>
                </div>
                <button
                  className="project__info-fullscreen"
                  onClick={action}
                  aria-label="Fullscreen"
                >
                  <UilExpandArrowsAlt size={"80"} color={"#fff"} />
                </button>
                <a
                  className={`project__link ${href === true ? "disabled" : ""}`}
                  href={href}
                  target="_blank"
                  aria-label="View Project Link"
                >
                  <UilShare size={"40"} />
                </a>
              </div>
            </div>
          </div>
        </Fade>
      )}
    </>
  );
}

export default ProjectCards;
