import registerHero from "assets/images/illustrations/image5.webp";
import { Fade } from "react-reveal";

function RegisterHero() {
  return (
    <>
      <div className="register__hero-container container">
        <Fade top cascade>
          <div className="register__hero-content">
            <h1>Regjistroni fëmijen tuaj</h1>

            <p>
              Zhvilloni fëmijen tuaj duke e regjistruar atë në Akademinë
              jCoders. Vazhdoni më posht për të mesuar më shumë.
            </p>
          </div>
          <div className="register__hero-img">
            <img src={registerHero} alt="Register Hero Image" />
          </div>
        </Fade>
      </div>
    </>
  );
}

export default RegisterHero;
