import Button from "components/buttons/Buttons";
import Socials from "components/buttons/Socials";

function NavHeader(props) {
  const { hide } = props;
  return (
    <>
      <div className="info__header">
        <div className="info__header-wrapper">
          <div className="info__header-content">
            <p>info@j-coders.com</p>
            <p>+386(0)49454929</p>
          </div>
          <div className="info__header-content">
            <Socials credits={false} size={"lg"} />
            {hide == false && (
              <Button
                className={["yellow-btn xs-btn"]}
                RedirectLink="/register"
                ButtonTitle="Regjistrohu"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NavHeader;
