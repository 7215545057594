// import AboutSvg from 'assets/svg/about_us_svg.svg'
import Button from "components/buttons/Buttons";
import Fade from "react-reveal/Fade";

// Importing Assets
import AboutImg from "assets/images/illustrations/image3.webp";

function AboutUs(props) {
  const { animate = true } = props;

  return (
    <>
      {(animate && (
        <Fade top cascade>
          <div className={[...props.classNameAboutText]}>
            <h1>Për Ne</h1>
            <p>
              Akademia jCoders është një institucion i cili ofron trajnime për
              nxënësit të moshave 8-18 në fushën e teknologjisë. jCoders është
              institucion i akredituar nga Autoriteti Kombëtar i Kualifikimeve
              dhe është i vetmi institucion që mban mban titullin “STEM
              CERTIFIED” në nivel ndërkombëtar nga institucioni amerikan COGNIA
              (më herët njohur si AdvancED).
              <br /> <br />
              Akademia jCoders ndodhet në 6 lokacione të Kosovës tri lokacione
              në Prishtinë, një në Pejë, një në Mitrovicë dhe një në Gjilan.
            </p>
            <Button
              className={[...props.classNameAboutBtn]}
              RedirectLink="/about"
              ButtonTitle="Më Shumë"
              variant={"blue"}
              ariaLabel={"Më Shumë"}
            />
          </div>

          <div className={[`about__svg ${props.classNameAboutSvg}`]}>
            <img src={AboutImg} alt="About Us" />
          </div>
        </Fade>
      )) || (
        <>
          <div className={[...props.classNameAboutText]}>
            <h1>Për Ne</h1>
            <p>
              Akademia jCoders është një institucion i cili ofron trajnime për
              nxënësit të moshave 8-18 në fushën e teknologjisë. jCoders është
              institucion i akredituar nga Autoriteti Kombëtar i Kualifikimeve
              dhe është i vetmi institucion që mban mban titullin “STEM
              CERTIFIED” në nivel ndërkombëtar nga institucioni amerikan COGNIA
              (më herët njohur si AdvancED).
              <br /> <br />
              Akademia jCoders ndodhet në 6 lokacione të Kosovës tri lokacione
              në Prishtinë, një në Pejë, një në Mitrovicë dhe një në Gjilan.
            </p>
            <Button
              className={[...props.classNameAboutBtn]}
              RedirectLink="/about"
              ButtonTitle="Më Shumë"
              variant={"blue"}
              ariaLabel={"Më Shumë"}
            />
          </div>

          <div className={[`about__svg ${props.classNameAboutSvg}`]}>
            <img src={AboutImg} alt="About Us" />
          </div>
        </>
      )}
    </>
  );
}

export default AboutUs;
