// Import Swiper React components
import { Swiper } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper";

function PaginationCarousel(props) {
  const { className, children } = props;
  //   const [isMobile, setIsMobile] = useState(false);

  //   useEffect(() => {
  //     const handleResize = () => {
  //       setIsMobile(window.innerWidth <= 992);
  //     };

  //     window.addEventListener("resize", handleResize);
  //     handleResize();

  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //     };
  //   }, []);

  return (
    <>
      <Swiper
        modules={[Autoplay, Pagination]}
        slidesPerView={4}
        autoplay={{ delay: 2500 }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          100: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
        className={`simple-pagination-swiper ${className}`}
      >
        {children}
      </Swiper>
    </>
  );
}

export default PaginationCarousel;
