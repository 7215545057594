const theme = {
  blue: "#40b1e5",
  red: "#f15b5b",
  purple: "#9f64a8",
  yellow: "#ffb000",
};

function PlanProgramDescription(props) {
  const { desc, color } = props;
  const _theme = theme[color];
  return (
    <>
      <div className="programs__learning-wrapper container">
        <h1 style={{ color: _theme }}>Çka do të mësojnë nxënësit?</h1>
        <p>
          {desc}
          <span
            style={{
              content: "",
              position: "absolute",
              left: "0",
              top: "0",
              width: "5px",
              height: "100%",
              backgroundColor: _theme,
            }}
          />
        </p>
        <div className="separator-img-wrapper">
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 272 116"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M205.919 102.667L246.969 61.7424L205.919 20.8173L218.434 8.34375L272 61.7424L218.434 115.141L205.919 102.667Z"
              fill="#152E4A"
            />
            <path
              d="M105.379 45.3242H88.6914V62.0113H105.379V45.3242Z"
              fill="#152E4A"
            />
            <path
              d="M172.462 45.3242H155.774V62.0113H172.462V45.3242Z"
              fill="#152E4A"
            />
            <path
              d="M138.921 45.3242H122.233V62.0113H138.921V45.3242Z"
              fill="#152E4A"
            />
            <path
              d="M66.081 102.25L24.9889 61.3254L66.0392 20.4003L53.5239 7.92676L0 61.3254L53.5657 114.724L66.081 102.25Z"
              fill="#152E4A"
            />
            <path
              d="M205.919 94.7413L246.969 53.8163L205.919 12.8906L218.434 0.416992L272 53.8163L218.434 107.215L205.919 94.7413Z"
              fill={_theme}
            />
            <path
              d="M88.6914 56.7359H105.379V40.0488H88.6914L88.6914 56.7359ZM172.461 40.0488H155.774V56.7359H172.461V40.0488ZM122.233 56.7359H138.92V40.0488H122.233V56.7359Z"
              fill={_theme}
            />
            <path
              d="M66.081 94.3237L24.9889 53.3986L66.0392 12.4736L53.5239 0L0 53.3986L53.5657 106.797L66.081 94.3237Z"
              fill={_theme}
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default PlanProgramDescription;
