import bulletPurple from "assets/images/methodology/bulletPurple.png";
import bulletBlue from "assets/images/methodology/bulletBlue.png";
import image from "assets/images/methodology/image.webp";

function Methodology() {
  return (
    <>
      <div className="home__methodology-container container">
        <div className="home__methodology-content">
          <div className="home__methodology-title">
            <h1>Metodologjia</h1>
            <h2>Metodologjia jonë</h2>
          </div>
          <p>
            Sipas standardeve amerikane, në jCoders aplikohen kryesisht 2
            metodologji të punës:
            <br />
            <br />
            <span>
              <img loading="lazy" src={bulletBlue} alt="Blue Bullet" />
              Të mësuarit përmes përvojës (Experiential Learning){" "}
            </span>
            <span>
              <img loading="lazy" src={bulletPurple} alt="Blue Purple" />
              Mësimi i bazuar në Projekte (Project Based Learning)
            </span>
            <br />
            Këto dy metoda sigurojnë që çdo orë mësimore në jCoders është
            interaktive dhe nxënësit janë të angazhuar praktikisht në zgjidhjen
            e problemeve.
            <br />
            <br />
            Sipas të dhënave studimore, aplikimi i këtyre metodave rrit
            angazhimi e nxënësve në klasë, kapacitetin mësimnxënies, zhvillon
            shkathtësitë e mendimit kritik, bashkëpunimit dhe komunikimit si dhe
            rrit vetëbesimin dhe përgjegjësinë në mësimnxënie.
          </p>
        </div>
        <div className="home__methodology-img">
          <img loading="lazy" src={image} alt="Methodology Image" />
        </div>
      </div>
    </>
  );
}

export default Methodology;
