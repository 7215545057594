// Importing Summer Images
import img1Age15 from "assets/images/planprograms/age15+/images/img1.webp";
import img2Age15 from "assets/images/planprograms/age15+/images/img2.webp";
import img3Age15 from "assets/images/planprograms/age15+/images/img3.webp";
import img4Age15 from "assets/images/planprograms/age15+/images/img4.webp";
import img1Age10 from "assets/images/planprograms/age10-14/images/img1.webp";
import img2Age10 from "assets/images/planprograms/age10-14/images/img2.webp";
import img3Age10 from "assets/images/planprograms/age10-14/images/img3.webp";
import img4Age10 from "assets/images/planprograms/age10-14/images/img4.webp";
import img1Age7 from "assets/images/planprograms/age7_9/images/img1.webp";
import img2Age7 from "assets/images/planprograms/age7_9/images/img2.webp";
import img3Age7 from "assets/images/planprograms/age7_9/images/img3.webp";
import img4Age7 from "assets/images/planprograms/age7_9/images/img4.webp";

export const planPrograms = [
  // Themes
  // blue | red | purple | yellow | green
  // type 1 = rregullt , 2 = parregullt

  // Plan programet te rregullta

  {
    id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    title: "Planprogrami për moshat 7-10",
    subtitle: "JUNIOR CODERS",
    shortDesc:
      "Programi i jCoders është krijuar me qëllim që t’u ofroj nxënësve mediume kreative për të mësuar konceptet më të reja teknologjike të përshtatshme për moshën e tyre. ",
    description:
      "Programi i jCoders është krijuar me qëllim që t’u ofroj nxënësve mediume kreative për të mësuar konceptet më të reja teknologjike të përshtatshme për moshën e tyre. Nxënësit të cilët ndjekin të gjitha modulet të shtrira në programin 3 vjeçar arrijnë që të mësojnë një diversitet të teknologjive duke përfshirë kodimin e lojërave, aplikacioneve, krijimi në 3D, elektronikën dhe robotikën. Me këtë diversitet nxënësit fitojnë një bazë shumë stabile të të kuptuarit të teknologjisë, dhe arrijnë t’a shohin atë si vegël të cilën mund t’a përdorin për të krijuar.",

    type: 1,
    theme: "blue",
    years: [1, 2, 3],
    results: [1, 2, 3],
  },
  {
    id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    title: "Planprogrami për moshat 11-14",
    subtitle: "JUNIOR CODERS",
    shortDesc:
      "Programi i jCoders është krijuar me qëllim që t’u ofroj nxënësve mediume kreative për të mësuar konceptet më të reja teknologjike të përshtatshme për moshën e tyre. ",
    description:
      "Programi i jCoders është krijuar me qëllim që t’u ofroj nxënësve mediume kreative për të mësuar konceptet më të reja teknologjike të përshtatshme për moshën e tyre. Nxënësit të cilët ndjekin të gjitha modulet të shtrira në programin 3 vjeçar arrijnë që të mësojnë një diversitet të teknologjive duke përfshirë kodimin e lojërave, aplikacioneve, krijimi në 3D, elektronikën dhe robotikën. Me këtë diversitet nxënësit fitojnë një bazë shumë stabile të të kuptuarit të teknologjisë, dhe arrijnë t’a shohin atë si vegël të cilën mund t’a përdorin për të krijuar.",
    age: 15,
    type: 1,
    theme: "purple",
    years: [1, 2],
    results: [1, 2],
  },
  {
    id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    title: "Planprogrami për moshat 15+",
    subtitle: "JUNIOR FULL-STACK",
    shortDesc:
      "Programi “Junior Full Stack Developer” fokusohet në programimin e Webfaqeve. Ndjekja e këtij programi u mundëson pjesëmarrësve të pajisjen me njohuri të gjera rreth dizajnimit të Webfaqeve, programimit të tyre në Front-End, menaxhimin e të dhënave dhe funksioneve me Back-End si dhe përdorimin e mjeteve për menaxhimin e punës, të nevojshme për industrinë tonë.",
    description:
      "Programi i jCoders është krijuar me qëllim që t’u ofroj nxënësve mediume kreative për të mësuar konceptet më të reja teknologjike të përshtatshme për moshën e tyre. Programi “Junior Full Stack Developer” fokusohet në programimin e Webfaqeve. Ndjekja e këtij programi u mundëson pjesëmarrësve të pajisjen me njohuri të gjera rreth dizajnimit të Webfaqeve, programimit të tyre në Front-End, menaxhimin e të dhënave dhe funksioneve me Back-End si dhe përdorimin e mjeteve për menaxhimin e punës, të nevojshme për industrinë tonë. Ky program përditësohet në vazhdimësi, në mënyrë që të jetë në hap me teknologjitë e fundit dhe është dizajnuar me idenë që fillestarët t’i kthej në persona të gatshëm për punë. ",
    age: 15,
    type: 1,
    theme: "red",
    years: [1, 2, 3],
    results: [1, 2, 3],
  },

  // Plan programet Verore
  {
    id: "f96130c0-e709-4f27-a181-25721624d5d3",
    title: "Planprogrami Veror për grupmoshën 7-9 vjeç",
    subtitle: "Kampi i Teknologjisë dhe Shkencës",
    shortDesc:
      "JCoders përgatitë të rinjtë në fushën e teknologjisë informative. Këtë e realizojmë duke i'u mësuar të përdorin teknologjinë si vegël krijuese, me anë të cilës ata ndërtojnë aplikacione, lojëra dhe krijime tjera teknologjike. Në fund të kampit, secili nxënës do të pajiset me certifikatë të njohur ndërkombëtarisht me vulë nga një ndër institucionet më rigoroze akredituese amerikane të quajtur Cognia.",
    description:
      "Gjatë verës, fëmijët dëshirojnë të relaksohen dhe të argëtohen, por gjithmonë ka mënyra për të argëtuar duke mësuar! Sfidat që kërkojnë kreativitet, mendim logjik dhe zgjidhje të problemeve janë ato që stimulojnë natyrën krijuese dhe kuriozitetin e secilit individ, veçanërisht tek fëmijët që ende janë duke zbuluar botën që i rrethon. Me këtë në mendje, jCoders ka krijuar kampin e teknologjisë dhe shkencës për moshat 7-9, ku fëmijët kanë mundësinë të ndërveprojnë, të eksperimentojnë dhe të mësojnë në mënyrë argëtuese dhe inovative.",
    extraDesc:
      "Vera në jCoders për fëmijët e moshave 7-9 vjet është e organizuar në 16 seanca nga 2 orë, ata vijnë ne jCoders nga e hëna në të enjten për 4 javë.",
    age: "7-9",
    type: 2,
    theme: "yellow",
    images: [img1Age7, img2Age7, img3Age7, img4Age7],
    // this element "." new line
    // this element ". ." new line with space
  },
  {
    id: "3db0cec4-23e6-4ab1-9270-2c8076368dee",
    title: "Planprogrami Veror për grupmoshën 10-14 vjeç",
    subtitle: "Teknologjia takon Ndërmarrësinë",
    shortDesc:
      "JCoders përgatitë të rinjtë në fushën e teknologjisë informative. Këtë e realizojmë duke i'u mësuar të përdorin teknologjinë si vegël krijuese, me anë të cilës ata ndërtojnë aplikacione, lojëra dhe krijime tjera teknologjike. Në fund të kampit, secili nxënës do të pajiset me certifikatë të njohur ndërkombëtarisht me vulë nga një ndër institucionet më rigoroze akredituese amerikane të quajtur Cognia.",
    description:
      "Vera në jCoders për fëmijët e moshave 10-14 vjet është e organizuar në 16 seanca mësimore, 14 seanca nga 2 orë dhe 2 seanca nga 5 orë. Ata vijnë ne jCoders nga e hëna në të enjten për 4 javë. Çdo të premte do të kemi aktivitete zbavitëse dhe edukative të cilat do të jenë opsionale dhe mund të marrin pjesë fëmijët sa herë dëshirojnë. Qëllimi i këtij Kampi Veror është që secili fëmijë të lansojë një biznes të vogël. Gjatë kampit do të mësojnë për veglat më të reja të Inteligjences Artificiale, dhe do të perdorin ato për të zhvilluar përmbajtjen e nevojshme që të hapin bizneset e tyre të vogla.  Ky kamp është shumë praktik, dhe fëmijët do te zhvillojnë shkathesitë e tyre financiare, problem-zgjidhëse si dhe do të përjetojnë hapat e zhvillimit të një biznesi te vogël.  Ky kamp i këtij lloji organizohet për herë të parë ne Kosovë!",
    extraDesc:
      "Vera në jCoders për fëmijët e moshave 10-14 vjet është e organizuar në 16 seanca mësimore, 14 seanca nga 2 orë dhe 2 seanca nga 5 orë. Ata vijnë ne jCoders nga e hëna në të enjten për 4 javë. Çdo të premte do të kemi aktivitete zbavitëse dhe edukative dhe mund të marrin pjesë fëmijët sa herë dëshirojnë.",
    age: "10-14",
    type: 2,
    theme: "purple",
    images: [img1Age10, img2Age10, img3Age10, img4Age10],
    // this element "." new line
    // this element ". ." new line with space
  },
  {
    id: "d248f9d0-e35c-490f-91d3-fc6634a89b67",
    title: "Planprogrami Veror për grumoshën 15+",
    subtitle: "Data Science & Artificial Intelligence",
    shortDesc:
      "JCoders përgatitë të rinjtë në fushën e teknologjisë informative. Këtë e realizojmë duke i'u mësuar të përdorin teknologjinë si vegël krijuese, me anë të cilës ata ndërtojnë aplikacione, lojëra dhe krijime tjera teknologjike. Në fund të kampit, secili nxënës do të pajiset me certifikatë të njohur ndërkombëtarisht me vulë nga një ndër institucionet më rigoroze akredituese amerikane të quajtur Cognia.",
    description:
      "Gjatë këtij moduli nxënësit do të mësojnë Data Science me Python dhe përdorimin e inteligjencës artificiale. Python rekomandohet të mësohet për fillestarë në fushën e programimit për shkak të sintaksës së lehtë dhe të thjeshtë. Në të njëjtën kohë Python ka shtrirje në shumë disiplina dhe kjo e bënë atë gjuhë programuese shumë të gjithanshme për zhvillimin e softuerëve. Megjithatë, ajo ku Python shkëlqen me të vërtetë, është në fushën e shkencën së të dhënave (Data Science) dhe Inteligjencës Artificiale.",
    extraDesc:
      "Për moshat 15+ vera do të jetë një eksperiencë sfiduese duke i qëndruar afër industrisë së teknologjisë. Gjatë kësaj vere nxënësit do të kenë mundësi të ndjekin programin online ose në klasë. Ky program do të përfshijë punë praktike dhe do të ju ofrojë nxënësve mundësinë të zhvillojnë projekte reale. Nxënësit do të ndjekin kampin veror për 4 javë, çdo ditë nga e hëna në të enjtën. Trajnimi do të zgjasë 2 orë në ditë, ndërsa ditën e fundit do të mbahet një maratonë e kodimit e cila do të zgjasë 8 orë.",
    age: "15+",
    type: 2,
    theme: "blue",
    images: [img1Age15, img2Age15, img3Age15, img4Age15],
    // this element "." new line
    // this element ". ." new line with space
    information:
      "Kohëzgjatja e programit:.. Brenda një muaji nxënësit marrin pjesë në 32 orë akademike. Mësimi mbahet 4 herë në javë (E hëne - E enjte) nga 2 orë në ditë (120 minuta) . . OPSIONET PER VIJIMIN NË KAMPIN VEROR . KORRIK: 03 - 28 Korrik . GUSHT: 31 Korrik - 25 Gusht . . Regjistrohu para dates 1 Qershor dhe perfito zbritje 15% - 136€ për nxënës . Çmimi i kampit veror është 160€ për nxënës. . *Zbritja vlenë për nxënësit të cilët janë familjar, apo janë të referuar nga ju . Çmimi i kampit veror për nxënësit aktiv është 136€ për nxënës në çdo kohë",
  },
];

// Year results
export const yearResults = [
  {
    id: 1,
    title: "Web Fundamentals",
    year: 1,
    desc: "Dizajnojnë Webfaqe responsive duke u bazuar në eksperiencën e përdoruesit. Njohin rëndësinë e dizajnit paralelisht me atë të programimit të një produkti. Njohin dhe të implementojnë HTML elementet përbërëse të një faqeje. Dizajnojnë ato elemente përmes teknologjive si CSS dhe SCSS. Zhvillojnë animacione përmes programimit në CSS. Implementojnë në faqen e tyre pjesë të jashtme të kodit. Praktikojnë përdorimin e mjeteve të cilat lejojnë punën në grup si menaxhimin e tasqeve me Trello apo ndarjen e kodit me GitHub. Të bashkëpunojnë mes vete për të arritur në një produkt final. Të krijojnë në vazhdimësi projekte individuale të bazuara në Front-End.",
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
  },
  {
    id: 2,
    year: 2,
    title: "Programming with JavaScript",
    desc: "Praktikojnë konceptet themelore dhe logjikën e gjuhës programuese JavaScript. Njohin strukturat bazë kontrolluese si dhe ato iterative të çdo gjuhe programuese. Përdorin veglat e ndryshme teknologjike për të menaxhuar punën gjatë zhvillimit të Webfaqeve. Programimin e Webfaqeve dinamike. Praktiktojnë logjikën për të shtuar interaksion ndërmjet përdoruesit dhe webfaqes. Praktikojnë algoritme për bashkëpunim të vazhdueshëm mes faqes dhe userit. Përdorin metodat më të reja të shkrimit të kodit që Webfaqja të jetë e optimizuar. Krijojnë Webfaqe të cilat kanë për bazë asinkronitetin.",
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
  },
  {
    id: 3,
    year: 3,
    title: "Programming with Node.js",
    desc: "Zhvillojnë aplikacione “Full-Stack” duke u mbështetur në Nodejs. Të njohin Databaza të ndryshme dhe karakteristikat e tyre për të përzgjedhur Databazën që i përshtatet aplikacionit. Të mësoj komunikimin mes aplikacionit dhe Databazës. Praktikoj CRUD operime në Databazë përmes aplikacionit. Njoh shtyllat e MVC dhe praktikisht t’i implementoj ato përmes kodit. Të krijoj projekte të plota në Front dhe Back-End duke mbështetur menaxhimin dhe manipulimin me të dhëna.",
    planprogram_id: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
  },
  {
    id: 4,
    year: 1,
    title: "Elementary Coder",
    desc: "Përdorin kompjuterin në mënyrë efektive. Njohin arkitekturën hardverike të kompjuterit. Njohin komandat vizuale për programim të aplikacioneve/lojërave. Njohin logjikën e programimit në prapavijë të lojës/animacionit. Njohin elektricitetin, arkitekturën e pajisjeve të përditshme dhe krijimin e qarqeve elektrike për realizimin e ideve të tyre. Dizajnojnë në 3D konstrukte të objekteve të ndryshme. Krijojnë konstrukte fizike të projekteve në robotikë.",
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
  },
  {
    id: 5,
    year: 2,
    title: "Junior Coder",
    desc: "Hulumtojnë në mënyrë më efektive. Mbrojnë mirë të dhënat e tyre në internet. Krijojnë lojëra me anë të programimit me blloqe. Zhvillojnë logjikë më komplekse në kod për ndërveprime të ndryshme. Krijojnë dhe aplikojnë komponente elektronike në qarqe elektrike duke krijuar pajisje të cilat ofrojnë zgjidhje për sfidat në përditshmëri. Njohin koncepte të matematikës të cilat ndërlidhen direkt me programim. Integrojnë aspekte të ndryshme si dizajni, programimi dhe elektronika në projektet e tyre.",
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
  },
  {
    id: 6,
    year: 3,
    title: "Advanced Coder",
    desc: "Dizajnojnë pamjen e aplikacioneve bazuar në rregullat e UI/UX. Krijojnë aplikacione mobile. Krijojnë moodboard dhe gjenerojnë paleta të ngjyrave. Krijojnë dizajne komplekse 2D. Krijojnë projekte duke manipuluar komponentet me anë të mikrokontrollerëve. Krijojnë qarqe të avancuara me aktuatorë dhe sensorë për të automatizuar proceset e përditshme.",
    planprogram_id: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
  },
  {
    id: 7,
    year: 1,
    title: "Junior Coder",
    desc: "Hulumtojnë në mënyrë më efektive. Mbrojnë mirë të dhënat e tyre në internet. Krijojnë lojëra me anë të programimit me blloqe. Zhvillojnë logjikë më komplekse në kod për ndërveprime të ndryshme. Krijojnë dhe aplikojnë komponente elektronike në qarqe elektrike duke krijuar pajisje të cilat ofrojnë zgjidhje për sfidat në përditshmëri. Njohin koncepte të matematikës të cilat ndërlidhen direkt me programim. Integrojnë aspekte të ndryshme si dizajni, programimi dhe elektronika në projektet e tyre.",
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
  },
  {
    id: 8,
    year: 2,
    title: "Advanced Coder",
    desc: "Dizajnojnë pamjen e aplikacioneve bazuar në rregullat e UI/UX. Krijojnë aplikacione mobile. Krijojnë moodboard dhe gjenerojnë paleta të ngjyrave. Krijojnë dizajne komplekse 2D. Krijojnë projekte duke manipuluar komponentet me anë të mikrokontrollerëve. Krijojnë qarqe të avancuara me aktuatorë dhe sensorë për të automatizuar proceset e përditshme.",
    planprogram_id: "48f7bb6a-4701-4757-bf37-f864d88462e9",
  },
];
