import classNames from "classnames";

function ProgramYearResults(props) {
  const { key, style, title, desc, theme, variant = ".", after = "○" } = props;

  return (
    <>
      <div key={key} className="year__results-container">
        <div className="year__results-card">
          <h1 className={classNames(`${theme}-text`)}>{title}</h1>
          <div className="year__results-content-desc">
            <div
              className={[classNames(`text-splitter-year ${theme}-line`)]}
            ></div>
            <div>
              {desc.map((item) => {
                return (
                  <p style={style}>
                    <span className={classNames(`${theme}-spliter`)}>
                      {after}
                    </span>{" "}
                    {item}
                    {variant} <br />
                  </p>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProgramYearResults;
