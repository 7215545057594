import vera7_9 from "assets/images/programs/cardsImage/vera7_9.webp";
import vera10_14 from "assets/images/programs/cardsImage/vera10_14.webp";
import vera15 from "assets/images/programs/cardsImage/vera15.webp";
import programR15 from "assets/images/programs/cardsImage/programR15.webp";
import programR11_14 from "assets/images/programs/cardsImage/programR11_14.webp";
import programR7_10 from "assets/images/programs/cardsImage/programR7_10.webp";

export const planProgramsCards = [
  {
    id: 1,
    img: vera7_9,
    title: "Kampi i teknologjisë dhe shkencës",
    age: "Mosha: 7-9",
    description:
      "Vera do të jetë një eksperiencë sfiduese duke i qëndruar afër industrisë së zhvillimit të softuereve dhe elektronikës. Programet do të përfshijnë...",
    redirect: "f96130c0-e709-4f27-a181-25721624d5d3",
    theme: "yellow",
    type: 2,
  },
  {
    id: 2,
    img: vera10_14,
    title: "Kampi teknologjia dhe ndërmarrësia",
    age: "Mosha: 10-14",
    description:
      "Vera do të jetë një eksperiencë sfiduese duke i qëndruar afër industrisë së zhvillimit të softuereve dhe elektronikës. Programet do të përfshijnë...",
    redirect: "3db0cec4-23e6-4ab1-9270-2c8076368dee",
    theme: "purple",
    type: 2,
  },
  {
    id: 3,
    img: vera15,
    title: "Data science & Artificial Intelligence",
    age: "Mosha: 15+",
    description:
      "Vera do të jetë një eksperiencë sfiduese duke i qëndruar afër industrisë së zhvillimit të softuereve dhe elektronikës. Programet do të përfshijnë...",
    redirect: "d248f9d0-e35c-490f-91d3-fc6634a89b67",
    theme: "darkBlue",
    type: 2,
  },
  {
    id: 4,
    img: programR7_10,
    title: "Planprogrami",
    subtitle: "Junior Coder",
    age: "Mosha: 7-10",
    description:
      "Nxënësit të cilët ndjekin të gjitha modulet të shtrira në programin 3 vjeçar arrijnë që të mësojnë një diversitet të teknologjive duke përfshirë kodimin e lojërave, aplikacioneve, krijimi në 3D, elektronikën dhe robotikën. ",
    redirect: "25ea7aea-2cf6-4980-a17e-c5c938dcfd46",
    theme: "lightBlue",
    type: 1,
  },
  {
    id: 5,
    img: programR11_14,
    title: "Planprogrami",
    subtitle: "Junior Coder",
    age: "Mosha: 11-14",
    description:
      "Nxënësit të cilët ndjekin të gjitha modulet të shtrira në programin 3 vjeçar arrijnë që të mësojnë një diversitet të teknologjive duke përfshirë kodimin e lojërave, aplikacioneve, krijimi në 3D, elektronikën dhe robotikën. ",
    redirect: "48f7bb6a-4701-4757-bf37-f864d88462e9",
    theme: "purple",
    type: 1,
  },
  {
    id: 6,
    img: programR15,
    title: "Planprogrami",
    subtitle: "Junior Full-Stack",
    age: "Mosha: 15+",
    description:
      "Programi “Junior Full Stack Developer” fokusohet në programimin e Webfaqeve.  Ndjekja e këtij programi u mundëson pjesëmarrësve të pajisjen me njohuri të gjera rreth dizajnimit të Webfaqeve, programimit të tyre në Front-End, menaxhimin e të dhënave dhe funksioneve me Back-End si dhe përdorimin e mjeteve për menaxhimin e punës, të nevojshme për industrinë tonë.",
    redirect: "ebf4cd88-c843-421d-a3d0-1bf108f0c91a",
    theme: "red",
    type: 1,
  },
];
