import PlanProgramInformation from "pages/planProgramUnregular/components/PlanProgramInformation";
import PlanProgramModule from "pages/planProgramUnregular/components/PlanProgramModule";

function PlanProgramWeeks(props) {
  const { info, desc, modules, theme } = props;
  return (
    <>
      <div className="program__ur__weeks-wrapper">
        <div className="program__ur__weeks-modules">
          {modules.map((i, index) => {
            return (
              <PlanProgramModule
                // style={{ minHeight: "370px" }}
                key={index}
                moduleTitle={i.title}
                moduleDesc={i.description}
                moduleDuration={i.duration}
                languageImg={i.bgImg}
                moduleTheme={theme}
              />
            );
          })}
        </div>
        <div className="program__ur__weeks-info">
          <PlanProgramInformation theme={theme} />
        </div>
      </div>
    </>
  );
}

export default PlanProgramWeeks;
