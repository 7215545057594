function SelectMenu(props) {
  const {
    name,
    id,
    className,
    required = true,
    onClick,
    onChange,
    value,
    options,
    title,
    disabled,
    label,
  } = props;

  return (
    <div className="register__input-container register__select-menu">
      <label htmlFor={id}>
        {label}
        <span>*</span>
      </label>
      <select
        name={name}
        id={id}
        className={className}
        required={required}
        onClick={onClick}
        onChange={onChange}
        value={value}
      >
        <option disabled={disabled} value="">
          {title}
        </option>
        {options.map((i, index) => {
          return (
            <option key={index} value={i.id}>
              {i.name}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default SelectMenu;
