// Importing Carousel
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

function PlanProgramOrganized(props) {
  const { desc, images } = props;
  return (
    <>
      <div className="programs__organized-wrapper container">
        <div className="programs__organized-text">
          <h1>Si është i organizuar programi veror?</h1>
          <p>{desc}</p>
        </div>
        <div className="programs__organized-slider">
          <Swiper
            pagination={{ clickable: true }}
            modules={[Pagination, Autoplay]}
            autoplay={{ delay: 1300 }}
            loop={true}
            className="programs__organized-carousel"
          >
            {images.map((i) => {
              return (
                <SwiperSlide>
                  <div className="programs__organized-img">
                    <img src={i} alt="Programs Organized Image" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export default PlanProgramOrganized;
