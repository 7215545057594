// Importing Testimonials Profile Img
import testimonialsImg from "assets/images/testimonials/testimonialImg.png";

// Importing Assets For Team Section
import fiona from "assets/images/team/team_members/fiona.webp";
import arta from "assets/images/team/team_members/arta.webp";
import marigona from "assets/images/team/team_members/marigona.webp";
import besarta from "assets/images/team/team_members/besarta.webp";

export const cities = [
  {
    id: 1,
    name: "Prishtinë (Bregu i Diellit)",
    value: "prishtinë Bregu i Diellit",
  },
  { id: 2, name: "Prishtine (Arbëri)", value: "prishtinë Arbëri" },
  { id: 3, name: "Mitrovicë", value: "mitrovicë" },
  { id: 4, name: "Pejë", value: "pejë" },
  { id: 5, name: "Gjilan", value: "gjilan" },
];

export const planPrograms = [
  { id: 1, name: "Baze mosha 7-10", value: "mosha7-10" },
  { id: 2, name: "Baze mosha 11-14", value: "mosha11-14" },
  { id: 3, name: "Baze mosha 15+", value: "mosha15+" },
  // { id: 1, name: "Vera mosha 7-9", value: "vera7-10" },
  // { id: 2, name: "Vera mosha 10-14", value: "vera10-14" },
  // { id: 3, name: "Vera mosha 15+", value: "vera15+" },
];

export const summerPlanPrograms = [
  { id: 1, name: "Grupmosha 7-10", value: "mosha7-10" },
  { id: 2, name: "Grupmosha 11-14", value: "mosha11-14" },
  { id: 3, name: "Grupmosha 15+", value: "mosha15+" },
];

export const preferences = [
  { id: 1, name: "Fizikisht", value: "fizikisht" },
  { id: 2, name: "Online", value: "online" },
];

export const testimonials = [
  {
    id: 1,
    image: testimonialsImg,
    title: "Tahir Sopi",
    role: "Prind",
    desc: "Pershendetje, une jam prindi i Una Sopit, e cila ka disa muaj qe eshte pjese e akademise suaj, J-Coders. Personalisht, jam i kenaqur me aftesimin e vajzes sime, te e cila çdo dite e me shume po shoh zhvillim ne aspektin e kompjuterikes.",
  },
  {
    id: 2,
    image: testimonialsImg,
    title: "Arif Kadriu",
    role: "Prind",
    desc: "Jcoders është vendi ku Atiku ka mësuar hapat e tij të parë në botën e kodimit, tani është viti i tij i tretë dhe gjithmonë buzëqesh kur shkon në klasë. Metodologjia e të mësuarit është përshtatur me moshën e tyre dhe trajnerët janë mbështetës.",
  },
  {
    id: 3,
    image: testimonialsImg,
    title: "Valbona Mahmutaj Tmava",
    role: "Prind",
    desc: "Pershendetje e nderuar, Jemi shume te kenaqur me mesimin dhe eksperiencen e djalit Noar Tmava.  Ai vjen me vullnet ne kurs, edhe sa here kthehet e shof qe cdo here tregon qe ka mesuar dicka te re dhe e shpreh me aq entuziazem ",
  },
  {
    id: 4,
    image: testimonialsImg,
    title: "Erza Imeraj",
    role: "Nxënëse",
    desc: "Unë për Jcoders kam nje përshtypje shumë të mirë dhe për 9 modulet e para sa kam qenë kam fituar nje eksperienc shumë të mirë në përdorimin e teknologjis. Poashtu, kam kaluar shumë mirë me stafin e Jcoders, andaj ja u sugjeroj të gjithve.",
  },
  {
    id: 5,
    image: testimonialsImg,
    title: "Arif Kadriu",
    role: "Prind",
    desc: "Kejsani ka qenë gjithmonë i interesuar për kompjuterët dhe adresa e duhur për zhvillimin e tij është JCoders. Në një mjedis ku kreativiteti dhe mendimi i zgjuar janë prioritet, ai po zhvillon veten dhe po mëson kodimin.",
  },
  {
    id: 6,
    image: testimonialsImg,
    title: "Teuta Agaj",
    role: "Prind",
    desc: "Sìç e dimë shekulli 21 êshtë shekulli i teknologjisë, shekulli ku e gjithë bota po ecë në mënyrë rapide për një rangim sa më të lartë në zhvillimin e kësaj fushe. Edhe akademia J-coders, është shembulli më i mirë dhe më i sigurt në zhvillimin dhe avancimin e fëmijëve.",
  },
  {
    id: 7,
    image: testimonialsImg,
    title: "Lulzim Ymeri",
    role: "Prind",
    desc: "Për fëmijën tim të parë, Nadirin, tashmë është viti i tretë i mësimit; për të dytin, Farisin, tashmë është bërë një vit e gjysmë. Qoftë online, qoftë në mjedisin fizik, jCoders realizon objektivin e mësimit në mënyrë të kënaqshme si për fëmijën, si për mua si prind.",
  },
  {
    id: 8,
    image: testimonialsImg,
    title: "Berat Preniqi",
    role: "Prind",
    desc: "Suela është njëra nga vajzat e familjes sonë që gjithnjë ka tregu interesim dhe prirje në fushën e teknologjisë, dizajnit dhe inovacionit. Për herë të parë ishte pjesë organizimit tuaj  `Girls in Tech` të cilin e përfundoj me sukses dhe që më shumë i nxorri në pah këto.",
  },
  {
    id: 9,
    image: testimonialsImg,
    title: "Teuta Berisha",
    role: "Prind",
    desc: "Kleiti ka perfitura shume njohuri ne Jcoders te cilat njeherit i ka shfrytezuar ne te mire te klases kjo sipas te thenave te kujdestares se klases e cila shpesh me thote qe ke bere nje investim te jashtezakonshem.",
  },
  {
    id: 10,
    image: testimonialsImg,
    title: "Luljeta Kodra",
    role: "Prind",
    desc: "Une, si prind jam njohur me jCoder nga faqja e instagram. Jam shume e lumtur qe i besova,  pasi djali im ka mare shume njohuri gjate keti viti. Dihet tashme qe teknologjia eshte nje pjese shume e rendesishme e brezit te ri, por me ane te JCoders,  Asadi e perdor ne menyre shume efikase teknologjine.",
  },
  {
    id: 11,
    image: testimonialsImg,
    title: "Sead Duraki",
    role: "Nxënës",
    desc: "In Tech Heroes I started learning about building websites, how to create a prototype and how to implement it, which is my passion, because I am always looking to learn new things, and to grow my experience. I have met a lot of new people, I had a really good time, which motivated me to grow and have fun while doing so!",
  },
  {
    id: 12,
    image: testimonialsImg,
    title: "Leart Lajqi",
    role: "Nxënës",
    desc: "Pas regjistrimit tim në Tech Heroes mënyra sesi e perdori kompjuterin e tregon suksesin tim edhe të trainerëve të mi. Tech Heroes mund të them se më ka aftësuar per krijimin e dizajnimin e nje Web faqeje nga fillimi e deri në fund. Eksperienca ime gjatë kohes time në Tech Heroes është e mrekullueshje, gjithqka ishte 100% punë.",
  },
  {
    id: 13,
    image: testimonialsImg,
    title: "Fidaim Ramadani",
    role: "Nxënës",
    desc: "Si fillim ka qene quditshem, por me kalimin e kohes jam pershtatur me fushen e programimit dhe tani ndihem shume mir, studioj mjaftueshem dhe kam mesuar shum gjera madje dhe formula matematik qe si kam ditur me heret, Mesuesit dhe Classmates jan shum te sjellshem.",
  },
  {
    id: 14,
    image: testimonialsImg,
    title: "İlayda Çüse",
    role: "Nxënëse",
    desc: "Tech Heroes' a yazıldığımdan beri yazılımı daha iyi öğrenmeye başladım. Küçüklüğümden beri hep yazılım programlarını merak etmişimdir. Burda güzel vakit geçirip çok fazla bilgi edindim. Severek yaptığım için de asla bıkmıyorum. Umarım ki daha güzel yerlere gelip kendi web sitemi bile kurabilirim.",
  },
  {
    id: 15,
    image: testimonialsImg,
    title: "Stefan Mihajlovic",
    role: "Nxënës",
    desc: "Mene je Tech Hereos naucio dosta o programiranju i razumevanju raznih stvari onako kako treba, naravno tu u sam upoznao i dosta ljudi sa kojima mogu komunicirati. Sve u svemu jedno veoma dobro novo iskustvo.",
  },
  {
    id: 16,
    image: testimonialsImg,
    title: "Driton Syla",
    role: "Nxënës",
    desc: "Eshte nje pervoje e mire qe te jesh pjese e Tech Heroes sepse ktu e sfidoj veten dhe njekohesishte mesoj gjera te reja rreth krijimit te nje website ne menyren profesionale.",
  },
  {
    id: 17,
    image: testimonialsImg,
    title: "Prindi i Aron Marku",
    role: "Prind",
    desc: "Si prind i Aron Marku, po e filloj këtë dëshmi nga regjistrimi i Aronit në Akademinë Jcoders. Aroni gjithmonë me padurim priste qe ti afrohet orari i mësimit, dhe në vazhdimësi shfaqte një gëzim të vërejtur lehtë në fytyrën e tij, që më bënte të kuptoj që me të vërtete programi po i pëlqen.",
  },
  {
    id: 18,
    image: testimonialsImg,
    title: "Xhavit F. Bytyçi",
    role: "Prind",
    desc: "Përshtypjet tona për `jCoders` ishin pozitive që nga dita e parë kur Mendimi u regjistrua. Pavarësisht mënyrën se si janë mbajtur ligjëratat në klasa apo online djali im dhe unë si prindi i tij, kemi qenë të kënaqur madje mendoj se mënyra ONLINE, 100% i ka përmbushur detyrat e këtij trajnimi dhe është metodë e parapëlqyer për ne.",
  },
  {
    id: 19,
    image: testimonialsImg,
    title: "Arjana Milo Velija",
    role: "Prind",
    desc: "Roni djali ynë është i kënaqur me trajnimin. Po i pëlqejnë aktivitetet që po bëni. Aq shumë po tërhejkin aktivitetet sa që po i përfundon detyrat para kohe. Edhe sfidat e fundjavës janë shumë interesante. Po kënaqemi dhe ne prindërit duke i provuar. Ju përgëzoj për punën!",
  },

  // Copy item
  // {
  //   id: 0,
  //   image: testimonialsImg,
  //   title: "",
  //   role: "",
  //   desc: "",
  // },
];

// Team members Array
export const team = [
  {
    id: 1,
    image: fiona,
    name: "Fiona Shehu",
    position: "Board Member",
    email: "fiona@j-coders.com",
  },
  {
    id: 2,
    image: arta,
    name: "Arta Shehu Zaimi",
    position: "Board Member",
    email: "arta@j-coders.com",
  },
  {
    id: 3,
    image: marigona,
    name: "Marigona Sylaj",
    position: "Co-Chief Executive Officer",
    email: "marigona@j-coders.com",
  },
  {
    id: 4,
    image: besarta,
    name: "Besarta Muhaxheri",
    position: "Co-Chief Executive Officer",
    email: "besarta@j-coders.com",
  },
];
