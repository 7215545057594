import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

function HeroCarousel(props) {
  const {
    style,
    images,
    delay = 2000,
    effect = "fade",
    loop = true,
    swiperClassName,
    className,
    alt,
  } = props;
  return (
    <>
      <Swiper
        effect={effect}
        modules={[Autoplay, EffectFade]}
        autoplay={{ delay: delay }}
        autoHeight
        loop={loop}
        height={"100%"}
        style={{ height: "inherit" }}
        className={swiperClassName}
      >
        {images.map((i) => (
          <SwiperSlide key={i.id} style={style}>
            <div className={className}>
              <img src={i.img} alt={alt} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default HeroCarousel;
