import articleImg from "assets/images/illustrations/image6.webp";

function ArticleAbout(props) {
  const { title, desc } = props;

  return (
    <>
      <div className="article__about-container container">
        <div className="article__about-content">
          <h1>{title}</h1>
          <p>{desc}</p>
        </div>
        <div className="article__about-img">
          <img loading="lazy" src={articleImg} alt="Article About Image" />
        </div>
      </div>
    </>
  );
}

export default ArticleAbout;
