import ScrollToTopBtn from "components/buttons/ScrollToTopBtn";
import { useEffect, useState } from "react";
import RegisterButton from "./RegisterButton";

function BottomFixedButtons() {
    const [VisibleScroll, setVisibleScroll] = useState('hidden-scroll')

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 350) {
                setVisibleScroll('visible-scroll')
            } else {
                setVisibleScroll('hidden-scroll')
            }
        })
    }, [])

    return (
        <div className={`scroll-to-top-btn ${VisibleScroll}`}>
            <ScrollToTopBtn className={''} />
            <RegisterButton className={''} />
        </div>
    );
}

export default BottomFixedButtons;