import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";

// Importing Routes
import AboutUs from "pages/about/AboutUs";
import Contact from "pages/contact/Contact";
import Home from "pages/home/Home";
import Programs from "pages/programs/Programs";
import Projects from "pages/projects/Projects";
import RegisterPage from "pages/register/Register";
import SummerRegisterPage from "pages/register/SummerRegister";
import ErrorPage from "error/ErrorPage";
import Media from "pages/media/Media";
import PlanPrograms from "routes/PlanPrograms";
import Article from "pages/article/Article";
import Cognia from "pages/cognia/Cognia";

function AnimatedRoutes() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Routes key={location.pathname} location={location}>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/programs" element={<Programs />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/summerRegister" element={<SummerRegisterPage />} />
      <Route path="/media" element={<Media />} />
      <Route path="/planPrograms/:id" element={<PlanPrograms />} />
      <Route path="/article" element={<Article />} />
      <Route path="/about/cognia-stem-certification" element={<Cognia />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default AnimatedRoutes;
