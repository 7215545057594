import { useEffect, useState } from "react";
import Button from "components/buttons/Buttons";
import programCardIcon from "assets/images/programs/icons/programCardIcon.png";

export default function ProgramContent(props) {
  const {
    key,
    img,
    title,
    subtitle,
    desc,
    age,
    className,
    redirectLink,
    theme,
  } = props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div key={key} className={`program__card ${theme}-theme`}>
        <div className="program__card-img">
          <img loading="lazy" src={img} alt="Plan Programi" />
        </div>

        <div className="program__card-divider"></div>
        <div className="program__card-content">
          <h1>
            {title} {subtitle}
          </h1>
          <span>{age}</span>
          <p>{desc}</p>
          <div>
            <Button
              className={[`programs-cards-btn ${className}`]}
              RedirectLink={`/planPrograms/${redirectLink}`}
              ButtonTitle="Më Shumë"
              variant={"purple"}
              size={"md"}
              ariaLabel={"Më Shumë"}
            />
          </div>
        </div>
        <div className="program__card-bgImg">
          <img src={programCardIcon} alt="Planprogram Card Image" />
        </div>
      </div>
    </>
  );
}
