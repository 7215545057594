import RootInput from "components/inputs/RootInput";

function PhoneNumberInput(props) {
  const {
    className,
    selectChange,
    inputChange,
    value,
    inputClassName,
    placeholder,
  } = props;
  const handleInputChange = (event) => {
    const { value } = event.target;
    let formattedValue = value.replace(/[^0-9-]/g, "");

    if (formattedValue.length > 7) {
      formattedValue = formattedValue.slice(0, 7);
    } else if (formattedValue.length > 3 && !formattedValue.includes("-")) {
      formattedValue =
        formattedValue.slice(0, 3) + "-" + formattedValue.slice(3);
    }

    inputChange(formattedValue);
  };

  return (
    <>
      <div className={className}>
        <select
          name="number-variant"
          id="number-variant"
          onChange={selectChange}
        >
          <option value="043">043</option>
          <option value="044">044</option>
          <option value="045">045</option>
          <option value="046">046</option>
          <option value="047">047</option>
          <option value="048">048</option>
          <option value="049">049</option>
        </select>
        <RootInput
          name={"phoneNumber"}
          id={"phoneNumber"}
          type={"text"}
          onChange={handleInputChange}
          placeholder={placeholder}
          value={value}
          className={`contact__phone-number-input ${inputClassName}`}
          ariaLabel={"Numri Kontaktues"}
        />
      </div>
    </>
  );
}

export default PhoneNumberInput;
