import { motion } from "framer-motion";
import "scss/css/media/mediaStyle.css";

// Importing Layout
import Footer from "components/layout/footer/Footer";
import NavBarr from "components/layout/NavBarr";
import MediaHeader from "./sections/MediaHeader";
import MediaCardsSection from "./sections/MediaCardsSection";
import BottomFixedButtons from "components/buttons/BottomFixedButtons";

function Media() {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <NavBarr contrast={true} />
        <section className="media">
          <MediaHeader />
        </section>

        <section className="media__cards-section">
          <MediaCardsSection />
        </section>
        <Footer variant={"gray"} />
        <BottomFixedButtons />
      </motion.div>
    </>
  );
}

export default Media;
