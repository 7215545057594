import { Link } from "react-router-dom";

const sizes = {
  md: "m-btn",
  sm: "s-btn",
  xs: "xs-btn",
};

function Button(props) {
  const {
    classNameLink,
    RedirectLink,
    buttonAction,
    ButtonTitle,
    className,
    variant = "yellow",
    size,
    ariaLabel,
  } = props;
  const _size = sizes[size];
  return (
    <>
      {(RedirectLink && (
        <button
          onClick={buttonAction}
          className={`btn ${variant}-btn ${[[...className]]} ${_size}`}
          aria-label={ariaLabel}
        >
          <Link className={classNameLink} to={RedirectLink}>
            {ButtonTitle}
          </Link>
        </button>
      )) || (
        <button
          onClick={buttonAction}
          className={`btn ${variant}-btn ${[[...className]]} ${_size}`}
          aria-label={ariaLabel}
        >
          {ButtonTitle}
        </button>
      )}
    </>
  );
}

export default Button;
