import ScrollDown from "assets/images/programs/scrollDown.svg";
import img from "assets/images/illustrations/image1.webp";
import Fade from "react-reveal/Fade";

function ProgramsHeaderSection(props) {
  const { action } = props;
  return (
    <>
      <div className="programs__container container">
        <Fade top cascade>
          <div className="programs__container-content">
            <div className="programs__container-info">
              <h1>A jeni t'u mendu, çka mësojnë nxënësit në jCoders?</h1>
              <div className="divider"></div>
              <p>
                Të gjitha modulet e jCoders janë pjesë e një plani më të madh.
                Çdo modul luan një rol në zhvillimin e nxënësve, ku nxënësi
                përveç që përvetëson një platformë të teknologjisë, gjithashtu i
                ka edhe trajnerët që fokusohen në zhvillimin e shkathtësive
                esenciale të shekullit 21. Ne e marrim kohën tonë, jo vetëm për
                t’i vlerësuar shkathtësitë e nxënësve tanë por gjithashtu për
                t’iu ndihmuar atyre që të jenë versioni më i mirë i vetës.
              </p>
            </div>
            <div className="programs__container-img">
              <img src={img} alt="Planprogram Header image" />
            </div>
          </div>
          <button
            onClick={action}
            style={{
              border: "none",
              backgroundColor: "transparent",
              cursor: "pointer",
            }}
            aria-label="Scroll Down"
          >
            <img
              src={ScrollDown}
              alt="Scroll Down Icon"
              className="scroll-down"
            />
          </button>
        </Fade>
      </div>
    </>
  );
}

export default ProgramsHeaderSection;
