import { useRef } from "react";

// Importing Components
import BottomFixedButtons from "components/buttons/BottomFixedButtons";
import { planPrograms } from "helpers/planPrograms/planProgramApi";
import { modules } from "helpers/planPrograms/modules";

// Importing Layout
import NavBarr from "components/layout/NavBarr";
import Footer from "components/layout/footer/Footer";

// import Assets
import PlanProgramHeader from "pages/planProgramUnregular/sections/PlanProgramHeader";
import PlanProgramDescription from "pages/planProgramUnregular/components/PlanProgramDescription";
import PlanProgramRegister from "pages/planProgramRegular/components/PlanProgramRegister";
import PlanProgramWeeks from "pages/planProgramUnregular/sections/PlanProgramWeeks";
import PlanProgramOrganized from "pages/planProgramUnregular/sections/PlanProgramOrganized";

const theme = {
  blue: "#40b1e5",
  red: "#f15b5b",
  purple: "#9f64a8",
  yellow: "#ffb000",
};

export default function ProgramContent(props) {
  const { params } = props;
  const programsRef = useRef(null);

  const planProgram = planPrograms.find((i, index) => {
    return i.id == params;
  });
  const _modules = modules.filter((module) => {
    return module.planprogram_id === planProgram.id;
  });

  const themeColor = theme[planProgram.theme];

  const handleClick = () => {
    if (programsRef.current) {
      const top = programsRef.current.offsetTop;
      window.scroll({
        top,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <NavBarr />
      <div className="body ur">
        <PlanProgramHeader
          title={planProgram.title}
          desc={planProgram.shortDesc}
          subtitle={planProgram.subtitle}
          theme={planProgram.theme}
          action={() => {
            handleClick();
          }}
        />
        <section className="programs__organized-container " ref={programsRef}>
          <PlanProgramOrganized
            desc={planProgram.extraDesc}
            images={planProgram.images}
          />
        </section>

        <section className="programs__learning-container">
          <PlanProgramDescription
            desc={planProgram.description}
            color={planProgram.theme}
          />
        </section>

        <section className="program__ur__content">
          <div className="program__ur__content-container container">
            <div className="program__ur__content-container-title">
              <h1 style={{ textTransform: "none", color: themeColor }}>
                Javët në kamp
              </h1>
              <h2>Cka do të zhvillohet gjatë kampit?</h2>
            </div>
            <PlanProgramWeeks
              info={planProgram.information}
              desc={planProgram.shortDesc}
              modules={_modules}
              theme={planProgram.theme}
            />
          </div>
        </section>

        <section
          className="programs__content-register"
          style={{ backgroundColor: "#fff " }}
        >
          <PlanProgramRegister
            theme={planProgram.theme}
            style={{ color: themeColor }}
            containerStyle={{ outline: `1px solid ${themeColor}` }}
            size={"sm"}
            redirect={"/summerRegister"}
          />
        </section>

        <BottomFixedButtons />

        <Footer variant={"white"} />
      </div>
    </>
  );
}
