import {
  UilFacebookF,
  UilInstagram,
  UilLinkedin,
  UilYoutube,
} from "@iconscout/react-unicons";

const sizes = {
  sm: { class: "sm-socials" },
  md: { class: "" },
  lg: { class: "lg-socials" },
};

function Socials(props) {
  const { credits = true, size = "md" } = props;
  const _size = sizes[size].class;
  return (
    <>
      <div
        className="socials__container"
        style={{
          marginBottom: `${credits ? "" : 0}`,
          width: `${credits ? "" : "max-content"}`,
          padding: `${credits ? "" : "0"}`,
        }}
      >
        <div className={`socials__img ${_size}`}>
          <a
            href="https://www.facebook.com/jCodersEdu"
            target="_blank"
            aria-label="jCoders Facebook"
          >
            <UilFacebookF />
          </a>
          <a
            href="https://www.youtube.com/@akademiajcoders6456"
            target="_blank"
            aria-label="jCoders Youtube"
          >
            <UilYoutube />
          </a>
          <a
            href="https://www.linkedin.com/company/akademia-jcoders"
            target="_blank"
            aria-label="jCoders LinkedIn"
          >
            <UilLinkedin />
          </a>
          <a
            href="https://www.instagram.com/akademia.jcoders/"
            target="_blank"
            aria-label="jCoders Instagram"
          >
            <UilInstagram />
          </a>
        </div>
        {credits && (
          <div className="socials__credits">
            <p>© 2015 - 2023 jCoders. All Rights Reserved️</p>
          </div>
        )}
      </div>
    </>
  );
}

export default Socials;
