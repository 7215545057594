import FadeCarousel from "components/carousels/FadeCarousel";
// import VisionImg from "assets/images/objectives/vision.png";
// import MissionImg from "assets/images/objectives/mision.png";

// Importing Assets
import img1 from "assets/images/heroImages/foto1.webp";
import img2 from "assets/images/heroImages/foto2.webp";
import img3 from "assets/images/heroImages/foto3.webp";
import img4 from "assets/images/heroImages/foto4.webp";
import img6 from "assets/images/heroImages/foto6.webp";
import img7 from "assets/images/heroImages/foto7.webp";
import img8 from "assets/images/heroImages/foto8.webp";
import img9 from "assets/images/heroImages/foto9.webp";
import img10 from "assets/images/heroImages/foto10.webp";

const sliderImg = [
  { id: 1, img: img1 },
  { id: 2, img: img2 },
  { id: 3, img: img3 },
  { id: 4, img: img4 },
  { id: 6, img: img6 },
  { id: 7, img: img7 },
  { id: 8, img: img8 },
  { id: 9, img: img9 },
  { id: 10, img: img10 },
];

function ObjectivesSection() {
  return (
    <>
      <div className="about__objectives-wrapper container">
        <div className="about__objectives-container">
          <div className="objective__card mission">
            <div className="objective__image">
              {/* <img src={MissionImg} alt="Our Mission" /> */}

              <FadeCarousel
                images={sliderImg}
                delay={"1300"}
                swiperClassName={"about__objective-carousel"}
                className={"about__objective-img"}
                style={{ height: "100%" }}
              />
            </div>

            <div className="objective__card-text mission-text">
              <h1>
                <span>Misioni</span> Ynë
              </h1>
              <p>
                Duke zhvilluar <span>planprograme</span>, procese dhe
                teknologji, ne synojmë të përmirësojmë{" "}
                <span>cilësinë e arsimit</span> dhe të kontribojmë në{" "}
                <span>ndërtimin</span> e një gjenerate të re inovatorësh me një{" "}
                <span>mendësi pozitive</span>.
              </p>
            </div>
          </div>
          {/* <div className="objective__card vision">
                            <div className="objective__card-text vision-text">
                                <h1>
                                    <span>Vizioni</span> Ynë
                                </h1>
                                <p>
                                    jCoders është <span>ndërtuar</span> para së gjithash për të
                                    ofruar një <span>përvojë</span> mësimi që do të ketë ndikim
                                    në jetën e nxënësve tanë. Ne <span>besojmë</span> se ҫdokush
                                    ka të drejtë të ndjehet rehat me atë se kush është dhe të
                                    mund ta shpreh potencialin e tij/saj <span>lirshëm</span>.
                                </p>
                            </div>
                            <div className="objective__image">
                                <img src={VisionImg} alt="Our Vision" />
                            </div>
                        </div> */}
        </div>
      </div>
    </>
  );
}

export default ObjectivesSection;
